import { useMemo } from 'react';
import { DropdownOption } from '../App/types';

export function useApplicationStatusOptions(
  applicationStatuses: Array<DropdownOption>,
  applicationStatusId: string,
  disableWaitlistOption: boolean,
  filterOptionsWaitlist: boolean,
  waitlistTooltipText: string,
): [Array<DropdownOption>] {
  const selectedApplicationStatus = applicationStatuses.find(
    (applicationStatus) => applicationStatus.value === applicationStatusId,
  );

  const applicationStatusOptions = useMemo(() => {
    return applicationStatuses
      .filter((applicationStatus) => {
        if (selectedApplicationStatus?.text === 'Abandoned') {
          return ['New Portal App', 'Abandoned'].includes(
            applicationStatus.text,
          );
        }

        if (
          selectedApplicationStatus?.text !== 'New Portal App' &&
          applicationStatus.text === 'Abandoned'
        ) {
          return false;
        }

        return true;
      })
      .map((applicationStatus) => {
        return ['Waitlist', 'Canceled', 'Denied'].includes(
          applicationStatus.text,
        ) && disableWaitlistOption
          ? {
              ...applicationStatus,
              disabled: true,
              ...(waitlistTooltipText
                ? {
                    tooltip: waitlistTooltipText,
                  }
                : {}),
            }
          : applicationStatus;
      });
  }, [
    applicationStatuses,
    disableWaitlistOption,
    selectedApplicationStatus,
    waitlistTooltipText,
  ]);

  if (!selectedApplicationStatus) {
    return [applicationStatusOptions];
  }

  if (!filterOptionsWaitlist) {
    return [applicationStatusOptions];
  }

  const statusToFilterMap: Record<string, string[]> = {
    Waitlist: ['Canceled', 'Denied', 'Waitlist'],
    'In Process': ['Canceled', 'Denied', 'Approved', 'In Process'],
    Canceled: ['Waitlist', 'Canceled'],
    Denied: ['Waitlist', 'Denied'],
    Approved: ['Canceled', 'Denied', 'In Process', 'Approved'],
    'New Portal App': ['Waitlist', 'Abandoned', 'New Portal App'],
    Abandoned: ['Abandoned', 'New Portal App'],
  };

  const filteredOptions =
    statusToFilterMap[selectedApplicationStatus.text] || [];

  return [
    applicationStatusOptions.filter((item) =>
      filteredOptions.includes(item.text),
    ),
  ];
}
