import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid } from 'react-bootstrap';
import DocumentTitle from 'react-document-title';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import * as prospectProfileActions from './actions';
import messages from './messages';
import {
  getAllContactTypes,
  getAllReferralTypes,
  getProspectAssignees,
  getAllPetTypes,
  getAllPetBreeds,
  getAllProspectStatus,
  getAllNameSuffixes,
  getAllRelationships,
  getAllFloorPlans,
} from '../App/actions';
import ProspectProfileForm from './ProspectProfileForm';
import { injectIntl } from 'react-intl';
import { concat, pickAll } from 'ramda';
import confirm from '../../components/ConfirmDialogModal';

import type {
  ProspectPreferences,
  FormProspect,
  AdditionalOccupant,
} from './types';
import type {
  GlobalState,
  ContactType,
  ReferralType,
  PetType,
  PetBreed,
  NameSuffix,
  User,
  ProspectStatus,
  Property,
  Relationship,
} from '../App/types';
import { getProspectToSubmit } from '../../utils/prospectPreferences-helpers';

type StateProps = {
  contactTypes: Array<ContactType>,
  referralTypes: Array<ReferralType>,
  prospectStatusList: Array<ProspectStatus>,
  assignees: Array<User>,
  petTypes: Array<PetType>,
  petBreeds: Array<PetBreed>,
  suffixes: Array<NameSuffix>,
  relationships: Array<Relationship>,
  locale: string,
  showSuccessMessage: boolean,
  showErrorMessage: boolean,
  errorMessage: string,
  selectedProperty: Property,
  isSaving: boolean,
  history: Object,
};

type InjectedProps = {
  actions: Object,
  intl: any,
};

export class ProspectProfile extends Component<StateProps & InjectedProps> {
  excludedFormValues: Array<any>;

  constructor(props: StateProps & InjectedProps) {
    super(props);
    this.excludedFormValues = [
      'helpText',
      'nBaths',
      'nHalfBaths',
      'nBeds',
      'moveInDateFrom',
      'moveInDateTo',
      'priceFrom',
      'priceTo',
      'leaseTermInMonths',
      'wants',
      'notWants',
      'moveInToVisible',
      'noMoveInVisible',
      'pets',
      'minors',
      'adults',
      'currentProspectStatus',
      'preferredFloorPlanId',
      'nBedsArr',
    ];
  }

  componentDidMount() {
    this.props.actions.getAllContactTypes();
    this.props.actions.getAllReferralTypes();
    this.props.actions.getProspectAssignees();
    this.props.actions.getAllPetTypes();
    this.props.actions.getAllPetBreeds();
    this.props.actions.getAllProspectStatus();
    this.props.actions.getAllRelationships();
    this.props.actions.getAllNameSuffixes();
    this.props.actions.getAllFloorPlans();
  }

  getProspectPreferences(prospect: FormProspect): ProspectPreferences {
    const sectionProperties = [
      'nBaths',
      'nBeds',
      'preferredFloorPlanId',
      'nHalfBaths',
      'moveInDateFrom',
      'moveInDateTo',
      'priceFrom',
      'priceTo',
      'leaseTermInMonths',
      'wants',
      'notWants',
      'helpText',
      'nBedsArr',
    ];
    // $FlowFixMe
    const preferencesObject: ProspectPreferences = pickAll(
      sectionProperties,
      prospect,
    );
    if (prospect?.preferredFloorPlanId === '') {
      preferencesObject.preferredFloorPlanId = null;
    }
    if (prospect.noMoveInVisible) delete preferencesObject.moveInDateFrom;
    return preferencesObject;
  }

  getPetOcupants(prospect: FormProspect): Array<Object> {
    const petOccupants = prospect.pets
      ? prospect.pets.map((pet) => ({
          petBreedId:
            !pet || pet.petBreedId === '' ? undefined : pet.petBreedId,
          ...pet,
        }))
      : [];
    return petOccupants;
  }

  getDefaultStatus(): string {
    const listStatus = this.props.prospectStatusList.map((prospectStatus) => ({
      value: prospectStatus.id,
      isDefault: prospectStatus.isDefault,
    }));
    return listStatus.filter((s) => s.isDefault === true)[0].value;
  }

  getAdditionalOcupants(prospect: FormProspect): Array<AdditionalOccupant> {
    const minorsOccupants = prospect.minors
      ? prospect.minors.map((minor) => ({
          type: 'Minor',
          ...minor,
        }))
      : [];

    const adultsOccupants = prospect.adults
      ? prospect.adults.map((other) => ({
          type: 'Other Adult',
          ...other,
        }))
      : [];
    return concat(minorsOccupants, adultsOccupants);
  }

  handleSubmit(prospect: FormProspect) {
    const relationships = this.generateRelationships(this.props.intl.locale);
    const prospectToSubmit = getProspectToSubmit(
      {
        prospect,
        propertyId: this.props.selectedProperty.id,
        prospectStatusList: this.props.prospectStatusList,
        isPost: true,
        relationships,
      },
      this.props.flags,
    );
    confirm(this.props.intl.formatMessage(messages.createConfirmation), {
      intl: this.props.intl,
    })
      .then(() => this.props.actions.saveProspectProfile(prospectToSubmit))
      .catch(() => {});
  }

  generateContactTypes(locale: string) {
    const contactTypes = this.props.contactTypes.map((contactType) => ({
      value: contactType.id,
      text: contactType.translations[locale] || contactType.name,
    }));
    contactTypes.unshift({
      value: '',
      text: this.props.intl.formatMessage(messages.chooseOption),
      disabled: true,
    });
    return contactTypes;
  }

  generateReferralTypes(locale: string) {
    const referralTypes = this.props.referralTypes
      .map((referralType) => ({
        value: referralType.id,
        text: referralType.translations[locale] || referralType.name,
      }))
      .sort((a: Object, b: Object) => a.text.localeCompare(b.text));
    referralTypes.unshift({
      value: '',
      text: this.props.intl.formatMessage(messages.chooseOption),
      disabled: true,
    });
    return referralTypes;
  }

  generatePetTypes(locale: string) {
    const petTypes = this.props.petTypes.map((petType) => ({
      value: petType.id,
      text: petType.translations[locale] || petType.name,
      breeds: petType.petBreeds.map((breed) => ({
        value: breed.id,
        text: breed.translations[locale] || breed.name,
      })),
    }));
    return petTypes;
  }

  generatePetBreeds(locale: string) {
    return this.props.petBreeds
      ? this.props.petBreeds.reduce((breeds, petBreed) => {
          if (!breeds[petBreed.petTypeId]) breeds[petBreed.petTypeId] = [];
          breeds[petBreed.petTypeId].push({
            value: petBreed.id,
            text: petBreed.translations[locale] || petBreed.name,
          });
          return breeds;
        }, {})
      : {};
  }

  generateSuffixes(locale: string) {
    const suffixes = this.props.suffixes
      ? this.props.suffixes.map((suffix) => ({
          value: suffix.id,
          text: suffix.translations[locale] || suffix.name,
        }))
      : [];
    suffixes.unshift({
      value: '',
      text: this.props.intl.formatMessage(messages.chooseOption),
      disabled: true,
    });
    return suffixes;
  }

  generateRelationships(locale: string) {
    const relationships = this.props.relationships
      ? this.props.relationships.map((suffix) => ({
          value: suffix.id,
          text: suffix.translations[locale] || suffix.name,
        }))
      : [];
    relationships.unshift({
      value: '',
      text: this.props.intl.formatMessage(messages.chooseOption),
      disabled: true,
    });
    return relationships;
  }

  generateAssignees() {
    return this.props && this.props.assignees
      ? this.props.assignees.map((assignee) => ({
          value: assignee.id,
          text: `${assignee.firstName} ${assignee.lastName}`,
          disabled: false,
        }))
      : [];
  }

  render() {
    if (this.props.selectedProperty.hasCommercialFloorPlans === 'ALL') {
      this.props.history.push('/404');
    }
    const contactTypes = this.generateContactTypes(this.props.intl.locale);
    const initialContactType =
      contactTypes.length > 0 ? contactTypes[0].value : undefined;
    const referralTypes = this.generateReferralTypes(this.props.intl.locale);
    const petTypes = this.generatePetTypes(this.props.intl.locale);
    const petBreeds = this.generatePetBreeds(this.props.intl.locale);
    const suffixes = this.generateSuffixes(this.props.intl.locale);
    const relationships = this.generateRelationships(this.props.intl.locale);
    const assignees = this.generateAssignees();
    const initialReferralType =
      referralTypes.length > 0 ? referralTypes[0].value : undefined;

    assignees?.unshift({
      value: '',
      text: this.props.intl.formatMessage(messages.unassigned),
    });
    const initialUser = assignees?.[0].value;

    const defaultAmount = 'default';
    return (
      <DocumentTitle title={this.props.intl.formatMessage(messages.title)}>
        <Grid className="bodywrap" fluid>
          {contactTypes.length > 0 && referralTypes.length > 0 && (
            <ProspectProfileForm
              initialValues={{
                helpText: '',
                contactTypeId: initialContactType,
                referralTypeId: initialReferralType,
                assignedToId: initialUser,
                adults: [],
                adultsCount: defaultAmount,
                minors: [],
                minorsCount: defaultAmount,
                pets: [],
                petsCount: defaultAmount,
                noMoveInDate: false,
                nBedsArr: [],
                preferredFloorPlanId: '',
                prefersUnitAccessible: 'no',
              }}
              onSubmit={this.handleSubmit.bind(this)}
              contactTypes={contactTypes}
              referralTypes={referralTypes}
              assignees={assignees}
              petTypes={petTypes}
              floorPlans={this.props.floorPlans}
              petBreeds={petBreeds}
              suffixes={suffixes}
              relationships={relationships}
              intl={this.props.intl}
              selectedProperty={this.props.selectedProperty}
              history={this.props.history}
            />
          )}
        </Grid>
      </DocumentTitle>
    );
  }
}

export const mapStateToProps = ({
  app,
  languageProvider,
}: GlobalState): any => {
  return {
    contactTypes: app.contactTypes,
    referralTypes: app.referralTypes,
    petTypes: app.petTypes,
    petBreeds: app.petBreeds,
    suffixes: app.nameSuffixes,
    relationships: app.relationships,
    assignees: app.prospectAssignees,
    prospectStatusList: app.prospectStatusList,
    locale: languageProvider.locale,
    selectedProperty: app.selectedProperty,
    floorPlans: app.floorPlans,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  const actions = bindActionCreators(
    {
      ...prospectProfileActions,
      getAllContactTypes,
      getAllReferralTypes,
      getAllPetTypes,
      getAllPetBreeds,
      getAllRelationships,
      getProspectAssignees,
      getAllProspectStatus,
      getAllNameSuffixes,
      getAllFloorPlans,
    },
    dispatch,
  );
  return { actions };
};

const InjectedProspectProfile = injectIntl(ProspectProfile);

const Connected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedProspectProfile);

export default withLDConsumer()(Connected);
