import { useState, useMemo, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { toastr } from 'react-redux-toastr';

// Components
import {
  Modal,
  ModalConfirm,
} from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { Typography } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Form } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import {
  PlusIcon,
  SettingsIcon,
  NotesIcon,
  SkipIcon,
  AlertInfoIcon,
  RedoIcon,
  InactiveIcon,
  CanceledStatusIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import {
  cancel,
  householdPreferencesNames,
  propertyPreferencesNames,
  reasons,
} from './constants';
import AddToWaitlistModal from './AddToWaitlistModal';
import NotesModal from './NotesModal';

// Hooks
import useReactHookFormProps from '../../../hooks/useReactHookFormProps';
import {
  useAddToWaitlist,
  useAddWaitlistNote,
  useSkipFromWaitlist,
  useConfirmFromWaitlist,
  useReinstateToWaitlist,
  useRemoveFromWaitlistMutation,
  useDenyApplicantMutation,
} from '../hooks.affordableWaitlistMutations';
import { useQueryClient } from 'react-query';
import {
  useCreateAffordableWaitlistPropertyPreferences,
  useUpdateAffordableWaitlistPropertyPreferences,
} from '../hooks.affordableWaitlistPropertyPreferencesMutations';

// Constants
import generalMessages from '../messages';
import modalMessages from './messages';

// Utils
import _ from 'lodash';
import { navigateToUrlWithSelectedPropertyId } from '../../../utils/navigation-helpers';

const messages = { ...modalMessages, ...generalMessages };

/**
 * @note
 *  - Will not let a user discard changes if the modal is addToWaitlist and it
 * was opened from the Convert Prospect to Applicant Screen
 * @param {*} param0
 * @returns
 */
const confirmModalPropsDiscardChanges = ({
  closeConfirmModal,
  handleClose,
  openConfirm,
  cancelCallback,
  modalType,
  modalState,
}) => {
  const dontLetUserDiscard =
    modalType === 'addToWaitlist' && modalState?.prospect;
  return {
    title: dontLetUserDiscard
      ? 'You Must Complete Submission'
      : 'Discard Changes?',
    onClose: closeConfirmModal,
    open: openConfirm,
    actionsProps: [
      {
        children: dontLetUserDiscard ? 'Complete Submission' : 'Cancel',
        onClick: closeConfirmModal,
      },
      ...(dontLetUserDiscard
        ? []
        : [
            {
              children: 'Discard Changes',
              onClick: () => {
                cancelCallback?.();
                handleClose();
                closeConfirmModal();
              },
            },
          ]),
    ],
  };
};

/**
 * Central component in charge to manage the modals related to the AffordableWaitlist screen.
 * -
 * @param {*} param0.modalState - The state containing parameters passed to the modal when using handleOpen
 * @returns
 */
const ManageAffordableWaitlistModals = ({
  // Component Props
  organizationId,
  propertyId,
  applicationId,
  // Refetch data
  refetchAffordableWaitlistPropertyPreferences,
  refetchAffordableWaitlists,
  // Modal Props
  modalState,
  modalType,
  open,
  openConfirm,
  openConfirmModal,
  handleClose,
  closeConfirmModal,
  // Other Props
  intl,
  isSubmitting,
}) => {
  const queryClient = useQueryClient();

  const [addToWaitlistIncomeLevel, setAddToWaitlistIncomeLevel] =
    useState('---');

  const {
    mutate: addToWaitlistMutate,
    isLoading: addToWaitlistMutationIsLoading,
  } = useAddToWaitlist({
    organizationId,
    propertyId,
    applicationId: applicationId ?? modalState?.applicationId,
    options: {
      onSuccess: async (res) => {
        const { updateCallback, updateCallbackParams, prospect } = modalState;
        if (updateCallback) {
          const params = updateCallbackParams
            ? Object.values(updateCallbackParams)
            : [];
          await updateCallback(...params);
        }
        queryClient.invalidateQueries(['getAllAffordableWaitlistsByProperty']);
        handleClose();
        if (prospect) {
          navigateToUrlWithSelectedPropertyId(
            `/application/${res.data.applicationId}`,
          );
        }
        toastr.success('Success', 'Household added to waitlist successfully');
      },
      onError: (e) => {
        toastr.error('Error', e);
      },
    },
  });

  const {
    mutate: confirmFromWaitlistMutate,
    isLoading: confirmFromWaitlistMutationIsLoading,
  } = useConfirmFromWaitlist({
    organizationId,
    propertyId,
    options: {
      onSuccess: async (res) => {
        toastr.success(
          'Success',
          'Applicant Selected from Waitlist successfully',
        );
        handleClose();
        refetchAffordableWaitlists();
      },
      onError: (e) => {
        toastr.error('Error', e);
      },
    },
  });

  const {
    mutate: skipFromWaitlistMutate,
    isLoading: skipFromWaitlistMutationIsLoading,
  } = useSkipFromWaitlist({
    organizationId,
    propertyId,
    options: {
      onSuccess: async (res) => {
        toastr.success('Success', 'Application skipped successfully');
        handleClose();
        refetchAffordableWaitlists();
      },
      onError: (e) => {
        toastr.error('Error', e);
      },
    },
  });

  const {
    mutate: createAffordableWaitlistPropertyPrefMutation,
    isLoading: createAffordableWaitlistPropertyPrefMutationIsLoading,
  } = useCreateAffordableWaitlistPropertyPreferences({
    organizationId,
    propertyId,
    options: {
      onSuccess: (res) => {
        toastr.success('Success', res.message);
        handleClose();
        refetchAffordableWaitlistPropertyPreferences();
      },
      onError: (e) => {
        toastr.error('Error', e);
      },
    },
  });

  const {
    mutate: updateAffordableWaitlistPropertyPrefMutation,
    isLoading: updateAffordableWaitlistPropertyPrefMutationIsLoading,
  } = useUpdateAffordableWaitlistPropertyPreferences({
    organizationId,
    propertyId,
    options: {
      onSuccess: (res) => {
        toastr.success('Success', res.message);
        handleClose();
        refetchAffordableWaitlistPropertyPreferences();
      },
      onError: (e) => {
        toastr.error('Error', e);
      },
    },
  });

  const {
    mutate: addWaitlistNoteMutate,
    isLoading: addWaitlistNoteMutationIsLoading,
  } = useAddWaitlistNote({
    organizationId,
    propertyId,
    options: {
      onSuccess: async (res) => {
        toastr.success('Success', 'Note added successfully');
        handleClose();
        refetchAffordableWaitlists();
      },
      onError: (e) => {
        toastr.error('Error', e);
      },
    },
  });

  const {
    mutate: reinstateToWaitlistMutate,
    isLoading: reinstateToWaitlistMutationIsLoading,
  } = useReinstateToWaitlist({
    organizationId,
    propertyId,
    options: {
      onSuccess: async (res) => {
        const { updateCallback, updateCallbackParams } = modalState;
        if (updateCallback) {
          const params = updateCallbackParams
            ? Object.values(updateCallbackParams)
            : [];
          await updateCallback(...params);
        }
        queryClient.invalidateQueries(['getAllAffordableWaitlistsByProperty']);
        toastr.success(
          'Success',
          'Application reinstated to waitlist successfully',
        );
        refetchAffordableWaitlists?.();
        handleClose();
      },
      onError: (e) => {
        toastr.error('Error', e);
      },
    },
  });

  const { removeFromWaitlist, removingFromWaitlist } =
    useRemoveFromWaitlistMutation({
      organizationId,
      propertyId,
      options: {
        onSuccess: async () => {
          const { shouldRefetchWaitlist } = modalState;
          toastr.success('Success', 'Removed from waitlist successfully');
          handleClose();
          if (shouldRefetchWaitlist) {
            refetchAffordableWaitlists();
          }
        },
        onError: (e) => {
          toastr.error('Error', e);
        },
      },
    });

  const { denyApplicant, denyingApplicant } = useDenyApplicantMutation({
    organizationId,
    propertyId,
    options: {
      onSuccess: async () => {
        const { shouldRefetchWaitlist } = modalState;
        toastr.success('Success', 'Application denied successfully');
        handleClose();
        if (shouldRefetchWaitlist) {
          refetchAffordableWaitlists();
        }
      },
      onError: (e) => {
        toastr.error('Error', e);
      },
    },
  });

  const ReactHookFormProps = useReactHookFormProps({
    validationSchema: {
      checkboxes: {
        validate: (value) => {
          const selectedCheckboxes = Object.values(value).filter(
            (val) => val === true,
          );
          return (
            selectedCheckboxes.length > 0 ||
            'At least one checkbox must be selected'
          );
        },
      },
    },
    mode: 'onChange',
  });
  const { reset, getValues, isValid, isDirty, watch } = ReactHookFormProps;
  const anticipatedAnnualIncome = watch('anticipatedAnnualIncome');

  useEffect(() => {
    const { addToWaitlistParams = {} } = modalState || {};
    const initialPropertyPreferences = modalState?.configurePreferences || {};

    const preferences = Object.fromEntries(
      householdPreferencesNames.map((name) => [_.camelCase(name), false]),
    );

    const defaultValues = {
      addToWaitlist: {
        right: {
          affordableProgram:
            addToWaitlistParams?.affordablePrograms &&
            addToWaitlistParams.affordablePrograms?.length === 1
              ? [addToWaitlistParams.affordablePrograms?.[0]]
              : [],
          anticipatedAnnualIncome: '',
          requestingAccomodation: '',
          ...preferences,
        },
      },
      configurePreferences: {
        ...initialPropertyPreferences?.options,
      },
      addNote: {
        note: '',
        applicantContacted: '',
      },
      skipFromWaitlist: {
        reasons: [],
        note: '',
      },
      reinstate: {
        reasons: [],
        note: '',
        reinstatePosition: '',
      },
      deny: {
        someField: '',
      },
    }[modalType];

    if (modalType === 'addToWaitlist') {
      reset(defaultValues.right);
    } else {
      reset(defaultValues);
    }
  }, [reset, modalType, modalState]);

  const noteFieldProps = ({ intl }) => ({
    variant: 'text',
    name: 'note',
    label: <FormattedMessage {...messages.notes} />,
    placeholder: 'Start Typing...',
    rules: {
      required: false,
      // maxLength: 255, // can vary later on
      validate: (note) => {
        let isValid = true;
        const reasons = getValues('reasons');
        if (
          reasons?.length > 0 &&
          reasons.includes(_.camelCase('Other - See Notes'))
        ) {
          if (!note) {
            return intl.formatMessage({ ...messages.notesRequired });
          }
        }
        if (note) {
          const noteLength = note.length;
          if (noteLength <= 255) {
            isValid = true;
          } else {
            isValid = false;
          }
        }
        if (!isValid) {
          return intl.formatMessage({ ...messages.limitReached255 });
        }
        return isValid;
      },
    },
    InputProps: {
      multiline: true,
      minRows: 4,
      maxRows: 5,
    },
    GridProps: {
      xs: 12,
    },
  });

  /**
   * Can be used for shared fields that change dynamically
   * @notes idealy it should be reused for:
   * - skip modal
   * - deny modal
   * - cancel modal
   */
  const sharedFormFields = useCallback(
    ({ intl, reasonType }) => {
      const reasonsToUse = reasons[reasonType];
      return [
        {
          variant: 'multiSelect',
          name: 'reasons',
          label: <FormattedMessage {...messages.reason} />,
          options: reasonsToUse.map((name) => ({
            value: _.camelCase(name),
            text: name,
          })),
          rules: {
            required: true,
          },
          GridProps: {
            xs: 12,
          },
        },
        noteFieldProps({ intl }),
      ];
    },
    [modalType],
  );

  const { confirmModalProps, modalProps, formProps } = useMemo(() => {
    const props = {
      modalProps: {},
      formProps: {},
      confirmModalProps: {},
    };

    switch (modalType) {
      case 'addToWaitlist':
        props.modalProps = {
          title: <FormattedMessage {...messages.addToWaitlistTitle} />,
          icon: <PlusIcon />,
          ContentProps: {
            sx: {
              maxWidth: '680px',
            },
          },
          onClose: () => {
            openConfirmModal();
          },
          noCloseIcon: modalState?.prospect || false,
          actionsProps: [
            ...(modalState?.prospect
              ? []
              : [
                  {
                    children: 'Cancel',
                    onClick: openConfirmModal,
                  },
                ]),
            {
              children: 'Add to Waitlist',
              submitButton: true,
              isSubmitting: isSubmitting || addToWaitlistMutationIsLoading,
              disabled: !isValid || !isDirty,
              onClick: () => {
                const values = getValues();
                const payload = {
                  affordableProgram: values.affordableProgram.sort().join(', '),
                  anticipatedAnnualIncome: values.anticipatedAnnualIncome,
                  incomeLevel: addToWaitlistIncomeLevel,
                  requestingAccommodation:
                    values.requestingAccomodation === 'yes' ? true : false,
                  preferences: {
                    ...Object.fromEntries(
                      householdPreferencesNames.map((name) => [
                        _.camelCase(name),
                        values[_.camelCase(name)],
                      ]),
                    ),
                  },
                  waitlistDateRange:
                    modalState.addToWaitlistParams?.waitlistDateRange,
                };

                if (modalState.manual?.onSubmit) {
                  modalState.manual.onSubmit(payload);
                } else {
                  addToWaitlistMutate({ payload });
                }
              },
            },
          ],
        };
        const programs =
          modalState.addToWaitlistParams?.affordablePrograms || [];
        props.formProps = {
          fields: [
            {
              name: 'custom',
              variant: 'custom',
              component: (
                <>
                  <Typography color={'text.secondary'}>
                    <FormattedMessage {...messages.completeTheFollowing} />
                  </Typography>
                </>
              ),
              GridProps: {
                xs: 12,
              },
            },
            {
              variant: 'multiSelect',
              name: 'affordableProgram',
              label: <FormattedMessage {...messages.affordableProgram} />,
              placeholder: 'Select a Program',
              options: programs.map((name) => ({
                value: name, // will be name for practical purposes
                text: name,
              })),
              readOnly: programs.length === 1,
              rules: {
                required: true,
              },
              GridProps: {
                xs: 12,
              },
            },
            {
              variant: 'number',
              name: 'anticipatedAnnualIncome',
              label: <FormattedMessage {...messages.anticipatedAnnualIncome} />,
              adornment: '$',
              rules: {
                required: true,
                min: 0.01,
                step: 0.01,
              },
              GridProps: {
                xs: 12,
              },
            },
            {
              variant: 'radioGroup',
              name: 'requestingAccomodation',
              label: <FormattedMessage {...messages.requestingAccomodation} />,
              options: [
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' },
              ],
              rules: {
                required: true,
              },
              GridProps: {
                xs: 12,
              },
            },
            {
              name: 'custom',
              variant: 'custom',
              component: (
                <>
                  <Typography variant={'label'}>
                    <FormattedMessage {...messages.preferences} />
                  </Typography>
                </>
              ),
              GridProps: {
                xs: 12,
              },
            },
            // TODO: some preferences might be disabled for some households later on
            ...householdPreferencesNames.map((name) => ({
              variant: 'checkbox',
              name: _.camelCase(name),
              label: name,
              rules: {
                required: false,
              },
              GridProps: {
                xs: 12,
              },
            })),
          ],
        };
        props.confirmModalProps = confirmModalPropsDiscardChanges({
          closeConfirmModal,
          handleClose,
          openConfirm,
          cancelCallback: modalState.cancelCallback,
          modalType,
          modalState,
        });
        break;
      case 'configurePreferences':
        props.modalProps = {
          ModalProps: {
            PaperProps: {
              sx: {
                height: 625,
              },
            },
          },
          title: (
            <Typography variant="h3">
              <FormattedMessage {...messages.configurePreferences} />
            </Typography>
          ),
          icon: <SettingsIcon />,
          actionsProps: [
            {
              children: cancel,
              onClick: isDirty ? openConfirmModal : handleClose,
            },
            {
              children: <FormattedMessage {...messages.save} />,
              submitButton: true,
              isSubmitting:
                createAffordableWaitlistPropertyPrefMutationIsLoading ||
                updateAffordableWaitlistPropertyPrefMutationIsLoading,
              disabled: !isValid || !isDirty,
              onClick: () => {
                const values = getValues();
                const result = Object.keys(values).filter((key) => values[key]);
                const payload = {
                  preferences: result,
                };

                const isEdit = modalState?.configurePreferences?.id;
                if (isEdit) {
                  updateAffordableWaitlistPropertyPrefMutation({ payload });
                } else {
                  createAffordableWaitlistPropertyPrefMutation({ payload });
                }
              },
            },
          ],
        };
        props.formProps = {
          fields: [
            {
              name: 'custom',
              variant: 'custom',
              component: (
                <Typography variant="h4" marginBottom={2}>
                  <FormattedMessage {...messages.preferences} />
                </Typography>
              ),
              GridProps: {
                xs: 12,
              },
            },
            ...propertyPreferencesNames.map((name) => ({
              variant: 'checkbox',
              name: _.camelCase(name),
              label: <FormattedMessage {...messages[_.camelCase(name)]} />,
              disabled: false,
              GridProps: {
                xs: 12,
                sx: {
                  '& .MuiFormControlLabel-label': {
                    marginLeft: '6px',
                  },
                },
              },
            })),
          ],
        };
        props.confirmModalProps = confirmModalPropsDiscardChanges({
          closeConfirmModal,
          handleClose,
          openConfirm,
          modalType,
          modalState,
        });
        break;
      case 'addNote':
        props.modalProps = {
          onClose: () => {
            openConfirmModal();
          },
          title: <FormattedMessage {...messages.addNoteTitle} />,
          icon: <NotesIcon />,
          ContentProps: {
            sx: {
              maxWidth: '496px',
              width: '496px',
              height: '280px',
            },
          },
          actionsProps: [
            {
              children: cancel,
              onClick: isDirty ? openConfirmModal : handleClose,
            },
            {
              children: <FormattedMessage {...messages.save} />,
              submitButton: true,
              isSubmitting: addWaitlistNoteMutationIsLoading,
              disabled: !isValid || !isDirty,
              onClick: () => {
                const values = getValues();
                const payload = {
                  description: values.note || '',
                  applicantContacted:
                    values.applicantContacted === 'yes' ? true : false,
                  // for logging purposes
                  affordableWaitlistRow: modalState?.affordableWaitlistRow,
                };
                addWaitlistNoteMutate({
                  payload,
                  affordableWaitlistId: modalState?.affordableWaitlistId,
                });
              },
            },
          ],
        };
        props.formProps = {
          fields: [
            noteFieldProps({ intl }),
            {
              variant: 'radioGroup',
              name: 'applicantContacted',
              label: <FormattedMessage {...messages.applicantContacted} />,
              options: [
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' },
              ],
              rules: {
                required: true,
              },
              GridProps: {
                xs: 12,
              },
            },
          ],
        };
        props.confirmModalProps = confirmModalPropsDiscardChanges({
          closeConfirmModal,
          handleClose,
          openConfirm,
        });
        break;
      case 'skipFromWaitlist':
        props.modalProps = {
          onClose: () => {
            openConfirmModal();
          },
          title: <FormattedMessage {...messages.skipTitle} />,
          icon: <SkipIcon />,
          ContentProps: {
            sx: {
              maxWidth: '496px',
              width: '496px',
              height: '280px',
            },
          },
          actionsProps: [
            {
              children: cancel,
              onClick: isDirty ? openConfirmModal : handleClose,
            },
            {
              children: <FormattedMessage {...messages.save} />,
              submitButton: true,
              isSubmitting: skipFromWaitlistMutationIsLoading,
              disabled: !isValid || !isDirty,
              onClick: () => {
                const values = getValues();
                const payload = {
                  description: values.note || '',
                  reasons: values.reasons || [],
                  // for logging purposes
                  affordableWaitlistRow: modalState?.affordableWaitlistRow,
                };
                skipFromWaitlistMutate({
                  payload,
                  affordableWaitlistId: modalState?.affordableWaitlistId,
                });
              },
            },
          ],
        };
        props.formProps = {
          fields: sharedFormFields({ intl, reasonType: 'skip' }),
        };
        props.confirmModalProps = confirmModalPropsDiscardChanges({
          closeConfirmModal,
          handleClose,
          openConfirm,
        });

        props.confirmModalProps = confirmModalPropsDiscardChanges({
          closeConfirmModal,
          handleClose,
          openConfirm,
        });

        break;

      case 'confirmFromWaitlist':
        props.modalProps = {
          title: (
            <FormattedMessage
              {...modalMessages.confirmSelectFromWaitlistTitle}
            />
          ),
          icon: <AlertInfoIcon />,
          ModalProps: {
            PaperProps: {
              sx: {
                height: 200,
                maxWidth: 400,
              },
            },
          },
          noCloseIcon: true,
          actionsProps: [
            {
              children: cancel,
              onClick: handleClose,
            },
            {
              children: 'Confirm',
              submitButton: true,
              isSubmitting: confirmFromWaitlistMutationIsLoading,
              onClick: () => {
                const payload = {
                  // for logging purposes
                  affordableWaitlistRow: modalState?.affordableWaitlistRow,
                };
                confirmFromWaitlistMutate({
                  payload,
                  affordableWaitlistId: modalState?.affordableWaitlistId,
                });
              },
            },
          ],
        };
        props.formProps = {
          fields: [
            {
              name: 'confirmDescription',
              variant: 'custom',
              component: (
                <Typography>
                  <FormattedMessage
                    {...modalMessages.contentConfirmSelectFromWaitlist}
                  />
                </Typography>
              ),
              GridProps: { xs: 12 },
            },
          ],
        };
        props.confirmModalProps = confirmModalPropsDiscardChanges({
          closeConfirmModal,
          handleClose,
          openConfirm,
        });
        break;
      case 'reinstate':
        props.modalProps = {
          onClose: () => {
            openConfirmModal();
          },
          title: <FormattedMessage {...messages.reinstateTitle} />,
          icon: <RedoIcon />,
          ContentProps: {
            sx: {
              maxWidth: '496px',
              width: '496px',
              height: '416px',
            },
          },
          actionsProps: [
            {
              children: cancel,
              onClick: isDirty ? openConfirmModal : handleClose,
            },
            {
              children: <FormattedMessage {...messages.save} />,
              submitButton: true,
              isSubmitting: reinstateToWaitlistMutationIsLoading,
              disabled: !isValid || !isDirty,
              onClick: () => {
                const values = getValues();
                const payload = {
                  description: values.note || '',
                  reasons: values.reasons || [],
                  reinstatePosition: values.reinstatePosition,
                  // for logging purposes
                  affordableWaitlistRow: modalState?.affordableWaitlistRow,
                };
                reinstateToWaitlistMutate({
                  payload,
                  affordableWaitlistId: modalState?.affordableWaitlistId,
                });
              },
            },
          ],
        };
        props.formProps = {
          fields: [
            ...sharedFormFields({ intl, reasonType: 'reinstate' }),
            {
              variant: 'radioGroup',
              name: 'reinstatePosition',
              label: <FormattedMessage {...messages.reinstatePosition} />,
              options: [
                { value: 'originalPlace', label: 'Original place on waitlist' },
                { value: 'bottomOfWaitlist', label: 'Bottom of waitlist' },
              ],
              rules: {
                required: true,
              },
              GridProps: {
                xs: 12,
              },
            },
          ],
        };
        props.confirmModalProps = confirmModalPropsDiscardChanges({
          closeConfirmModal,
          handleClose,
          openConfirm,
        });
        break;

      case 'cancel':
        props.modalProps = {
          onClose: () => {
            openConfirmModal();
          },
          title: <FormattedMessage {...messages.cancelTitle} />,
          icon: <InactiveIcon color="action" />,
          ContentProps: {
            sx: {
              width: {
                xs: 'calc(100% - 32px)',
                sm: 496,
              },
              height: '280px',
            },
          },
          actionsProps: [
            {
              children: cancel,
              onClick: isDirty ? openConfirmModal : handleClose,
            },
            {
              children: <FormattedMessage {...messages.save} />,
              submitButton: true,
              isSubmitting: removingFromWaitlist,
              disabled: !isValid || !isDirty,
              onClick: () => {
                const values = getValues();
                const payload = {
                  notes: values.note || '',
                  reasons: values.reasons,
                  affordableWaitlistId: modalState?.affordableWaitlistId,
                  affordableWaitlistRow: modalState?.affordableWaitlistRow,
                  applicationId: modalState?.applicationId,
                };
                removeFromWaitlist({
                  payload,
                });
              },
            },
          ],
        };
        props.formProps = {
          fields: sharedFormFields({ intl, reasonType: 'cancel' }),
        };
        props.confirmModalProps = confirmModalPropsDiscardChanges({
          closeConfirmModal,
          handleClose,
          openConfirm,
        });
        break;

      case 'deny':
        props.modalProps = {
          onClose: () => {
            openConfirmModal();
          },
          title: <FormattedMessage {...messages.denyTitle} />,
          icon: <CanceledStatusIcon color="error" />,
          ContentProps: {
            sx: {
              width: {
                xs: 'calc(100% - 32px)',
                sm: 496,
              },
              height: '280px',
            },
          },
          TitleProps: {
            sx: {
              color: 'error.main',
              display: 'flex',
              alignItems: 'center',
            },
          },
          actionsProps: [
            {
              children: cancel,
              onClick: isDirty ? openConfirmModal : handleClose,
            },
            {
              children: <FormattedMessage {...messages.save} />,
              submitButton: true,
              isSubmitting: denyingApplicant,
              disabled: !isValid || !isDirty,
              onClick: () => {
                const values = getValues();
                const payload = {
                  notes: values.note || '',
                  reasons: values.reasons,
                  affordableWaitlistId: modalState?.affordableWaitlistId,
                  affordableWaitlistRow: modalState?.affordableWaitlistRow,
                  applicationId: modalState?.applicationId,
                };
                denyApplicant({
                  payload,
                });
              },
            },
          ],
        };
        props.formProps = {
          fields: sharedFormFields({ intl, reasonType: 'deny' }),
        };
        props.confirmModalProps = confirmModalPropsDiscardChanges({
          closeConfirmModal,
          handleClose,
          openConfirm,
        });
        break;

      case 'showNotes':
        props.modalProps = {
          title: <FormattedMessage {...modalMessages.notes} />,
          icon: <NotesIcon />,
          onClose: handleClose,
          ContentProps: {
            sx: {
              padding: 4,
            },
          },
          ModalProps: {
            PaperProps: {
              sx: {
                height: 'auto',
                maxWidth: 450,
              },
            },
          },
          noCloseIcon: false,
          actionsProps: [
            {
              children: 'Close',
              onClick: handleClose,
            },
          ],
        };
        break;

      default:
        break;
    }

    return props;
  }, [
    // mutations
    addToWaitlistMutate,
    addToWaitlistMutationIsLoading,
    addWaitlistNoteMutate,
    addWaitlistNoteMutationIsLoading,
    confirmFromWaitlistMutate,
    confirmFromWaitlistMutationIsLoading,
    skipFromWaitlistMutate,
    skipFromWaitlistMutationIsLoading,
    reinstateToWaitlistMutate,
    reinstateToWaitlistMutationIsLoading,
    createAffordableWaitlistPropertyPrefMutation,
    createAffordableWaitlistPropertyPrefMutationIsLoading,
    updateAffordableWaitlistPropertyPrefMutation,
    updateAffordableWaitlistPropertyPrefMutationIsLoading,
    removeFromWaitlist,
    removingFromWaitlist,
    denyApplicant,
    denyingApplicant,
    // form
    getValues,
    isDirty,
    isValid,
    sharedFormFields,
    // modal
    handleClose,
    closeConfirmModal,
    modalType,
    modalState,
    openConfirm,
    openConfirmModal,
    // Others
    addToWaitlistIncomeLevel,
    intl,
    isSubmitting,
  ]);

  if (modalType === 'showNotes') {
    return (
      <NotesModal
        open={open}
        onClose={handleClose}
        modalProps={modalProps}
        notes={modalState?.notes}
      />
    );
  }

  return (
    <>
      {modalType === 'addToWaitlist' ? (
        <AddToWaitlistModal
          organizationId={organizationId}
          propertyId={propertyId}
          open={open}
          handleClose={handleClose}
          modalProps={modalProps}
          ReactHookFormProps={ReactHookFormProps}
          formProps={formProps}
          addToWaitlistInfo={modalState.addToWaitlistParams?.info}
          anticipatedAnnualIncome={anticipatedAnnualIncome}
          setAddToWaitlistIncomeLevel={setAddToWaitlistIncomeLevel}
          currentIncomeLevel={addToWaitlistIncomeLevel}
        />
      ) : (
        <Modal open={open} onClose={handleClose} {...modalProps}>
          <Form ReactHookFormProps={ReactHookFormProps} {...formProps} />
        </Modal>
      )}
      <ModalConfirm open={openConfirm} {...confirmModalProps} />
    </>
  );
};

export default ManageAffordableWaitlistModals;
