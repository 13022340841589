import React from 'react';
import {
  Box,
  Stack,
  Typography,
  TextArea,
  Button,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { SingleSelect } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import messages from '../messages';
import type { Props } from './types';

function FinalDecisionForm({ intl, isReadyForDecision }: Props) {
  const [selectedStatus, setSelectedStatus] = React.useState();

  return (
    <Box
      sx={{
        borderRadius: '4px',
        border: '1px solid #D1D1D1',
        backgroundColor: '#F8F8F8',
        padding: 3,
      }}
    >
      <Stack direction="row">
        <Stack
          width={isReadyForDecision ? '15%' : undefined}
          marginRight={isReadyForDecision ? 3 : 16}
          spacing={1}
        >
          <Typography variant="labelLarge">
            {intl.formatMessage(messages.finalScreeningStatus)}
          </Typography>
          {isReadyForDecision ? (
            <SingleSelect
              value={selectedStatus}
              onChange={(value) => setSelectedStatus(value)}
              fullWidth
              helperText={intl.formatMessage(messages.required)}
              options={[
                {
                  value: 'approved',
                  text: intl.formatMessage(messages.approved),
                },
                {
                  value: 'approvedWithConditions',
                  text: intl.formatMessage(messages.approvedWithConditions),
                },
                {
                  value: 'declined',
                  text: intl.formatMessage(messages.declined),
                },
              ]}
            />
          ) : (
            <Typography variant="bodyMedium">---</Typography>
          )}
        </Stack>
        <Stack
          width={isReadyForDecision ? '75%' : undefined}
          spacing={1}
          marginRight={isReadyForDecision ? 3 : 0}
        >
          <Typography variant="labelLarge">
            {intl.formatMessage(messages.reasonForDecision)}
          </Typography>
          {isReadyForDecision ? (
            <TextArea disabled={!selectedStatus} />
          ) : (
            <Typography variant="bodyMedium">---</Typography>
          )}
        </Stack>
        {isReadyForDecision && (
          <Button
            variant="primary"
            disabled
            marginLeft={3}
            sx={{
              height: '40px',
              marginTop: 1.5,
            }}
          >
            {intl.formatMessage(messages.saveFinalDecision)}
          </Button>
        )}
      </Stack>
    </Box>
  );
}

export default FinalDecisionForm;
