import React from 'react';
import { Tabs as CLTabs } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import TextingTabIcon from '../../containers/TextingTabIcon';
import { TAB_KEYS } from '../../containers/ResidentProfile/constants';
import { filterAndDeleteShowPropFromTabs } from '../ResidentProfile/utils';

const Tabs = ({
  id,
  checkUserPermissions,
  affordableTabs,
  flags,
  householdId,
  hudTab,
  isCommercial,
  onChange,
  selectedProperty,
  showCamTab,
  showDocumentsTab,
  showLeaseAppTab,
  showTextingTab,
  value,
}) => {
  return (
    <CLTabs
      id={id}
      value={value}
      onChange={(_, value) => onChange(value)}
      scrollButtons="auto"
      sx={{ mb: 1, borderBottom: '1px solid', borderColor: 'divider' }}
      variant="scrollable"
      tabs={filterAndDeleteShowPropFromTabs([
        {
          label: <FormattedMessage {...messages.prospectInformation} />,
          value: TAB_KEYS.PROSPECT_INFO,
          show: isCommercial,
        },
        {
          label: <FormattedMessage {...messages.activityTab} />,
          value: TAB_KEYS.ACTIVITY,
          disabled: !checkUserPermissions(['activity-read']),
        },
        {
          label: <FormattedMessage {...messages.texting} />,
          value: TAB_KEYS.TEXTING,
          icon: <TextingTabIcon householdId={householdId} />,
          show: showTextingTab,
        },
        {
          label: <FormattedMessage {...messages.documentsTab} />,
          value: TAB_KEYS.DOCUMENTS,
          show: showDocumentsTab,
        },
        {
          label: <FormattedMessage {...messages.ledgerTab} />,
          value: TAB_KEYS.LEDGER,
        },
        {
          label: <FormattedMessage {...messages.applicationTab} />,
          value: TAB_KEYS.LEASE_APPLICATION,
          show: showLeaseAppTab && !isCommercial,
        },
        {
          label: <FormattedMessage {...messages.leaseDataTab} />,
          value: TAB_KEYS.LEASE_DATA,
        },
        {
          label: <FormattedMessage {...messages.housingAssistanceVoucherTab} />,
          value: TAB_KEYS.HOUSING_ASSISTANCE_VOUCHER,
          show:
            flags.housingChoiceVouchers &&
            selectedProperty?.config?.housingAssistanceVouchers,
        },
        {
          label: <FormattedMessage {...messages.camTab} />,
          value: TAB_KEYS.CAM,
          show: showCamTab,
        },
        ...affordableTabs?.map(
          ({ programName, combinedName, urlProgramName }) => ({
            label: `${combinedName ?? programName} Qualification`,
            value: urlProgramName,
          }),
        ),
        {
          label: `${hudTab?.programName} Qualification`,
          value: TAB_KEYS.HUD,
          show: Boolean(hudTab),
        },
      ])}
    />
  );
};

export default Tabs;
