import React, { memo } from 'react';
import {
  Box,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { palette } from '@fortress-technology-solutions/fortress-component-library/design';
import {
  Input,
  NumericInput,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { MoneyIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { Controller } from 'react-hook-form';

const TableInput = memo(({ name, control, placeholder, inputType }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => {
        const { onChange, value } = field;

        if (inputType === 'currency') {
          return (
            <NumericInput
              value={value || ''}
              onChange={onChange}
              placeholder={placeholder || ''}
              startAdornment={<MoneyIcon />}
              gutterBottom={false}
              step="0.01"
              HelperTextProps={{
                sx: { display: 'none' },
              }}
            />
          );
        }
        return (
          <Input
            value={value || ''}
            onChange={onChange}
            placeholder={placeholder || ''}
            gutterBottom={false}
            HelperTextProps={{
              sx: { display: 'none' },
            }}
          />
        );
      }}
    />
  );
});

const CashFlowTable = ({ data, headers, tableState, control }) => {
  return (
    <Box>
      <Box display="flex">
        {headers.map((header, headerIndex) => (
          <Box
            key={headerIndex}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: header.flex,
              textAlign: header.textAlign || 'right',
              padding: '8px 16px',
            }}
          >
            <Box>
              <Typography variant="labelLarge">{header.label}</Typography>
            </Box>
            {header.subLabel && (
              <Box>
                <Typography variant="bodySmall" color="textSecondary">
                  {header.subLabel}
                </Typography>
              </Box>
            )}
          </Box>
        ))}
      </Box>
      {data.map((section, sectionIndex) => (
        <Box key={sectionIndex}>
          <Box
            display="flex"
            padding="8px 16px"
            sx={{
              backgroundColor: palette.lighter.lightGrey,
              borderTop: `1px solid ${palette.lighter.lightGrey}`,
            }}
          >
            <Typography variant="labelLarge">{section.header}</Typography>
            {section?.headerSuffix && (
              <Typography variant="labelLarge" color="textSecondary">
                {' '}
                {section?.headerSuffix}
              </Typography>
            )}
          </Box>

          {section.rows.map((row, rowIndex) => (
            <Box
              key={rowIndex}
              display="flex"
              alignItems="center"
              sx={{
                borderBottom: `1px solid ${palette.light.lightGreyAlt}`,
              }}
            >
              {headers.map((header, headerIndex) => {
                const page = tableState?.page;
                const keyParts = [page, sectionIndex, rowIndex, header.key];
                const name = keyParts.join('-');
                const value = row[header.key];
                const suffixValue = row[`${header.key}Suffix`];
                const isLabelOnly = row.isLabelOnly;

                return (
                  <Box
                    key={headerIndex}
                    flex={header.flex || 1}
                    textAlign={header.textAlign || 'right'}
                    padding="8px 16px"
                  >
                    {header.editable && !isLabelOnly ? (
                      <TableInput
                        name={name}
                        control={control}
                        placeholder={header.placeholder}
                        inputType={header.type}
                      />
                    ) : (
                      <>
                        <Typography variant="bodySmall">{value}</Typography>
                        {suffixValue && (
                          <Typography variant="bodySmall" color="textSecondary">
                            {' '}
                            {suffixValue}
                          </Typography>
                        )}
                      </>
                    )}
                  </Box>
                );
              })}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default memo(CashFlowTable);
