export const dropdownValues = [
  {
    value: 'qualifications.LIHTC',
    text: '(LIHTC/HOME) Qualifications/ Personal data',
    disabled: false,
  },
  {
    value: 'assets.LIHTC',
    text: '(LIHTC) Assets',
    disabled: false,
  },
  {
    value: 'income.LIHTC',
    text: '(LIHTC) Incomes',
    disabled: false,
  },
  {
    value: 'assets.HOME',
    text: '(HOME) Assets',
    disabled: false,
  },
  {
    value: 'income.HOME',
    text: '(HOME) Incomes',
    disabled: false,
  },
  {
    value: 'qualifications.NOAH',
    text: '(NOAH) Qualifications/ Personal data',
    disabled: false,
    noah: true,
  },
  {
    value: 'assets.NOAH',
    text: '(NOAH) Assets',
    disabled: false,
    noah: true,
  },
  {
    value: 'income.NOAH',
    text: '(NOAH)Incomes',
    disabled: false,
    noah: true,
  },

  {
    value: 'qualifications.Deferred Loan',
    text: '(Deferred Loan) Qualifications/ Personal data',
    disabled: false,
    deferredLoan: true,
  },
  {
    value: 'assets.Deferred Loan',
    text: '(Deferred Loan) Assets',
    disabled: false,
    deferredLoan: true,
  },
  {
    value: 'income.Deferred Loan',
    text: '(Deferred Loan)Incomes',
    disabled: false,
    deferredLoan: true,
  },
  {
    value: 'documents',
    text: 'Documents',
    disabled: false,
  },
  {
    value: 'pets',
    text: 'Pets',
    disabled: false,
  },
  {
    value: 'mincbaseline',
    text: 'MINC Baseline',
    disabled: false,
    rd: true,
  },
  {
    value: 'matbaseline-noprior',
    text: 'MAT Baseline (Skip households with MO certs)',
    disabled: false,
    hud: true,
  },
  {
    value: 'matbaseline',
    text: 'MAT Baseline',
    disabled: false,
    hud: true,
  },
];
