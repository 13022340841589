import { Form } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import { useMemo, useCallback, useEffect } from 'react';
import useReactHookFormProps from '../../../hooks/useReactHookFormProps';
import { shouldDisableDate } from './utils';
import HeaderForm from './HeaderForm';

const RDLimitForm = ({
  floorPlan,
  withHeader,
  noteRentOnly,
  onChange,
  handleIsValid,
  handleIsDirty,
}) => {
  const {
    rdUtilityAllowance,
    disableDateNoteRent,
    disableDateBasicRent,
    disableDateUtilityAllowance,
  } = floorPlan;

  //Note: No UA - WHEN: A floor plan does not have an RD Utility Allowance
  const noUA = !rdUtilityAllowance;

  const ReactHookFormProps = useReactHookFormProps({
    defaultValues: {
      id: floorPlan.id,
      internalName: floorPlan?.internalName ?? '',
      marketingName: floorPlan?.marketingName ?? '',
      noteRent: floorPlan?.noteRent ?? '',
      basicRent: floorPlan?.basicRent ?? '',
      rdUtilityAllowance: rdUtilityAllowance ?? 0,
      utilityAlowancesId: floorPlan?.utilityAlowancesId,
    },
  });

  const { getValues, isValid, isDirty, watch } = ReactHookFormProps;
  const newNoteRentWatch = watch('newNoteRent');
  const newBasicRentWatch = watch('newBasicRent');
  const newRdUtilityAllowanceWatch = watch('newRdUtilityAllowance');

  useEffect(() => {
    handleIsValid(floorPlan.id, isValid);
    handleIsDirty(floorPlan.id, isDirty);
  }, [handleIsValid, handleIsDirty, floorPlan, isValid, isDirty]);

  useEffect(() => {
    if (isValid) {
      onChange(getValues());
    }
  }, [isValid, getValues, onChange]);

  const noUAFields = useMemo(
    () => [
      {
        variant: 'text',
        name: 'internalName',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'text',
        name: 'marketingName',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'number',
        name: 'noteRent',
        adornment: '$',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'number',
        name: 'newNoteRent',
        adornment: '$',
        rules: {
          min: 1,
          step: 1,
          validate: (value) => {
            const startDate = getValues('startDate');
            return !(value && !startDate);
          },
        },
        disabled: false,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'number',
        name: 'basicRent',
        adornment: '$',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'number',
        name: 'newBasicRent',
        adornment: '$',
        rules: {
          min: 1,
          step: 1,
          validate: (value) => {
            const startDate = getValues('startDate');
            return !(value && !startDate);
          },
        },
        disabled: false,
        GridProps: {
          xs: 1,
        },
      },

      {
        variant: 'date',
        name: 'startDate',
        format: 'YYYY-MM-DD',
        placeholder: 'YYYY-MM-DD',
        rules: {
          required: ({ getValues }) => {
            const newBasicRent = Boolean(getValues('newBasicRent')?.length);
            const newNoteRent = Boolean(getValues('newNoteRent')?.length);

            return newNoteRent || newBasicRent;
          },
          validate: (value) => {
            const newBasicRent = getValues('newBasicRent');
            const newNoteRent = getValues('newNoteRent');
            if ((newBasicRent?.length || newNoteRent?.length) && !value) {
              return 'Start Date is required when fee amount is provided';
            }

            if (value && !newBasicRent && !newNoteRent) {
              return 'Required an one fee amount';
            }
            return true;
          },
        },
        GridProps: {
          xs: {
            width: 2,
          },
        },
        shouldDisableDate: (date) => {
          // eslint-disable-next-line no-lone-blocks
          {
            let disabledDate = disableDateNoteRent;
            if (newNoteRentWatch && !newBasicRentWatch) {
              disabledDate = disableDateNoteRent;
            }

            if (!newNoteRentWatch && newBasicRentWatch) {
              disabledDate = disableDateBasicRent;
            }

            return shouldDisableDate(date, disabledDate);
          }
        },
      },
    ],
    [
      getValues,
      disableDateNoteRent,
      disableDateBasicRent,
      newBasicRentWatch,
      newNoteRentWatch,
    ],
  );

  const noteRentOnlyFields = useMemo(
    () => [
      {
        variant: 'text',
        name: 'internalName',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'text',
        name: 'marketingName',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'number',
        name: 'noteRent',
        adornment: '$',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },

      {
        variant: 'number',
        name: 'newNoteRent',
        adornment: '$',
        rules: {
          min: 1,
          step: 1,
          validate: (value) => {
            const startDate = getValues('startDate');
            return !(value && !startDate);
          },
        },
        disabled: false,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'number',
        name: 'rdUtilityAllowance',
        adornment: '$',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'number',
        name: 'newRdUtilityAllowance',
        adornment: '$',
        rules: {
          min: 1,
          step: 1,
          validate: (value) => {
            const startDate = getValues('startDate');
            return !(value && !startDate);
          },
        },
        disabled: false,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'date',
        name: 'startDate',
        format: 'YYYY-MM-DD',
        placeholder: 'YYYY-MM-DD',
        rules: {
          required: ({ getValues }) => {
            const newRdUtilityAllowance = Boolean(
              getValues('newRdUtilityAllowance')?.length,
            );

            const newNoteRent = Boolean(getValues('newNoteRent')?.length);

            return newNoteRent || newRdUtilityAllowance;
          },
          validate: (value) => {
            const newRdUtilityAllowance = getValues('newRdUtilityAllowance');

            const newNoteRent = getValues('newNoteRent');
            if (
              (newRdUtilityAllowance?.length || newNoteRent?.length) &&
              !value
            ) {
              return 'Start Date is required when fee amount is provided';
            }

            if (value && !newRdUtilityAllowance && !newNoteRent) {
              return 'Required an one fee amount';
            }
            return true;
          },
        },
        GridProps: {
          xs: {
            width: 2,
          },
        },
        shouldDisableDate: (date) => {
          // eslint-disable-next-line no-lone-blocks
          {
            let disabledDate = disableDateUtilityAllowance;
            if (newNoteRentWatch && !newRdUtilityAllowanceWatch) {
              disabledDate = disableDateNoteRent;
            }

            if (!newNoteRentWatch && newRdUtilityAllowanceWatch) {
              disabledDate = disableDateUtilityAllowance;
            }

            return shouldDisableDate(date, disabledDate);
          }
        },
      },
    ],
    [
      getValues,
      disableDateUtilityAllowance,
      disableDateNoteRent,
      newNoteRentWatch,
      newRdUtilityAllowanceWatch,
    ],
  );

  const allFields = useMemo(
    () => [
      {
        variant: 'text',
        name: 'internalName',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'text',
        name: 'marketingName',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'number',
        name: 'noteRent',
        adornment: '$',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },

      {
        variant: 'number',
        name: 'newNoteRent',
        adornment: '$',
        rules: {
          min: 1,
          step: 1,
          validate: (value) => {
            const startDate = getValues('startDate');
            return !(value && !startDate);
          },
        },
        disabled: false,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'number',
        name: 'basicRent',
        adornment: '$',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'number',
        name: 'newBasicRent',
        adornment: '$',
        rules: {
          min: 1,
          step: 1,
          validate: (value) => {
            const startDate = getValues('startDate');
            return !(value && !startDate);
          },
        },
        disabled: false,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'number',
        name: 'rdUtilityAllowance',
        adornment: '$',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'number',
        name: 'newRdUtilityAllowance',
        adornment: '$',
        rules: {
          min: 1,
          step: 1,
          validate: (value) => {
            const startDate = getValues('startDate');
            return !(value && !startDate);
          },
        },
        disabled: false,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'date',
        name: 'startDate',
        format: 'YYYY-MM-DD',
        placeholder: 'YYYY-MM-DD',
        rules: {
          required: ({ getValues }) => {
            const newRdUtilityAllowance = Boolean(
              getValues('newRdUtilityAllowance')?.length,
            );
            const newBasicRent = Boolean(getValues('newBasicRent')?.length);
            const newNoteRent = Boolean(getValues('newNoteRent')?.length);

            return newNoteRent || newBasicRent || newRdUtilityAllowance;
          },
          validate: (value) => {
            const newRdUtilityAllowance = getValues('newRdUtilityAllowance');
            const newBasicRent = getValues('newBasicRent');
            const newNoteRent = getValues('newNoteRent');
            if (
              (newRdUtilityAllowance?.length ||
                newBasicRent?.length ||
                newNoteRent?.length) &&
              !value
            ) {
              return 'Start Date is required when fee amount is provided';
            }

            if (
              value &&
              !newRdUtilityAllowance &&
              !newBasicRent &&
              !newNoteRent
            ) {
              return 'Required an one fee amount';
            }
            return true;
          },
        },
        GridProps: {
          xs: {
            width: 2,
          },
        },
      },
    ],
    [
      getValues,
      disableDateUtilityAllowance,
      disableDateNoteRent,
      disableDateBasicRent,
      newNoteRentWatch,
      newBasicRentWatch,
      newRdUtilityAllowanceWatch,
    ],
  );

  const formFields = useCallback(() => {
    if (noUA) {
      return noUAFields;
    }
    if (noteRentOnly) {
      return noteRentOnlyFields;
    }
    return allFields;
  }, [noUAFields, noteRentOnlyFields, allFields, noUA, noteRentOnly]);

  const { formProps } = useMemo(() => {
    const props = {
      formProps: {},
    };

    props.formProps = {
      fields: formFields(),
    };

    return props;
  }, [formFields]);

  return (
    <>
      {withHeader && <HeaderForm noUA={noUA} noteRentOnly={noteRentOnly} />}
      <Form ReactHookFormProps={ReactHookFormProps} {...formProps} />
    </>
  );
};

export default RDLimitForm;
