import React from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';
import isNil from 'lodash/isNil';
import { get } from '../../utils/api';
import {
  PROVIDERS,
  SCREENING_POLL_INTERVAL,
  STATUSES,
  TRANSUNION_SCREENING_METHODS,
} from './constants';
import useSelectedProperty from '../../hooks/useSelectedProperty';

export function useHouseholdScreening({ householdId, shouldPoll }) {
  const { data, isLoading, refetch } = useQuery(
    ['householdScreening', householdId],
    () => {
      return get(
        `${process.env.REACT_APP_SCREENING_SERVICE_API_URL}/screenings/households/${householdId}`,
        {
          isOtherApi: true,
        },
      );
    },
    {
      refetchInterval: shouldPoll ? SCREENING_POLL_INTERVAL : false,
      refetchOnWindowFocus: false,
    },
  );

  return {
    householdScreening: data?.result,
    isLoadingHouseholdScreening: isLoading,
    refetchHouseholdScreening: refetch,
  };
}

export function useOneUser({ userId }) {
  const selectedProperty = useSelectedProperty();
  const { data } = useQuery(
    ['user', userId, selectedProperty.organizationId],
    () => {
      return get(`/${selectedProperty.organizationId}/users/${userId}`);
    },
    {
      enabled: !isNil(userId),
      refetchOnWindowFocus: false,
    },
  );

  return data;
}

export function useScreeningData({ householdId, missingRequiredFields }) {
  const [shouldPollHouseholdScreening, setShouldPollHouseholdScreening] =
    React.useState(true);
  const { householdScreening, refetchHouseholdScreening } =
    useHouseholdScreening({
      householdId,
      shouldPoll: shouldPollHouseholdScreening,
    });
  const submittedByUser = useOneUser({
    userId: householdScreening?.createdById,
  });

  const transUnionStatuses = React.useMemo(() => {
    if (missingRequiredFields.length > 0)
      return {
        creditStatus: STATUSES.NOT_READY,
        criminalStatus: STATUSES.NOT_READY,
        evictionStatus: STATUSES.NOT_READY,
      };

    if (householdScreening && householdScreening.requests?.length === 0)
      return {
        creditStatus: STATUSES.PENDING,
        criminalStatus: STATUSES.PENDING,
        evictionStatus: STATUSES.PENDING,
      };

    const screeningMethodDetails = householdScreening?.requests?.find((r) => {
      const providerPropertyProfile = r.providerPropertyProfile;

      return (
        providerPropertyProfile?.isActive &&
        providerPropertyProfile?.isEnabled &&
        providerPropertyProfile.provider?.name === PROVIDERS.TRANSUNION
      );
    })?.result?.details;

    if (!screeningMethodDetails)
      return {
        creditStatus: STATUSES.NOT_STARTED,
        criminalStatus: STATUSES.NOT_STARTED,
        evictionStatus: STATUSES.NOT_STARTED,
      };

    const getStatus = (method) => {
      const status =
        STATUSES[
          screeningMethodDetails.find(
            (d) =>
              d.screeningMethod?.method?.toLowerCase() === method.toLowerCase(),
          )?.status
        ];

      if (status === STATUSES.NOT_STARTED) return STATUSES.PENDING;

      return status ?? STATUSES.NOT_STARTED;
    };

    const creditStatus = getStatus(TRANSUNION_SCREENING_METHODS.CREDIT);
    const criminalStatus = getStatus(TRANSUNION_SCREENING_METHODS.CRIMINAL);
    const evictionStatus = getStatus(TRANSUNION_SCREENING_METHODS.EVICTION);

    return {
      creditStatus,
      criminalStatus,
      evictionStatus,
    };
  }, [householdScreening, missingRequiredFields.length]);
  const transUnionStatusesAreAllApproved = React.useMemo(
    () =>
      Object.values(transUnionStatuses).every(
        (status) => status === STATUSES.APPROVED,
      ),
    [transUnionStatuses],
  );
  const screeningStatus = React.useMemo(() => {
    if (missingRequiredFields.length > 0) return STATUSES.NOT_READY;
    if (!householdScreening) return STATUSES.NOT_STARTED;
    if (transUnionStatusesAreAllApproved) return STATUSES.INCOMPLETE;
    return STATUSES.PENDING;
  }, [
    missingRequiredFields.length,
    householdScreening,
    transUnionStatusesAreAllApproved,
  ]);
  const submittedByUserFullName = submittedByUser
    ? `${submittedByUser.firstName} ${submittedByUser.lastName}`
    : '';
  const submittedOnFormatted = householdScreening?.createdAt
    ? moment(householdScreening.createdAt).format('YYYY-MM-DD')
    : '';
  const submissionData =
    submittedByUser && householdScreening
      ? {
          submittedBy: submittedByUserFullName,
          submittedOn: submittedOnFormatted,
        }
      : null;

  React.useEffect(() => {
    setShouldPollHouseholdScreening(transUnionStatusesAreAllApproved === false);
  }, [transUnionStatusesAreAllApproved]);

  return {
    transUnionStatuses,
    refetchHouseholdScreening,
    transUnionStatusesAreAllApproved,
    submissionData,
    screeningStatus,
  };
}
