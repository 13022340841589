import { AFFORDABLE_PROGRAMS } from '../../constants/affordableProgramNames';
import HUD_SUBSIDY_CODES from '../../constants/hudSubsidyCodes';

export const checkIsSection236 = ({
  shouldCheck,
  section236Flag,
  unit,
  selectedProperty,
}: {
  shouldCheck: boolean,
  section236Flag: boolean,
  unit?: Object,
  selectedProperty?: Object,
}): boolean => {
  if (!shouldCheck || !section236Flag) {
    return false;
  }

  const fpHudCode = (unit?.floorPlan?.floorPlanAffordablePrograms ?? []).find(
    (fp) =>
      fp?.propertyAffordableProgram?.masterAffordableProgram?.name ===
      AFFORDABLE_PROGRAMS.HUD,
  )?.hudCode;

  const propertyHUDSubsidy = (selectedProperty?.propertyHUDSubsidy ?? []).map(
    (hud) => hud?.masterHUDSubsidy?.code,
  );

  return (
    propertyHUDSubsidy.includes(HUD_SUBSIDY_CODES.SECTION_236) ||
    fpHudCode === HUD_SUBSIDY_CODES.SECTION_236
  );
};

export const filterAndDeleteShowPropFromTabs = (tabs: Array<Object>) =>
  tabs
    .filter((tab) => tab.show ?? true)
    .map((tab) => {
      delete tab.show;
      return tab;
    });
