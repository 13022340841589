import { Modal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import {
  Box,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { formatDateTimeDisplayLocal } from '@fortress-technology-solutions/fortress-component-library/utils';
import _ from 'lodash';

import { actionsMapper } from './constants';

const NotesModal = ({ open, handleClose, modalProps, notes = [] }) => {
  return (
    <Modal open={open} onClose={handleClose} {...modalProps}>
      {notes?.map((note) => {
        const includeOtherReason = note?.reasons?.includes('otherSeeNotes');
        let reasons = note?.reasons;
        if (includeOtherReason) {
          reasons = note?.reasons?.filter(
            (reason) => includeOtherReason && reason !== 'otherSeeNotes',
          );
        }

        return (
          <Box
            sx={{
              paddingBottom: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: 1.5,
              }}
            >
              <Typography color="text.secondary" variant="bodySmall">
                {formatDateTimeDisplayLocal(note.createdAt)}
              </Typography>
              <Typography color="primary" variant="labelMedium">
                {actionsMapper[note.action]}
              </Typography>
            </Box>
            {note?.reasons?.length && (
              <>
                <Typography variant="labelMedium">Reason(s)</Typography>
                <Box
                  component={'ul'}
                  sx={{
                    paddingLeft: 3,
                  }}
                >
                  {reasons?.map((reason) => {
                    return (
                      <Typography
                        component={'li'}
                        color="text.secondary"
                        variant="bodySmall"
                      >
                        {_.startCase(reason)}
                      </Typography>
                    );
                  })}
                  {includeOtherReason && (
                    <Typography
                      component={'li'}
                      color="text.secondary"
                      variant="bodySmall"
                    >
                      {note?.description
                        ? `Other - ${note.description}`
                        : 'Other'}
                    </Typography>
                  )}
                </Box>
              </>
            )}
            {!includeOtherReason && (
              <Typography color="text.secondary" variant="bodySmall">
                {note?.description}
              </Typography>
            )}
          </Box>
        );
      })}
    </Modal>
  );
};

export default NotesModal;
