import React, { Fragment } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';

import DoughnutGraph from '../../../components/Graphs/DoughnutGraph';
import messages from './messages';
import {
  AsyncBox,
  Grid,
} from '@fortress-technology-solutions/fortress-component-library/Molecules';
import {
  StatusIndicator,
  Divider,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import KPICardContainer from '../../../components/KPICardContainer';

const FormatCurrency = ({ value, formatNumber }: Object) => (
  <strong>
    {' '}
    {formatNumber(value, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })}{' '}
  </strong>
);

const labelFormatter = (formatNumber: Function) => {
  return (tooltipItem: Object, data: Object) => {
    const { datasetIndex, index } = tooltipItem;
    const { labels, datasets } = data;
    const value = datasets[datasetIndex].data[index] || 0;
    const label = labels[index];
    const formattedCurrency = formatNumber(value, {
      style: 'currency',
      currency: 'USD',
    });
    return `${label}: ${formattedCurrency}`;
  };
};

const PercentageChange = ({
  changePositive,
  percentChange,
  formatNumber,
}: Object) => (
  <Typography
    display={'block'}
    variant={'bodySmall'}
    color={changePositive ? 'success.main' : 'error.main'}
  >
    <i className={`icon et-caret-${changePositive ? 'up' : 'down'}`} />
    {formatNumber(percentChange, {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })}
  </Typography>
);

const GraphSectionOfCard = ({
  data,
  formatNumber,
  dataLoaded,
  fetching,
}: Object) => {
  const { addDetailsToIncomeKpis } = useFlags();

  if (addDetailsToIncomeKpis) {
    const labels = ['Charges', 'Deposits'];
    const {
      totalNetCharges,
      mtdDeposits,
      lastMonthCharges,
      lastMonthDeposits,
    } = data;
    const chargesPercentChange =
      (totalNetCharges - lastMonthCharges) / lastMonthCharges;
    const depositsPercentChange =
      (mtdDeposits - lastMonthDeposits) / lastMonthDeposits;
    const chargesPositive = totalNetCharges > lastMonthCharges;
    const depositsPositive = mtdDeposits > lastMonthDeposits;
    const usePlaceholder =
      totalNetCharges === 0 && mtdDeposits === 0 && !fetching;
    const label = labelFormatter(formatNumber);

    return (
      <Fragment>
        {usePlaceholder ? (
          <div style={{ height: '150px' }}>
            <FormattedMessage {...messages.noData} />
          </div>
        ) : (
          <DoughnutGraph
            labels={labels}
            data={[totalNetCharges, mtdDeposits]}
            dataLoaded={dataLoaded}
            options={{
              tooltips: {
                callbacks: { label },
              },
            }}
          />
        )}
        <Grid container sx={{ pt: 4, maxWidth: 550, margin: 'auto' }}>
          <Grid item xs={6} sm={12} md={6}>
            <StatusIndicator
              radius={4}
              sx={{
                backgroundColor: 'primary.dark',
                display: 'inline-block',
                mr: 0.5,
              }}
            />
            <Typography variant={'bodySmall'}>
              <FormattedMessage {...messages.lastPeriodCharges} />
              <FormatCurrency
                value={lastMonthCharges}
                formatNumber={formatNumber}
              />
            </Typography>
            {lastMonthCharges > 0 && (
              <PercentageChange
                changePositive={chargesPositive}
                percentChange={chargesPercentChange}
                formatNumber={formatNumber}
              />
            )}
          </Grid>
          <Grid item xs={6} sm={12} md={6}>
            <StatusIndicator
              radius={4}
              sx={{
                backgroundColor: 'primary.light',
                display: 'inline-block',
                mr: 0.5,
              }}
            />
            <Typography variant={'bodySmall'}>
              <FormattedMessage {...messages.lastPeriodDeposits} />
              <FormatCurrency
                value={lastMonthDeposits}
                formatNumber={formatNumber}
              />
              {lastMonthDeposits > 0 && (
                <PercentageChange
                  changePositive={depositsPositive}
                  percentChange={depositsPercentChange}
                  formatNumber={formatNumber}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
      </Fragment>
    );
  } else {
    const labels = ['Charges', 'Deposits'];
    const { mtdCharges, mtdDeposits, lastMonthCharges, lastMonthDeposits } =
      data;
    const chargesPercentChange =
      (mtdCharges - lastMonthCharges) / lastMonthCharges;
    const depositsPercentChange =
      (mtdDeposits - lastMonthDeposits) / lastMonthDeposits;
    const chargesPositive = mtdCharges > lastMonthCharges;
    const depositsPositive = mtdDeposits > lastMonthDeposits;
    const usePlaceholder = mtdCharges === 0 && mtdDeposits === 0 && !fetching;
    const label = labelFormatter(formatNumber);

    return (
      <Fragment>
        {usePlaceholder ? (
          <div style={{ height: '150px' }}>
            <FormattedMessage {...messages.noData} />
          </div>
        ) : (
          <DoughnutGraph
            labels={labels}
            data={[mtdCharges, mtdDeposits]}
            dataLoaded={dataLoaded}
            options={{
              tooltips: {
                callbacks: { label },
              },
            }}
          />
        )}
        <div className="row padtop10">
          <div className="col-xs-7 col-sm-12 text-center">
            <span className="status-ball--small status-darker-blue" />
            <span className="font-10">
              {' '}
              <FormattedMessage {...messages.lastPeriodCharges} />
              <FormatCurrency
                value={lastMonthCharges}
                formatNumber={formatNumber}
              />
              {lastMonthCharges > 0 && (
                <PercentageChange
                  changePositive={chargesPositive}
                  percentChange={chargesPercentChange}
                  formatNumber={formatNumber}
                />
              )}
            </span>
          </div>
          <div className="col-xs-7 col-sm-12 text-center">
            <span className="status-ball--small status-lighter-blue" />
            <span className="font-10">
              {' '}
              <FormattedMessage {...messages.lastPeriodDeposits} />
              <FormatCurrency
                value={lastMonthDeposits}
                formatNumber={formatNumber}
              />
              {lastMonthDeposits > 0 && (
                <PercentageChange
                  changePositive={depositsPositive}
                  percentChange={depositsPercentChange}
                  formatNumber={formatNumber}
                />
              )}
            </span>
          </div>
        </div>
      </Fragment>
    );
  }
};

const getNegativeFormattedNumber = (value) => {
  if (value === 0) {
    return '- ($0.00)';
  }
  return (
    '- ' +
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currencySign: 'accounting',
    }).format(value)
  );
};

const totalStyle = { fontSize: '15px', fontWeight: '500' };

const IncomeGraph = ({
  data,
  formatMessage,
  formatNumber,
  dataLoaded,
  fetching,
  isExportingPDF,
}: Object) => {
  const { addDetailsToIncomeKpis } = useFlags();

  if (addDetailsToIncomeKpis) {
    const {
      mtdRentCharges,
      mtdOtherCharges,
      mtdCredits,
      mtdWriteOffs,
      totalNetCharges,

      mtdDeposits,
      pendingDeposits,
      totalPTDDeposits,
    } = data;

    // We display values rounded to an integer, so the depositPercentOfCharges
    // needs to be calculated from these rounded values too.
    const totalNetChargesInt = Math.round(totalNetCharges);
    const totalPTDDepositsInt = Math.round(totalPTDDeposits);
    let depositsPercentOfCharges =
      totalNetChargesInt === 0
        ? Number.NaN
        : totalPTDDepositsInt / totalNetChargesInt;
    if (depositsPercentOfCharges === -0) {
      // weird case where it would display "-0%"...
      depositsPercentOfCharges = 0;
    }

    return (
      <KPICardContainer title={<FormattedMessage {...messages.income} />}>
        <AsyncBox loading={!dataLoaded}>
          <Grid container>
            <Grid
              item
              {...(isExportingPDF ? { xs: 6 } : { xs: 12, sm: 6 })}
              sx={{ textAlign: 'center' }}
            >
              <Grid
                container
                padding={0}
                fontSize={'10px'}
                spacing={2}
                rowSpacing={0.2}
              >
                <Grid
                  item
                  xs={12}
                  textAlign={'left'}
                  margin={0}
                  fontWeight={500}
                >
                  <FormattedMessage {...messages.ptdIncome} />
                </Grid>
                <Grid item xs={12} paddingBottom={1}>
                  <Divider />
                </Grid>
                <Grid item xs={6} textAlign={'left'}>
                  <FormattedMessage {...messages.ptdRentCharges} />
                </Grid>
                <Grid
                  item
                  xs={6}
                  className="text-blue"
                  textAlign={'right'}
                  data-testid="mtdRentCharges"
                >
                  <FormattedNumber
                    value={mtdRentCharges}
                    style="currency"
                    currency="USD"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </Grid>
                <Grid item xs={6} textAlign={'left'}>
                  <FormattedMessage {...messages.ptdOtherCharges} />
                </Grid>
                <Grid
                  item
                  xs={6}
                  className="text-blue"
                  textAlign={'right'}
                  data-testid="mtdOtherCharges"
                >
                  +{' '}
                  <FormattedNumber
                    value={mtdOtherCharges}
                    style="currency"
                    currency="USD"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </Grid>
                <Grid item xs={6} textAlign={'left'}>
                  <FormattedMessage {...messages.ptdCredits} />
                </Grid>
                <Grid
                  item
                  xs={6}
                  className="text-red"
                  textAlign={'right'}
                  data-testid="mtdCredits"
                >
                  {getNegativeFormattedNumber(mtdCredits)}
                </Grid>
                <Grid item xs={6} textAlign={'left'}>
                  <FormattedMessage {...messages.ptdWriteOffs} />
                </Grid>
                <Grid
                  item
                  xs={6}
                  className="text-red"
                  textAlign={'right'}
                  data-testid="mtdWriteOffs"
                >
                  {getNegativeFormattedNumber(mtdWriteOffs)}
                </Grid>
                <Grid
                  paddingBottom={2}
                  item
                  xs={6}
                  textAlign={'left'}
                  fontWeight={500}
                >
                  <FormattedMessage {...messages.totalNetCharges} />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={totalStyle}
                  className="text-blue"
                  textAlign={'right'}
                  data-testid="totalNetCharges"
                >
                  <FormattedNumber
                    value={totalNetCharges}
                    style="currency"
                    currency="USD"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  textAlign={'left'}
                  margin={0}
                  fontWeight={500}
                >
                  <FormattedMessage {...messages.ptdDepositsAddDetailsToKpis} />
                </Grid>
                <Grid item xs={12} paddingBottom={1}>
                  <Divider />
                </Grid>
                <Grid item xs={6} textAlign={'left'}>
                  <FormattedMessage {...messages.depositsInBank} />
                </Grid>
                <Grid
                  item
                  xs={6}
                  className="text-blue"
                  textAlign={'right'}
                  data-testid="mtdDeposits"
                >
                  <FormattedNumber
                    value={mtdDeposits}
                    style="currency"
                    currency="USD"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </Grid>
                <Grid item xs={6} textAlign={'left'}>
                  <FormattedMessage {...messages.pendingDeposits} />
                </Grid>
                <Grid
                  item
                  xs={6}
                  className="text-blue"
                  textAlign={'right'}
                  data-testid="pendingDeposits"
                >
                  +{' '}
                  <FormattedNumber
                    value={pendingDeposits}
                    style="currency"
                    currency="USD"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </Grid>
                <Grid item xs={6} textAlign={'left'} fontWeight={500}>
                  <FormattedMessage {...messages.totalPTDDeposits} />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={totalStyle}
                  className="text-blue"
                  textAlign={'right'}
                  paddingBottom={2}
                  data-testid="totalPTDDeposits"
                >
                  <FormattedNumber
                    value={totalPTDDeposits}
                    style="currency"
                    currency="USD"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </Grid>
                <Grid item xs={12} paddingBottom={1}>
                  <Divider variant="dashed" />
                </Grid>
                <Grid item xs={8} textAlign={'left'}>
                  <FormattedMessage {...messages.depositsAsPercentage} />
                </Grid>
                <Grid
                  alignSelf={'end'}
                  item
                  xs={4}
                  className="text-blue"
                  textAlign={'right'}
                  columnSpacing={0}
                  data-testid="depositsPercentOfCharges"
                >
                  <FormattedNumber
                    value={depositsPercentOfCharges}
                    style={{ value: 'percent' }.value}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              {...(isExportingPDF ? { xs: 6 } : { xs: 12, sm: 6 })}
              sx={{ textAlign: 'center', padding: 2 }}
            >
              <GraphSectionOfCard
                data={data}
                formatMessage={formatMessage}
                formatNumber={formatNumber}
                dataLoaded={dataLoaded}
                fetching={fetching}
              />
            </Grid>
          </Grid>
        </AsyncBox>
      </KPICardContainer>
    );
  } else {
    const { mtdCharges, mtdDeposits, pendingDeposits } = data;

    // We display values rounded to an integer, so the depositPercentOfCharges
    // needs to be calculated from these rounded values too.
    const mtdChargesInt = Math.round(mtdCharges);
    const mtdDepositsInt = Math.round(mtdDeposits);
    const pendingDepositsInt = Math.round(pendingDeposits);
    let depositsPercentOfCharges =
      mtdChargesInt === 0
        ? Number.NaN
        : (mtdDepositsInt + pendingDepositsInt) / mtdChargesInt;
    if (depositsPercentOfCharges === -0) {
      // weird case where it would display "-0%"...
      depositsPercentOfCharges = 0;
    }
    return (
      <KPICardContainer title={<FormattedMessage {...messages.income} />}>
        <div className="row">
          <div className="col-xs-12 col-sm-6 text-center">
            <span className="font25 text-blue">
              <FormattedNumber
                value={mtdCharges}
                style={{ value: 'currency' }.value}
                currency="USD"
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            </span>
            <h5 className="font-12">
              <FormattedMessage {...messages.ptdCharges} />
            </h5>
            <span className="font25 text-blue">
              <FormattedNumber
                value={mtdDeposits}
                style={{ value: 'currency' }.value}
                currency="USD"
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            </span>
            <h5 className="font-12">
              <FormattedMessage {...messages.ptdDeposits} />
            </h5>
            <span className="font25 text-blue">
              <FormattedNumber
                value={pendingDeposits}
                style={{ value: 'currency' }.value}
                currency="USD"
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            </span>
            <h5 className="font-12">
              <FormattedMessage {...messages.pendingDeposits} />
            </h5>
            <span className="font25 text-blue">
              {Number.isNaN(depositsPercentOfCharges) ? (
                '---'
              ) : (
                <FormattedNumber
                  value={depositsPercentOfCharges}
                  style={{ value: 'percent' }.value}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              )}
            </span>
            <h5 className="font-12">
              <FormattedMessage {...messages.depositsPercentOfCharges} />
            </h5>
          </div>
          <div className="col-xs-12 col-sm-6">
            <AsyncBox loading={!dataLoaded}>
              <GraphSectionOfCard
                data={data}
                formatMessage={formatMessage}
                formatNumber={formatNumber}
                dataLoaded={dataLoaded}
                fetching={fetching}
              />
            </AsyncBox>
          </div>
        </div>
      </KPICardContainer>
    );
  }
};

export default IncomeGraph;
