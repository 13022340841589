import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { ContactBox } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { getSortFn } from '@fortress-technology-solutions/fortress-component-library/utils';
import useIntl from '../../../hooks/useIntl';
import type { Applicant } from '../../CreateApplication/types';
import { getUrlWithSelectedPropertyId } from '../../../utils/navigation-helpers';

type Props = {
  applicants: Array<Applicant>,
  isResident: boolean,
  unitId: string,
  unitNumber: string,
  isCommercial: boolean,
  isUnitSoftDeleted?: boolean,
};

const filterAndTransformApplicants = (
  applicants: Array<Applicant>,
  isCommercial: boolean,
) => {
  const filtered = applicants.filter(
    (applicant) =>
      applicant.resident?.status === 'Current' &&
      applicant.applicantType.financiallyResponsible,
  );

  const sortFn = getSortFn(
    'applicantCustomer.customer.firstName',
    'ASC',
  ).string;
  const sorted = [...filtered].sort(sortFn);

  return sorted.map((applicant) => {
    const customer = applicant.applicantCustomer?.customer ?? {};
    return {
      firstName: customer.firstName ?? '',
      lastName: isCommercial ? '' : customer.lastName ?? '',
      phoneNumber: customer.phoneNumber ?? '',
      emailAddress: customer.emailAddress ?? '',
    };
  });
};

const ResidentProfileDetails = ({
  applicants,
  isResident,
  unitNumber,
  unitId,
  isCommercial,
  isUnitSoftDeleted = false,
}: Props) => {
  const intl = useIntl();
  const contacts = filterAndTransformApplicants(applicants, isCommercial);

  return (
    <ContactBox
      ReactRouterLink={ReactRouterLink}
      contacts={contacts}
      intl={intl}
      isLoading={false}
      variant={isResident ? 'currentResident' : 'priorResident'}
      unitToURL={getUrlWithSelectedPropertyId(`/unit/${unitId}`)}
      unitLinkDisabled={isUnitSoftDeleted}
      unitId={unitId}
      unitNumber={unitNumber}
      applicantTypeOptions={[]}
      onSelectChange={() => {}}
    />
  );
};

export default ResidentProfileDetails;
