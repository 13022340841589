export const GET_ONE_RESIDENT = 'app/ResidentProfile/GET_ONE_RESIDENT';
export const GET_ONE_RESIDENT_SUCCESS =
  'app/ResidentProfile/GET_ONE_RESIDENT_SUCCESS';
export const GET_ONE_RESIDENT_ERROR =
  'app/ResidentProfile/GET_ONE_RESIDENT_ERROR';

export const ASSIGN_RESIDENT = 'app/PeopleProfile/ASSIGN_RESIDENT';
export const UPDATE_RESIDENT = 'app/PeopleProfile/UPDATE_RESIDENT';
export const UPDATE_RESIDENT_SUCCESS =
  'app/PeopleProfile/UPDATE_RESIDENT_SUCCESS';
export const UPDATE_RESIDENT_ERROR = 'app/PeopleProfile/UPDATE_RESIDENT_ERROR';

export const CLEAN_LOADED_RESIDENT =
  'app/ResidentProfile/CLEAN_LOADED_RESIDENT';

export const MOVE_OUT_RESIDENT = 'app/ResidentProfile/MOVE_OUT_RESIDENT';
export const MOVE_OUT_RESIDENT_SUCCESS =
  'app/ResidentProfile/MOVE_OUT_RESIDENT_SUCCESS';
export const SAVE_NOTICE_TO_VACATE =
  'app/ResidentProfile/SAVE_NOTICE_TO_VACATE';
export const SAVE_NOTICE_TO_VACATE_SUCCESS =
  'app/ResidentProfile/SAVE_NOTICE_TO_VACATE_SUCCESS';
export const SAVE_NOTICE_TO_VACATE_ERROR =
  'app/ResidentProfile/SAVE_NOTICE_TO_VACATE_ERROR';

export const DELETE_NOTICE_TO_VACATE =
  'app/ResidentProfile/DELETE_NOTICE_TO_VACATE';
export const DELETE_NOTICE_TO_VACATE_SUCCESS =
  'app/ResidentProfile/DELETE_NOTICE_TO_VACATE_SUCCESS';
export const DELETE_NOTICE_TO_VACATE_ERROR =
  'app/ResidentProfile/DELETE_NOTICE_TO_VACATE_ERROR';

export const UPDATE_DO_NOT_RENEW = 'app/ResidentProfile/UPDATE_DO_NOT_RENEW';
export const UPDATE_DO_NOT_RENEW_SUCCESS =
  'app/ResidentProfile/UPDATE_DO_NOT_RENEW_SUCCESS';

export const UPDATE_FAS_READY = 'app/ResidentProfile/UPDATE_FAS_READY';
export const UPDATE_FAS_READY_SUCCESS =
  'app/ResidentProfile/UPDATE_FAS_READY_SUCCESS';

export const GET_ALL_MONTHLY_TRANSACTIONS_RESIDENT =
  'app/ResidentProfile/GET_ALL_MONTHLY_TRANSACTIONS_RESIDENT';
export const GET_ALL_MONTHLY_TRANSACTIONS_RESIDENT_SUCCESS =
  'app/ResidentProfile/GET_ALL_MONTHLY_TRANSACTIONS_RESIDENT_SUCCESS';
export const GET_ALL_MONTHLY_TRANSACTIONS_RESIDENT_ERROR =
  'app/ResidentProfile/GET_ALL_MONTHLY_TRANSACTIONS_RESIDENT_ERROR';

export const GET_MONTHLY_TRANSACTIONS_RESIDENT =
  'app/ResidentProfile/GET_MONTHLY_TRANSACTIONS_RESIDENT';
export const GET_MONTHLY_TRANSACTIONS_RESIDENT_SUCCESS =
  'app/ResidentProfile/GET_MONTHLY_TRANSACTIONS_RESIDENT_SUCCESS';
export const GET_MONTHLY_TRANSACTIONS_RESIDENT_ERROR =
  'app/ResidentProfile/GET_MONTHLY_TRANSACTIONS_RESIDENT_ERROR';

export const SAVE_MONTHLY_CHARGE_RESIDENT =
  'app/ResidentProfile/SAVE_MONTHLY_CHARGE_RESIDENT';
export const SAVE_MONTHLY_CHARGE_RESIDENT_SUCCESS =
  'app/ResidentProfile/SAVE_MONTHLY_CHARGE_RESIDENT_SUCCESS';

export const SET_MONTHLY_OPTION_RESIDENT =
  'app/ResidentProfile/SET_MONTHLY_OPTION_RESIDENT';

export const GET_APPLICATION_STATUS =
  'app/ApplicationProfile/GET_APPLICATION_DECISION_STATUS';
export const GET_APPLICATION_STATUS_SUCCESS =
  'app/ApplicationProfile/GET_APPLICATION_STATUS_SUCCESS';
export const GET_APPLICATION_STATUS_ERROR =
  'app/ApplicationProfile/GET_APPLICATION_STATUS_ERROR';

export const GET_RESIDENT_SCREENING_STATUS =
  'app/ResidentProfile=/GET_RESIDENT_SCREENING_STATUS';
export const GET_RESIDENT_SCREENING_STATUS_SUCCESS =
  'app/ResidentProfile=/GET_RESIDENT_SCREENING_STATUS_SUCCESS';
export const GET_RESIDENT_SCREENING_STATUS_ERROR =
  'app/ResidentProfile=/GET_RESIDENT_SCREENING_STATUS_ERROR';
export const RENEW_LEASE = 'app/ApplicationProfile/RENEW_LEASE';
export const RENEW_LEASE_SUCCESS = 'app/ApplicationProfile/RENEW_LEASE_SUCCESS';
export const RENEW_LEASE_FAIL = 'app/ApplicationProfile/RENEW_LEASE_FAIL';

export const TRANSFER_LEASE = 'app/ResidentProfile/TRANSFER_LEASE';
export const TRANSFER_LEASE_SUCCESS =
  'app/ResidentProfile/TRANSFER_LEASE_SUCCESS';
export const TRANSFER_LEASE_FAIL = 'app/ResidentProfile/TRANSFER_LEASE_FAIL';

export const SAVE_LEASE_DATA_TAB_RESIDENT =
  'app/ApplicationProfile/SAVE_LEASE_DATA_TAB_RESIDENT';
export const SAVE_LEASE_DATA_TAB_RESIDENT_SUCCESS =
  'app/ApplicationProfile/SAVE_LEASE_DATA_TAB_RESIDENT_SUCCESS';

export const UPDATE_RESIDENT_MOVE_IN_DATE =
  'app/ResidentProfile/UPDATE_RESIDENT_MOVE_IN_DATE';

export const UPDATE_PRIOR_RESIDENT_MOVE_OUT_DATE =
  'app/ResidentProfile/UPDATE_PRIOR_RESIDENT_MOVE_OUT_DATE';

export const UPDATE_RESIDENT_PROPERTY_MOVE_IN_DATE =
  'app/ResidentProfile/UPDATE_RESIDENT_PROPERTY_MOVE_IN_DATE';

export const UPDATE_LABEL_END_DATE_RESIDENT =
  'app/ApplicationProfile/UPDATE_LABEL_END_DATE_RESIDENT';

export const GET_UNIT_BY_ID = 'app/ResidentProfile/GET_UNIT_BY_ID';
export const GET_UNIT_BY_ID_SUCCESS =
  'app/ResidentProfile/GET_UNIT_BY_ID_SUCCESS';
export const GET_UNIT_BY_ID_ERROR = 'app/ResidentProfile/GET_UNIT_BY_ID_ERROR';

export const GENERATE_FUTURE_LEASE_DOCUMENT =
  'app/ResidentProfile/GENERATE_FUTURE_LEASE_DOCUMENT';
export const GENERATE_FUTURE_LEASE_DOCUMENT_SUCCESS =
  'app/ResidentProfile/GENERATE_FUTURE_LEASE_DOCUMENT_SUCCESS';
export const GENERATE_FUTURE_LEASE_DOCUMENT_ERROR =
  'app/ResidentProfile/GENERATE_FUTURE_LEASE_DOCUMENT_ERROR';

export const UPDATE_UNDER_EVICTION =
  'app/ResidentProfile/UPDATE_UNDER_EVICTION';
export const UPDATE_UNDER_EVICTION_SUCCESS =
  'app/ResidentProfile/UPDATE_UNDER_EVICTION_SUCCESS';

export const MANUAL_WRITE_OFF = 'app/ResidentProfile/MANUAL_WRITE_OFF';

export const REMOVE_LEASE = 'app/ResidentProfile/REMOVE_LEASE';
export const REMOVE_LEASE_SUCCESS = 'app/ResidentProfile/REMOVE_LEASE_SUCCESS';

export const GET_APPLICATION_DECISION_STATUS_SUCCESS =
  'app/ApplicationProfile/GET_APPLICATION_DECISION_STATUS_SUCCESS';

export const UPDATE_APPROVE_APPLICANT =
  'app/ApplicationProfile/UPDATE_APPROVE_APPLICANT';

export const UPDATE_APPROVE_APPLICANT_SUCCESS =
  'app/ApplicationProfile/UPDATE_APPROVE_APPLICANT_SUCCESS';

export const UPDATE_CANCEL_APPLICANT =
  'app/ApplicationProfile/UPDATE_CANCEL_APPLICANT';

export const UPDATE_CANCEL_APPLICANT_SUCCESS =
  'app/ApplicationProfile/UPDATE_CANCEL_APPLICANT_SUCCESS';

export const SEND_RESIDENT_PAYLEASE_EMAIL =
  'app/ApplicationProfile/SEND_RESIDENT_PAYLEASE_EMAIL';
export const SEND_RESIDENT_PORTAL_CLAIM_EMAIL =
  'app/ApplicationProfile/SEND_RESIDENT_PORTAL_CLAIM_EMAIL';

export const SET_RECOMPLETE_STARTED =
  'app/ApplicationProfile/SET_RECOMPLETE_STARTED';

export const SET_RECERT_FLAG_STARTED =
  'app/ApplicationProfile/SET_RECERT_FLAG_STARTED';

export const SOCKET_EVENT_FAS_PENDING =
  'app/ResidentProfile/SOCKET_EVENT_FAS_PENDING';
export const SOCKET_EVENT_FAS_SUCCESS =
  'app/ResidentProfile/SOCKET_EVENT_FAS_SUCCESS';
export const SOCKET_EVENT_FAS_ERROR =
  'app/ResidentProfile/SOCKET_EVENT_FAS_ERROR';
export const SOCKET_EVENT_FAS_REVERSAL_SUCCESS =
  'app/ResidentProfile/SOCKET_EVENT_FAS_REVERSAL_SUCCESS';
export const SOCKET_EVENT_FAS_REVERSAL_ERROR =
  'app/ResidentProfile/SOCKET_EVENT_FAS_REVERSAL_ERROR';

export const UPDATE_AUTO_EMAIL_MONTHLY_INVOICE =
  'app/ResidentProfile/UPDATE_AUTO_EMAIL_MONTHLY_INVOICE';
export const UPDATE_AUTO_EMAIL_MONTHLY_INVOICE_SUCCESS =
  'app/ResidentProfile/UPDATE_AUTO_EMAIL_MONTHLY_INVOICE_SUCCESS';

export const GET_FIRST_UNIT_MOVE_IN_DATE_AFTER_PRIOR_RESIDENT =
  'app/ResidentProfile/GET_FIRST_UNIT_MOVE_IN_DATE_AFTER_PRIOR_RESIDENT';
export const GET_FIRST_UNIT_MOVE_IN_DATE_AFTER_PRIOR_RESIDENT_SUCCESS =
  'app/ResidentProfile/GET_FIRST_UNIT_MOVE_IN_DATE_AFTER_PRIOR_RESIDENT_SUCCESS';
export const GET_FIRST_UNIT_MOVE_IN_DATE_AFTER_PRIOR_RESIDENT_ERROR =
  'app/ResidentProfile/GET_FIRST_UNIT_MOVE_IN_DATE_AFTER_PRIOR_RESIDENT_ERROR';

export const ADULT_GENERAL_PICK_ARRAY = [
  'preferredName',
  'firstName',
  'middleName',
  'lastName',
  'phoneNumber',
  'emailAddress',
  'parentCompany',
];

export const ADULT_PERSONAL_PICK_ARRAY = [
  'birthday',
  'passportNumber',
  'passportCountry',
  'stateIdNumber',
  'stateIdOrigin',
  'socialSecurityNumber',
  'alienRegistration',
];

export const ADULT_AFFORDABLE_PICK_ARRAY = [
  'gender',
  'handicapped',
  'ethnicity',
  'race',
  'multiRace',
  'veteran',
  'raceSource',
];

export const MINOR_GENERAL_PICK_ARRAY = [
  'preferredName',
  'firstName',
  'middleName',
  'lastName',
];

export const MINOR_PERSONAL_PICK_ARRAY = [
  'dateOfBirth',
  'socialSecurityNumber',
];

export const MINOR_AFFORDABLE_PICK_ARRAY = [
  'ethnicity',
  'gender',
  'jointCustody',
  'race',
  'multiRace',
  'studentType',
  'raceSource',
];

export const PICK_ARRAYS = {
  adult: {
    generalInformation: ADULT_GENERAL_PICK_ARRAY,
    personalInformation: ADULT_PERSONAL_PICK_ARRAY,
    affordableInformation: ADULT_AFFORDABLE_PICK_ARRAY,
  },
  minor: {
    generalInformation: MINOR_GENERAL_PICK_ARRAY,
    personalInformation: MINOR_PERSONAL_PICK_ARRAY,
    affordableInformation: MINOR_AFFORDABLE_PICK_ARRAY,
  },
};

export const FETCH_CAM_POOLS = 'app/Ledger/FETCH_CAM_POOLS';
export const FETCH_CAM_POOLS_SUCCESS = 'app/Ledger/FETCH_CAM_POOLS_SUCCESS';
export const FETCH_CAM_POOLS_ERROR = 'app/Ledger/FETCH_CAM_POOLS_ERROR';

// Some options are being deprecated but should still be available for existing data.
export const DEPRECATED_MOVE_OUT_REASON_IDS = [
  '46e28447-6a6f-4f15-b93f-10bc64c81c3c',
];

export const MOVE_OUT_PRORATE_MEHTODS = {
  YES_FULL_MONTH: 'Yes - Full Month Charge, Reconcile at Move-Out',
  YES_MONTH_START: 'Yes - Prorate at Start of Month',
  NO_NEVER: 'No - Never Prorate',
};

export const TAB_KEYS = {
  ACTIVITY: 'activity',
  WORK_ORDERS: 'workOrders',
  TEXTING: 'texting',
  LEDGER: 'ledger',
  LEASE_DATA: 'leaseData',
  HOUSING_ASSISTANCE_VOUCHER: 'housingAssistanceVoucher',
  CAM: 'cam',
  HUD: 'hud',
  LEASE_APPLICATION: 'leaseApp',
  DOCUMENTS: 'documents',
  PROSPECT_INFO: 'prospectInfo',
  QUALIFICATION_HISTORY: 'qualificationHistory',
};
