import React from 'react';
import {
  Box,
  Stack,
  Paper,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';

const ProfileLayout = ({
  ContactBox,
  Household,
  Snapshot,
  Tabs,
  TabsContent,
}) => {
  return (
    <Box
      padding={2}
      height={'100%'}
      component="div"
      display={'grid'}
      gridTemplateColumns={{ xs: '1fr', lg: '450px 1fr' }}
      gap={2}
    >
      <Box gridColumn={1} sx={{ lg: { overflowY: 'auto' } }}>
        <Stack gap={2} paddingX={'1px'}>
          <Paper>{ContactBox}</Paper>
          <Paper>{Snapshot}</Paper>
          <Paper>{Household}</Paper>
        </Stack>
      </Box>

      <Paper
        sx={{
          height: '100%',
          gridColumn: { xs: 1, lg: 2 },
          overflowX: 'hidden',
        }}
      >
        {Tabs}
        <Box padding={2} minHeight={500}>
          {TabsContent}
        </Box>
      </Paper>
    </Box>
  );
};

export default ProfileLayout;
