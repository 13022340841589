import { defineMessages } from 'react-intl';

/* eslint-disable max-len */
const messages = defineMessages({
  title: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.Title',
    defaultMessage: 'Prospect Information',
  },
  applicationChecklist: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.ApplicationChecklist',
    defaultMessage: 'Application Checklist',
  },
  leaseApplication: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.LeaseApplication',
    defaultMessage: 'Lease Application',
  },
  credirOrCriminal: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CredirOrCriminal',
    defaultMessage: 'Credit/Criminal',
  },
  status: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.status',
    defaultMessage: 'Status',
  },
  applicationNotes: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.ApplicationNotes',
    defaultMessage: 'Application Notes',
  },
  saveNote: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.saveNote',
    defaultMessage: 'Save Note',
  },
  editNote: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.editNote',
    defaultMessage: 'Edit/View Note',
  },
  cannotScreen: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CannotScreen',
    defaultMessage: `Cannot screen until all applications have been received.
      Please rescreen if change to any adult's Name, SSN or DOB`,
  },
  cannotScreenUpdated: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CannotScreenUpdated',
    defaultMessage:
      "You cannot screen until all applications have been received. Please rescreen if you change the Applicant Type or an adult's Last Name, SSN, DOB, or Employment Income.",
  },
  approvedApplications: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.approvedApplications',
    defaultMessage: 'Approved Applications',
  },
  pendingApplications: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.pendingApplications',
    defaultMessage: 'Pending Applications',
  },
  canceledApplications: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.canceledApplications',
    defaultMessage: 'Canceled Applications',
  },
  cannotScreenPending: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.cannotScreenPending',
    defaultMessage: 'Cannot screen until all applications have been received.',
  },
  approveApplications: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.approveApplications',
    defaultMessage: 'Approve Applications',
  },
  approveApplicationsConfirmation: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.ApproveApplicationsConfirmation',
    defaultMessage:
      'Are you sure you want to convert these Applicants into Residents?',
  },
  leaseLockedSentToPortal: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.LeaseLockedSentToPortal',
    defaultMessage:
      'A Lease for this household has been sent to Portal. If you need to edit the Application or the Application status, please go to the Lease Data tab and cancel the outstanding Lease.',
  },
  leaseLockedExecuted: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.LeaseLockedExecuted',
    defaultMessage:
      'A Lease for this household has been Executed. If you need to edit the Application or the Application status, please go to the Lease Data tab and cancel the outstanding Lease.',
  },
  permissionRequired: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.PermissionRequired',
    defaultMessage: 'Permissions required',
  },
  waitlistClosed: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.WaitlistClosed',
    defaultMessage: 'The waitlist status is closed',
  },
});

export default messages;
