/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  stateProjectIdXml: {
    id: 'App.ViewProperty.AffordableSetup.Programs.STATEPROG.StateProjectIdXml',
    defaultMessage: 'State / Project ID (XML)*',
  },
  stateProjectIdTicHec: {
    id: 'App.ViewProperty.AffordableSetup.Programs.STATEPROG.StateProjectIdTicHec',
    defaultMessage: 'State / Project ID (TIC/HEC)*',
  },
  initialCertForNonHUD: {
    id: 'App.ViewProperty.AffordableSetup.Programs.STATEPROG.InitialCertForNonHUD',
    defaultMessage: 'Initial Certs for {programName} and Other Programs',
  },
  initialCertForNonHUDTip: {
    id: 'App.ViewProperty.AffordableSetup.Programs.STATEPROG.InitialCertForNonHUDTip',
    defaultMessage:
      'This feature will allow users to start and Initital Certification from the {programName} and Other Programs Qualification tab for any households that do not have an Initial Cert record completed in Fortress.',
  },
  altXMLPropertyName: {
    id: 'App.ViewProperty.AffordableSetup.Programs.STATEPROG.AltXMLPropertyName',
    defaultMessage: 'Affordable Property Name (XML)',
  },
  altXMLProjectName: {
    id: 'App.ViewProperty.AffordableSetup.Programs.STATEPROG.AltXMLProjectName',
    defaultMessage: 'Affordable Project Name (XML)',
  },
  altXMLProjectNameTooltip: {
    id: 'App.ViewProperty.AffordableSetup.Programs.STATEPROG.AltXMLProjectNameTooltip',
    defaultMessage:
      'To change the XML name of different sites within your project, navigate to the Manage Buildings screen.',
  },
  overrideQualification: {
    id: 'App.ViewProperty.AffordableSetup.Programs.STATEPROG.OverrideQualification',
    defaultMessage: 'Override Qualification',
  },
  interimCerts: {
    id: 'App.ViewProperty.AffordableSetup.Programs.STATEPROG.InterimCerts',
    defaultMessage: 'Interim Certs',
  },
});

export default messages;
