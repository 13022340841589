import CashFlowTable from './table';
import React, { memo } from 'react';

const headers = [
  { label: '', key: 'label', flex: 3, textAlign: 'left' },
  { label: 'Current Budget', key: 'currentBudget', flex: 1 },
  { label: 'Actual', key: 'actual', flex: 1 },
  {
    label: 'Proposed Budget',
    key: 'proposedBudget',
    flex: 1,
    editable: true,
    placeholder: '0.00',
    type: 'currency',
  },
  {
    label: 'Comments or (YTD)',
    key: 'comments',
    flex: 1,
    editable: true,
    placeholder: 'Comment or (YTD)',
  },
];

const data = [
  {
    header: 'Operating and Maintenance',
    rows: [
      {
        label: '1. Maintenance and Repairs Payroll',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '2. Maintenance and Repairs Supply',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '3. Maintenance and Repairs Contract',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '4. Painting',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '5. Snow Removal',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '6. Elevator Maintenance/Contract',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '7. Grounds',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '8. Services',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '9. Annual Capital Budget',
        labelSuffix: '(From Part V - Operating)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '10. Other Operating Expenses',
        labelSuffix: '(Itemize)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '11. Sub-Total Maint. and Operating',
        labelSuffix: '(1 thru 10)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
    ],
  },
  {
    header: 'Utilities',
    headerSuffix: '(*If master metered check box on front)',
    rows: [
      {
        label: '12. Electricity *',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '13. Water *',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '14. Sewer *',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '15. Fuel (Oil, Coal, Gas)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '16. Garbage and Trash Removal',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '17. Other Utilities',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '18. Sub-Total Utilities',
        labelSuffix: '(12 thru 17)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
    ],
  },
  {
    header: 'Administrative',
    rows: [
      {
        label: '19. Site Management Payroll',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '20. Management Fee',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '21. Project Auditing Expense',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '22. Project Bookkeeping/Accounting',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '23. Legal Expenses',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '24. Advertising',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '25. Telephone and Answering Service',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '26. Office Supplies',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '27. Office Funiture and Equipment',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '28. Training Expense',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '29. Health Ins. and Other Emp. Benefits',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '30. Payroll Taxes',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: "31. Worker's Compensation",
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '32. Other Administrative Expenses',
        labelSuffix: '(Itemize)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '33. Sub-Total Administrative',
        labelSuffix: '(19 thru 32)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
    ],
  },
  {
    header: 'Taxes and Insurance',
    rows: [
      {
        label: '34. Real Estate Taxes',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '35. Special Assessments',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '36. Other Taxes, Licenses, and Permits',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '37. Property and Liability Insurance',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '38. Fidelity Coverage Insurance',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '39. Other Insurance',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '40. Sub-Total Taxes and Insurance',
        labelSuffix: '(34 thru 39)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '41. Total O&M Expenses',
        labelSuffix: '(11+18+33+40)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
    ],
  },
];

const Part2 = ({ tableState, setTableState, control }) => (
  <CashFlowTable
    data={data}
    headers={headers}
    tableState={tableState}
    setTableState={setTableState}
    control={control}
  />
);

export default memo(Part2);
