import React, { Fragment, type ElementConfig, useState } from 'react';
import { defaultTo, isNil, isEmpty, pathOr } from 'ramda';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import Select, { components } from 'react-select';
import cn from 'classnames';
import Styled from 'styled-components';
import {
  Checkbox,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  InputGroup,
  MenuItem,
  OverlayTrigger,
  Radio,
  Row,
  SplitButton,
  Tooltip,
} from 'react-bootstrap';
import Datetime from 'react-datetime';
import moment from 'moment';

import { Field } from 'redux-form';
import messages from '../containers/Home/messages';
import { actions as toastrActions } from 'react-redux-toastr';
import PencilButton from '../components/Common/PencilButton';

import {
  Input,
  Typography,
  TimePicker,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { MoneyIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';

import {
  MenuButton,
  MultiSelect,
} from '@fortress-technology-solutions/fortress-component-library/Molecules';

import { DatePicker } from '@fortress-technology-solutions/fortress-component-library/Organisms';

export const YES_VALUE = 'yes';
const YES_TEXT = 'Yes';
export const NO_VALUE = 'no';
const NO_TEXT = 'No';
export const yesNoBooleanOptions = [
  { value: YES_VALUE, text: YES_TEXT, disabled: false },
  { value: NO_VALUE, text: NO_TEXT, disabled: false },
];
export const noYesBooleanOptions = [
  { value: NO_VALUE, text: NO_TEXT, disabled: false },
  { value: YES_VALUE, text: YES_TEXT, disabled: false },
];

const isError = (touched: boolean, error: string) => {
  return touched && error;
};

const isWarning = (touched: boolean, warning: string) => {
  return touched && warning;
};

export function sanitizeNumberInput(inputString: string) {
  const sanitizedString = inputString.replace(/[^0-9.]/g, '');

  const parts = sanitizedString.split('.');
  let integerPart = parts[0] || '0';
  let decimalPart = parts[1] || '';

  integerPart = integerPart.replace(/^0+/, '');

  if (integerPart.length > 7) {
    integerPart = integerPart.slice(0, 7);
  }

  if (decimalPart.length > 2) {
    decimalPart = decimalPart.slice(0, 2);
  }

  const sanitizedResult =
    decimalPart.length > 0
      ? integerPart + '.' + decimalPart
      : integerPart + (sanitizedString.endsWith('.') ? '.' : '');

  return sanitizedResult;
}

const renderTooltip = (
  type: 'error' | 'warning' | null,
  message: string,
  id?: string,
) => {
  const StyledTooltip = Styled(Tooltip)`
  &.error {
    .tooltip-inner {
      background-color: #B10303;
      color: white
    }
    .tooltip-arrow {
      border-top-color: #B10303;
    }
  }
  &.warning {
    .tooltip-inner {
      background-color: #5D25B2;
      color: white
    }
    .tooltip-arrow {
      border-top-color: #5D25B2;
    }
  }
  `;
  return (
    <StyledTooltip id={id || 'tooltip'} className={type}>
      {message}
    </StyledTooltip>
  );
};

const LabelForCheckbox = Styled.label`
  margin-top: 10px;
`;

export const extractCurrentDateFormat = (intl: Object) => {
  const dateToFormat = new Date(2017, 11, 22);
  const formattedDate = intl.formatDate(dateToFormat, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
  return formattedDate
    .replace('2017', 'YYYY')
    .replace('12', 'MM')
    .replace('22', 'DD');
};

export const getValidationState = (
  touched: boolean,
  error: string,
  warning: string,
) => {
  return isError(touched, error)
    ? 'error'
    : isWarning(touched, warning)
    ? 'warning'
    : null;
};

export const socialSecurityNumberParser = (number: string): string => {
  const numberToParse = defaultTo('', number);
  return numberToParse.replace(/[- ]+/g, '').slice(0, 9);
};

export const socialSecurityNumberFormatter = (number: string): string => {
  let numberToFormat = defaultTo('', number);
  numberToFormat = numberToFormat.substring(0, 9);
  const first = numberToFormat.substring(0, 3);
  const second = numberToFormat.substring(3, 5);
  const third = numberToFormat.substring(5);
  const maskedFormat = `${first}-${second}-${third}`;
  if (maskedFormat === '--') {
    return '';
  }
  return maskedFormat;
};

export const ssnMasker = (number: string): string => {
  let numberToFormat = defaultTo('', number);
  numberToFormat = numberToFormat.substring(0, 9);
  const third = numberToFormat.substring(5);
  const maskedFormat = `***-***-${third}`;
  if (maskedFormat === '***-***-') {
    return '';
  }
  return maskedFormat;
};

export const feidNumberFormatter = (number: string): string => {
  let numberToFormat = defaultTo('', number);
  numberToFormat = numberToFormat.substring(0, 9);
  const first = numberToFormat.substring(0, 2);
  const second = numberToFormat.substring(2, 9);
  return `${first}-${second}`;
};

type ReadOnlyFieldProps = {
  label: string,
  value: string,
};

export const ReadOnlyField = ({ label, value }: ReadOnlyFieldProps) => (
  <React.Fragment>
    <div>
      <label>{label}</label>
    </div>
    <div className="form-group">{value}</div>
  </React.Fragment>
);

export const renderCLDatePickerField = ({ input, meta, ...custom }) => {
  return (
    <DatePicker
      helperText={
        <Typography variant="helper" color="error">
          {meta.error}
        </Typography>
      }
      {...custom}
      {...input}
      value={input.value === '' ? null : input.value}
      error={meta.dirty && meta.invalid}
    />
  );
};

export const renderCLTimePickerField = ({ input, meta, ...custom }) => {
  return (
    <TimePicker
      helperText={meta.error}
      {...custom}
      {...input}
      value={input.value === '' ? null : input.value}
      error={meta.dirty && meta.invalid}
    />
  );
};

export const renderCLCurrencyField = ({ input, meta, ...custom }) => {
  return (
    <Input
      placeholder="0.00"
      helperText={
        <Typography variant="helper" color="error">
          {meta.error}
        </Typography>
      }
      startAdornment={<MoneyIcon />}
      {...custom}
      {...input}
      onChange={(e) => {
        const newValue = sanitizeNumberInput(e.target.value);
        input.onChange(newValue);
      }}
      error={meta.dirty && meta.invalid}
    />
  );
};

export const renderTextField = ({
  classes,
  input,
  label,
  meta: { touched, error, warning },
  placeholder,
  showFloatLabel,
  showLabel,
  store,
  storeSubscription,
  messageAsTooltip = false,
  ...custom
}: Object) => {
  const { placement } = custom;

  const tooltip = messageAsTooltip
    ? renderTooltip(
        getValidationState(touched, error, warning),
        error || warning,
        `${input.name || 'default'}-tooltip`,
      )
    : null;

  const formGroup = (
    <FormGroup validationState={getValidationState(touched, error, warning)}>
      {showFloatLabel && (
        <ControlLabel className="has-float-label">
          <FormControl
            type="text"
            placeholder={placeholder}
            {...input}
            {...custom}
          />
          <span>{placeholder}</span>
        </ControlLabel>
      )}
      {showLabel && <label className={classes}>{label}</label>}
      {!showFloatLabel && (
        <FormControl
          type="text"
          placeholder={placeholder}
          {...input}
          {...custom}
        />
      )}
      {touched && (error || warning) && !messageAsTooltip && (
        <HelpBlock>{error || warning}</HelpBlock>
      )}
    </FormGroup>
  );

  return messageAsTooltip && touched && (!isNil(error) || !isNil(warning)) ? (
    <OverlayTrigger
      key={input.name || 'tooltip'}
      placement={isNil(placement) ? 'top' : placement}
      overlay={tooltip}
    >
      {formGroup}
    </OverlayTrigger>
  ) : (
    formGroup
  );
};

// change
type SearchDropDownProps = {
  name: string,
  show: boolean,
  focus: Function,
  change: Function,
  items: Array<Object>,
  formatMessage: Function,
  changeCallback: Function,
  setSelection: Function,
  refSelector: any,
  classNames: string,
  label: string,
  placeholder: string,
  itemPrefix: string,
};

export const SearchDropDown = ({
  name,
  show,
  focus,
  change,
  items,
  formatMessage,
  changeCallback,
  setSelection,
  refSelector,
  classNames,
  label,
  placeholder,
  itemPrefix,
}: SearchDropDownProps) => {
  const itemList = items.map((item) => {
    const key = itemPrefix ? `${itemPrefix}Id` : 'id';
    const description = itemPrefix ? `${itemPrefix}Description` : 'description';
    return (
      <li onClick={() => setSelection(item)} key={item[`${key}`]}>
        {item[`${description}`]}
      </li>
    );
  });

  return (
    <FormGroup>
      <div className={classNames}>
        {label ? <label>{label}</label> : null}
        <div className="search-input search-input-active">
          <div className="form">
            <Field
              className="input-lg"
              component="input"
              type="search"
              name={name}
              onFocus={focus}
              onChange={(e) => change(e.target.value)}
              placeholder={placeholder}
              autoComplete="off"
            />
            <div className="search-input__autocomplete">
              {show ? <ul ref={refSelector}>{itemList}</ul> : null}
            </div>
            <button type="button">
              <i className="et-search" />
            </button>
          </div>
        </div>
      </div>
    </FormGroup>
  );
};

const SearchIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>,
) => {
  return (
    <components.DropdownIndicator {...props}>
      <i className="et-search" />
    </components.DropdownIndicator>
  );
};

export const renderSearchDropDown = ({
  options,
  classNames,
  label,
  placeholder,
  bsSize,
  classes,
  meta: { touched, error, warning },
  onChangeCallback,
  fieldValue,
  ...custom
}: Object) => {
  const filterOptions = (candidate, input) => {
    const keywords = pathOr('', ['value', 'keywords'], candidate).toLowerCase();
    if (input && keywords) {
      return (
        keywords.match(new RegExp(input, 'i')) ||
        candidate.label.match(new RegExp(input, 'i'))
      );
    } else if (input) {
      return candidate.label.match(new RegExp(input, 'i'));
    }
    return true;
  };
  return (
    <div className={classNames}>
      <FormGroup validationState={getValidationState(touched, error, warning)}>
        {label && <ControlLabel className={classes}>{label}</ControlLabel>}
        <div className="search-input search-input-active">
          <Select
            classNamePrefix="react-select"
            components={{ SearchIndicator }}
            options={options}
            placeholder={placeholder}
            onChange={onChangeCallback}
            value={fieldValue}
            filterOption={filterOptions}
            isSearchable
            {...custom}
          />
          {touched && <HelpBlock>{error || warning}</HelpBlock>}
        </div>
      </FormGroup>
    </div>
  );
};

export const renderTextFieldTwoColumns = ({
  classes,
  col1: [md1, xs1],
  col2: [md2, xs2],
  input,
  label,
  meta: { touched, error, warning },
  placeholder,
  showLabel,
  rowClasses,
  ...custom
}: Object) => (
  <Row className={rowClasses}>
    <Col md={md1} xs={xs1}>
      <label className={classes}>{label}</label>
    </Col>
    <Col md={md2} xs={xs2}>
      <FormGroup validationState={getValidationState(touched, error, warning)}>
        {
          <FormControl
            type="text"
            placeholder={placeholder}
            {...input}
            {...custom}
          />
        }
        {touched && <HelpBlock>{error || warning}</HelpBlock>}
      </FormGroup>
    </Col>
  </Row>
);

export const renderBlockDataTextField = ({
  input,
  label,
  meta: { touched, error, warning },
  ...custom
}: Object) => (
  <div className="block-data block-white-shadow">
    <h5>{label}</h5>
    {custom.readOnly ? (
      <p>{input.value}</p>
    ) : (
      <FormGroup
        style={{ marginBottom: 0 }}
        validationState={getValidationState(touched, error, warning)}
      >
        <input {...input} {...custom} className="input-lg form-control" />
        {touched && <HelpBlock>{error || warning}</HelpBlock>}
      </FormGroup>
    )}
  </div>
);

export const renderTextAreaTwoColumns = ({
  classes,
  col1: [md1, xs1],
  col2: [md2, xs2],
  input,
  label,
  meta: { touched, error, warning },
  placeholder,
  pencilCol,
  showLabel,
  showPencil,
  onPencilClick,
  pencilScope = [],
  ...custom
}: Object) => {
  const [pencilMd, pencilXs] = pencilCol || [1, 1];
  return (
    <Row>
      <Col md={md1} xs={xs1}>
        <label className={classes}>{label}</label>
      </Col>
      {showPencil && (
        <Col md={pencilMd} xs={pencilXs}>
          <PencilButton scope={pencilScope} onClick={onPencilClick} />
        </Col>
      )}
      <Col md={md2} xs={xs2}>
        <FormGroup
          validationState={getValidationState(touched, error, warning)}
        >
          {
            <FormControl
              componentClass="textarea"
              placeholder={placeholder}
              {...input}
              {...custom}
            />
          }
          {touched && <HelpBlock>{error || warning}</HelpBlock>}
        </FormGroup>
      </Col>
    </Row>
  );
};

export const renderTextAreaField = ({
  classes,
  input,
  label,
  meta: { touched, error, warning },
  placeholder,
  showFloatLabel,
  showLabel,
  store,
  storeSubscription,
  ...custom
}: Object) => (
  <FormGroup validationState={getValidationState(touched, error, warning)}>
    {showFloatLabel && (
      <ControlLabel className="has-float-label">
        <FormControl
          componentClass="textarea"
          placeholder={placeholder}
          {...input}
          {...custom}
        />
        <span>{placeholder}</span>
      </ControlLabel>
    )}
    {showLabel && <label className={classes}>{label}</label>}
    {!showFloatLabel && (
      <FormControl
        componentClass="textarea"
        placeholder={placeholder}
        {...input}
        {...custom}
      />
    )}
    <HelpBlock>{touched && (error || warning)}</HelpBlock>
  </FormGroup>
);

export const renderEmailField = ({
  classes,
  input,
  label,
  meta: { touched, error, warning },
  placeholder,
  showLabel,
  ...custom
}: Object) => (
  <FormGroup validationState={getValidationState(touched, error, warning)}>
    {showLabel && <label className={classes}>{label}</label>}
    <FormControl
      type="email"
      placeholder={placeholder}
      {...input}
      {...custom}
    />
    {touched && <HelpBlock>{error || warning}</HelpBlock>}
  </FormGroup>
);

export const renderPhoneField = ({
  classes,
  input,
  label,
  meta: { touched, error, warning },
  placeholder,
  showLabel,
  ...custom
}: Object) => (
  <FormGroup validationState={getValidationState(touched, error, warning)}>
    {showLabel && <label className={classes}>{label}</label>}
    <FormControl type="tel" placeholder={placeholder} {...input} {...custom} />
    {touched && <HelpBlock>{error || warning}</HelpBlock>}
  </FormGroup>
);

export const renderPhoneFieldWithPermanentWarn = ({
  input,
  placeholder,
  meta: { touched, error, warning },
  ...custom
}: Object) => (
  <FormGroup validationState={getValidationState(touched, error, warning)}>
    <FormControl type="tel" placeholder={placeholder} {...input} {...custom} />
    <HelpBlock className="has-error">{error || warning}</HelpBlock>
  </FormGroup>
);

export const phoneParser = (number: string): string => {
  const numberToParse = defaultTo('', number);
  return numberToParse.replace(/[-() ]+/g, '').slice(0, 10);
};

export const phoneFormatter = (number: string): string => {
  if (!number) {
    return '';
  }
  const formattedNumber = number.replace(/-/g, '');
  let numberToParse = defaultTo('', formattedNumber);
  numberToParse = numberToParse.substring(0, 10);
  const first = numberToParse.substring(0, 3);
  const second = numberToParse.substring(3, 6);
  const third = numberToParse.substring(6);
  const maskedFormat = '(' + first + ') ' + second + '-' + third;
  if (maskedFormat === '() -') {
    return '';
  }
  return maskedFormat;
};

export const renderNumberField = ({
  input,
  label,
  meta: { touched, error, warning },
  placeholder,
  messageAsTooltip = false,
  fullWidth,
  showLabel,
  ...custom
}: Object) => {
  const { placement } = custom;

  const tooltip = messageAsTooltip
    ? renderTooltip(
        getValidationState(touched, error, warning),
        error || warning,
        `${input.name || 'default'}-tooltip`,
      )
    : null;

  const formGroup = (
    <FormGroup validationState={getValidationState(touched, error, warning)}>
      {showLabel && (
        <ControlLabel
          style={fullWidth ? { width: '100%' } : {}}
          className="input"
        >
          {label}
          <FormControl
            type="number"
            placeholder={placeholder}
            {...input}
            {...custom}
          />
        </ControlLabel>
      )}
      {!showLabel && (
        <ControlLabel className="has-float-label">
          <FormControl
            type="number"
            placeholder={placeholder}
            {...input}
            {...custom}
          />
          <span>{placeholder}</span>
        </ControlLabel>
      )}
      {touched && !messageAsTooltip && (
        <HelpBlock>{error || warning}</HelpBlock>
      )}
    </FormGroup>
  );

  return messageAsTooltip && touched && (!isNil(error) || !isNil(warning)) ? (
    <OverlayTrigger
      key={input.name || 'tooltip'}
      placement={isNil(placement) ? 'top' : placement}
      overlay={tooltip}
    >
      {formGroup}
    </OverlayTrigger>
  ) : (
    formGroup
  );
};

export const renderFlexNumberField = ({
  input,
  label,
  meta: { touched, error, warning },
  placeholder,
  showLabel,
  classes,
  ...custom
}: Object) => (
  <FormGroup validationState={getValidationState(touched, error, warning)}>
    {label && <ControlLabel className={classes}>{label}</ControlLabel>}
    <FormControl
      type="number"
      placeholder={placeholder}
      {...input}
      {...custom}
    />
    {touched && <HelpBlock>{error || warning}</HelpBlock>}
  </FormGroup>
);

const makeNumberFieldWithAddon =
  (addon: string) =>
  ({
    input,
    label,
    classes,
    messageAsTooltip = false,
    meta: { touched, error, warning },
    ...custom
  }: Object) => {
    const { placement } = custom;

    const tooltip = messageAsTooltip
      ? renderTooltip(
          getValidationState(touched, error, warning),
          error || warning,
          `${input.name || 'default'}-tooltip`,
        )
      : null;

    const formGroup = (
      <FormGroup validationState={getValidationState(touched, error, warning)}>
        {label && <ControlLabel className={classes}>{label}</ControlLabel>}
        <InputGroup>
          <InputGroup.Addon>{addon}</InputGroup.Addon>
          <FormControl type="number" {...input} {...custom} />
        </InputGroup>
        {touched && (error || warning) && !messageAsTooltip && (
          <HelpBlock>{error || warning}</HelpBlock>
        )}
      </FormGroup>
    );

    return messageAsTooltip && touched && (!isNil(error) || !isNil(warning)) ? (
      <OverlayTrigger
        key={input.name || 'tooltip'}
        placement={isNil(placement) ? 'top' : placement}
        overlay={tooltip}
      >
        {formGroup}
      </OverlayTrigger>
    ) : (
      formGroup
    );
  };

export const renderPercentageField = makeNumberFieldWithAddon('%');
export const renderCurrencyField = makeNumberFieldWithAddon('$');

const numberFormatStyle = { value: 'currency' };

export const renderBlockDataCurrencyField = ({
  input,
  label,
  meta: { touched, error, warning },
  ...custom
}: Object) => (
  <div className="block-data block-white-shadow">
    {label && <h5>{label}</h5>}
    {custom.readOnly ? (
      <p>
        <FormattedNumber
          value={input.value}
          style={numberFormatStyle.value}
          currency="USD"
        />
      </p>
    ) : (
      <FormGroup
        style={{ marginBottom: 0 }}
        validationState={getValidationState(touched, error, warning)}
      >
        <div className="input-group">
          <div className="input-group-addon">$</div>
          <input
            className="input-lg form-control"
            step="0.01"
            {...input}
            {...custom}
            type="number"
          />
        </div>
        {touched && <HelpBlock>{error || warning}</HelpBlock>}
      </FormGroup>
    )}
  </div>
);

export const renderPasswordField = ({
  classes,
  input,
  label,
  meta: { touched, error, warning },
  placeholder,
  ...custom
}: Object) => (
  <FormGroup validationState={getValidationState(touched, error, warning)}>
    <label className={classes}>{label}</label>
    <FormControl
      type="password"
      placeholder={placeholder}
      {...input}
      {...custom}
    />
    {touched && <HelpBlock>{error || warning}</HelpBlock>}
  </FormGroup>
);

export const renderBlockDataSelectField = ({
  input,
  label,
  meta: { touched, error, warning },
  options,
  ...custom
}: Object) => (
  <div className="block-data block-white-shadow">
    <h5>{label}</h5>
    <FormGroup
      style={{ marginBottom: 0 }}
      validationState={getValidationState(touched, error, warning)}
    >
      <select className="input-lg form-control" {...input} {...custom}>
        {options.map((opt) => (
          <option
            value={opt.value}
            key={opt.value || 'undefinedValue'}
            disabled={opt.disabled}
          >
            {opt.text}
          </option>
        ))}
      </select>
      {touched && <HelpBlock>{error || warning}</HelpBlock>}
    </FormGroup>
  </div>
);

export const renderSelectField = ({
  classes,
  className,
  input,
  label,
  meta: { touched, error, warning },
  options,
  selectClassNames,
  store,
  storeSubscription,
  messageAsTooltip,
  ...custom
}: Object) => {
  const { placement } = custom;
  const tooltip = messageAsTooltip
    ? renderTooltip(
        getValidationState(touched, error, warning),
        error || warning,
        `${input.name || 'default'}-tooltip`,
      )
    : null;
  const selectOptions = options?.map((option) => (
    <option
      key={option.value || 'undefinedValue'}
      value={option.value}
      disabled={option.disabled ? option.disabled : false}
    >
      {option.text}
    </option>
  ));
  const formGroup = (
    <FormGroup
      className={className}
      validationState={getValidationState(touched, error, warning)}
    >
      {label && <ControlLabel className={classes}>{label}</ControlLabel>}
      <FormControl
        data-default-value-selected={input.value !== 'default'}
        componentClass="select"
        className={selectClassNames}
        {...input}
        {...custom}
      >
        {selectOptions}
      </FormControl>
      {touched && (error || warning) && !messageAsTooltip && (
        <HelpBlock>{error || warning}</HelpBlock>
      )}
    </FormGroup>
  );
  return messageAsTooltip && touched && (!isNil(error) || !isNil(warning)) ? (
    <OverlayTrigger
      key={input.name || 'tooltip'}
      placement={isNil(placement) ? 'top' : placement}
      overlay={tooltip}
    >
      {formGroup}
    </OverlayTrigger>
  ) : (
    formGroup
  );
};

export const RenderFortressSelectField = ({
  className,
  classes,
  name,
  input,
  label,
  meta: { touched, error, warning },
  options,
  store,
}: Object) => {
  const [value, setValue] = useState();

  return (
    <FormGroup
      className={className}
      validationState={getValidationState(touched, error, warning)}
    >
      {label && <ControlLabel className={classes}>{label}</ControlLabel>}
      <MenuButton
        variant="primary"
        id={name}
        ButtonProps={{
          fullWidth: true,
          sx: { display: 'flex', justifyContent: 'space-between' },
        }}
        placeholder="Complete Activity"
        options={options}
        value={value}
        onMenuItemClick={(eventKey) => {
          input.onChange(eventKey);
          setValue(eventKey);
        }}
      />
      {touched && (error || warning) && (
        <HelpBlock>{error || warning}</HelpBlock>
      )}
    </FormGroup>
  );
};

export const renderSelectFieldTwoColumns = ({
  classes,
  className,
  rowClasses,
  col1: [md1, xs1],
  col2: [md2, xs2],
  input,
  label,
  meta: { touched, error, warning },
  options,
  selectClassNames,
  store,
  storeSubscription,
  ...custom
}: Object) => {
  const selectOptions = options.map((option) => (
    <option
      key={option.value || 'undefinedValue'}
      value={option.value}
      disabled={option.disabled ? option.disabled : false}
    >
      {option.text}
    </option>
  ));
  return (
    <Row className={rowClasses}>
      <Col md={md1} xs={xs1}>
        {label && <ControlLabel className={classes}>{label}</ControlLabel>}
      </Col>
      <Col md={md2} xs={xs2}>
        <FormGroup
          className={className}
          validationState={getValidationState(touched, error, warning)}
        >
          <FormControl
            data-default-value-selected={input.value !== 'default'}
            componentClass="select"
            className={selectClassNames}
            {...input}
            {...custom}
          >
            {selectOptions}
          </FormControl>
          {touched && <HelpBlock>{error || warning}</HelpBlock>}
        </FormGroup>
      </Col>
    </Row>
  );
};

export const renderMultiSelectFieldFortress = ({
  options,
  onChangeCallback,
  controlledValue,
  meta: { touched, error, warning },
  className,
  name,
  placeholder,
  selectAllOption = true,
  excludeFromSelectAll = [],
  label,
  disabled = false,
}) => {
  const helperText = touched ? (error ? error : warning) : '';
  return (
    <FormGroup
      className={className}
      validationState={getValidationState(touched, error, warning)}
    >
      {label && <ControlLabel>{label}</ControlLabel>}
      <MultiSelect
        name={name}
        placeholder={placeholder}
        selectAllOption={selectAllOption}
        options={options}
        value={controlledValue || []}
        onChange={onChangeCallback}
        error={Boolean(error)}
        helperText={helperText}
        checkmarks
        excludeFromSelectAll={excludeFromSelectAll}
        disabled={disabled}
      />
    </FormGroup>
  );
};

export const renderMultiSelectField = ({
  disabled,
  isDisabled,
  options,
  label,
  defaultValue,
  onChangeCallback,
  controlledValue,
  meta: { touched, error, warning },
  className,
  messageAsTooltip,
  input,
  classes,
  selectClassNames,
  refCallback = null,
  onFocusCallback = null,
  ...custom
}: Object) => {
  const { placement } = custom;
  const tooltip = messageAsTooltip
    ? renderTooltip(
        getValidationState(touched, error, warning),
        error || warning,
        `${input.name || 'default'}-tooltip`,
      )
    : null;
  const formGroup = (
    <FormGroup
      className={className}
      validationState={getValidationState(touched, error, warning)}
    >
      {label && <ControlLabel className={classes}>{label}</ControlLabel>}
      <Select
        classNamePrefix="react-multi-select"
        defaultValue={defaultValue}
        options={options}
        isMulti
        isDisabled={disabled || isDisabled}
        closeMenuOnSelect={false}
        onChange={onChangeCallback}
        value={controlledValue || undefined}
        ref={refCallback}
        onFocus={onFocusCallback}
        {...custom}
      />
      {touched && (error || warning) && !messageAsTooltip && (
        <HelpBlock>{error || warning}</HelpBlock>
      )}
    </FormGroup>
  );
  return messageAsTooltip && touched && (!isNil(error) || !isNil(warning)) ? (
    <OverlayTrigger
      key={input.name || 'tooltip'}
      placement={isNil(placement) ? 'top' : placement}
      overlay={tooltip}
    >
      {formGroup}
    </OverlayTrigger>
  ) : (
    formGroup
  );
};

export const renderSplitButtonDropdownField = ({
  className,
  input,
  label,
  meta: { touched, error, warning },
  options,
  store,
  storeSubscription,
  ...custom
}: Object) => {
  const selectOptions = options.map((option) => (
    <MenuItem
      key={option.value || 'undefinedValue'}
      eventKey={option.value}
      disabled={option.disabled ? option.disabled : false}
      onSelect={(eventKey) => input.onChange(eventKey)}
    >
      {option.text}
    </MenuItem>
  ));
  const getTitle = () => {
    const option = options.find((f) => f.value === input.value);
    return option ? option.text : 'Complete activity';
  };
  return (
    <FormGroup
      className={className}
      validationState={getValidationState(touched, error, warning)}
    >
      <SplitButton bsStyle="primary" title={getTitle()} id="split-button-basic">
        {selectOptions}
      </SplitButton>
    </FormGroup>
  );
};

export const renderCheckboxSwitch = ({
  disabled,
  input,
  meta: { touched, error, warning },
  store,
  storeSubscription,
  displayInline,
  ...custom
}: Object) => (
  <Fragment>
    <div
      className={cn(
        `btn-switch${displayInline ? ' disp-inline' : ' float-right'}`,
        {
          disabled: disabled,
        },
      )}
    >
      <input
        id={input.name}
        className="btn-switch-checkbox"
        type="checkbox"
        checked={input.value}
        {...input}
        {...custom}
        disabled={disabled}
      />
      <label htmlFor={input.name} className="btn-switch-label">
        <span className="btn-switch-inner" />
        <span className="btn-switch-switch" />
      </label>
    </div>
    {touched && <HelpBlock className="text-red">{error || warning}</HelpBlock>}
  </Fragment>
);

export const renderCheckboxField = ({
  disabled,
  input,
  key,
  label,
  store,
  storeSubscription,
  meta: { touched, error, warning },
  boldLabel,
  ...custom
}: Object) => {
  return (
    <FormGroup
      key={key}
      validationState={getValidationState(touched, error, warning)}
    >
      <Checkbox
        checked={input.value}
        className="custom-checkbox padleft20"
        {...input}
        {...custom}
        disabled={disabled}
      >
        <input type="checkbox" />
        <span className="custom-check-square center-block" />
        {boldLabel ? <strong>{label}</strong> : label}
      </Checkbox>
      {touched && <HelpBlock>{error || warning}</HelpBlock>}
    </FormGroup>
  );
};

export const renderCheckboxFieldTwoColumns = ({
  disabled,
  col1: [md1, xs1],
  col2: [md2, xs2],
  input,
  key,
  label,
  store,
  storeSubscription,
  meta: { touched, error, warning },
  boldLabel,
  ...custom
}: Object) => {
  return (
    <Row>
      <Col md={md1} xs={xs1}>
        <LabelForCheckbox>{label}</LabelForCheckbox>
      </Col>
      <Col md={md2} xs={xs2}>
        <FormGroup
          key={key}
          validationState={getValidationState(touched, error, warning)}
        >
          <Checkbox
            checked={input.value}
            className="custom-checkbox padleft20"
            {...input}
            {...custom}
            disabled={disabled}
          >
            <input type="checkbox" />
            <span className="custom-check-square center-block" />
          </Checkbox>
          {touched && <HelpBlock>{error || warning}</HelpBlock>}
        </FormGroup>
      </Col>
    </Row>
  );
};

export const renderBlockDataCheckboxField = ({
  input,
  label,
  meta: { touched, error, warning },
  ...custom
}: Object) => {
  return (
    <div className="block-data block-white-shadow">
      <h5>{label}</h5>
      <FormGroup validationState={getValidationState(touched, error, warning)}>
        <Checkbox
          checked={input.value}
          className="custom-checkbox padleft20"
          {...input}
          {...custom}
          disabled={input.disabled || custom.readOnly}
        >
          <input type="checkbox" />
          <span className="custom-check-square" />
        </Checkbox>
        {touched && <HelpBlock>{error || warning}</HelpBlock>}
      </FormGroup>
    </div>
  );
};

export const renderSimpleCheckboxField = ({
  disabled,
  input,
  label,
  store,
  storeSubscription,
  ...custom
}: Object) => {
  return (
    <Checkbox
      checked={input.value}
      className="custom-checkbox padleft30 flying-checkbox"
      {...input}
      {...custom}
      disabled={disabled}
    >
      <input type="checkbox" />
      <span className="custom-check-square" />
      {label}
    </Checkbox>
  );
};

export const renderDatePickerInput = (props, openCalendar) => {
  const buttonClick = props.disabled ? undefined : openCalendar;
  return (
    <InputGroup>
      <FormControl type="text" {...props} />
      <span onClick={buttonClick} className="input-group-addon">
        <i className="et-icons16 et-calendar" />
      </span>
    </InputGroup>
  );
};

const renderDateInput = (props) => {
  return (
    <FormControl
      type="text"
      {...props}
      onClick={undefined}
      onFocus={undefined}
    />
  );
};

export const renderDateField = ({
  bsSize = 'sm',
  classes = 'input nopad',
  disabled,
  hidePicker = false,
  input,
  inputOnly = false,
  label,
  messageAsTooltip = false,
  meta: { touched, error, warning },
  placeholder = '',
  noticeToVacate,
  lease,
  ...custom
}: Object) => {
  const { value, isControlled, classPicker, placement } = custom;

  const className = `input-${bsSize
    .replace('small', 'sm')
    .replace('large', 'lg')}`;
  const renderFunction = inputOnly ? renderDateInput : renderDatePickerInput;

  const formGroup = (
    <FormGroup validationState={getValidationState(touched, error, warning)}>
      {label && <ControlLabel className={classes}>{label}</ControlLabel>}
      {hidePicker !== true ? (
        <React.Fragment>
          {isControlled ? (
            <Datetime
              value={value}
              timeFormat={false}
              inputProps={{ className, disabled, placeholder }}
              renderInput={renderFunction}
              closeOnSelect={true}
              {...input}
              {...custom}
            />
          ) : (
            <Datetime
              timeFormat={false}
              inputProps={{ className, disabled, placeholder }}
              renderInput={renderFunction}
              className={classPicker}
              {...input}
              {...custom}
            />
          )}
          {touched && !messageAsTooltip && (
            <HelpBlock>{error || warning}</HelpBlock>
          )}
        </React.Fragment>
      ) : null}
    </FormGroup>
  );

  const tooltip = messageAsTooltip
    ? renderTooltip(
        getValidationState(touched, error, warning),
        error || warning,
        `${input.name || 'default'}-tooltip`,
      )
    : null;

  return messageAsTooltip && touched && (!isNil(error) || !isNil(warning)) ? (
    <OverlayTrigger
      key={input.name || 'tooltip'}
      placement={isNil(placement) ? 'top' : placement}
      overlay={tooltip}
    >
      {formGroup}
    </OverlayTrigger>
  ) : (
    formGroup
  );
};

const renderBlockDataDatePickerInput = (props, openCalendar) => (
  <div className="input-group">
    <input {...props} className="input-lg form-control" />
    <div className="input-group-addon" onClick={openCalendar}>
      {' '}
      <i className="et-calendar" />
    </div>
  </div>
);

export const renderBlockDataDateField = ({
  input,
  label,
  required,
  meta: { touched, error, warning },
  ...custom
}: Object) => (
  <div className="block-data block-white-shadow">
    <h5 className={required ? 'required' : null}> {label}</h5>
    {custom.readOnly ? (
      <p>{moment(input.value).format('MM/DD/YYYY')}</p>
    ) : (
      <FormGroup
        style={{ marginBottom: 0 }}
        validationState={getValidationState(touched, error, warning)}
      >
        <Datetime
          timeFormat={false}
          renderInput={renderBlockDataDatePickerInput}
          {...input}
          {...custom}
        />
        {touched && <HelpBlock>{error || warning}</HelpBlock>}
      </FormGroup>
    )}
  </div>
);

const renderTimePickerInput = (props, openCalendar) => {
  const buttonClick = props.disabled ? undefined : openCalendar;
  return (
    <InputGroup>
      <FormControl type="text" {...props} />
      <span onClick={buttonClick} className="input-group-addon">
        <i className="et-icons16 et-clock" />
      </span>
    </InputGroup>
  );
};

export const renderTimeField = ({
  input,
  label,
  disabled,
  bsSize = 'sm',
  meta: { touched, error, warning },
  ...custom
}: Object) => {
  const className = `input-${bsSize
    .replace('small', 'sm')
    .replace('large', 'lg')}`;
  return (
    <FormGroup validationState={getValidationState(touched, error, warning)}>
      {label && <ControlLabel className="input nopad">{label}</ControlLabel>}
      <Datetime
        dateFormat={false}
        inputProps={{ className, disabled }}
        renderInput={renderTimePickerInput}
        {...input}
        {...custom}
      />
      <HelpBlock>{error || warning}</HelpBlock>
    </FormGroup>
  );
};

export const renderTranslatedMessage = (message: any) => (
  <FormattedMessage {...message} />
);

export const renderNoDataToastr = () => {
  return toastrActions.add({
    type: 'info',
    message: renderTranslatedMessage(messages.noDataDescription),
    options: {
      showCloseButton: true,
      removeOnHover: true,
    },
  });
};

export const renderInlineTextField = ({
  input,
  store,
  storeSubscription,
  meta: { touched, error, warning },
  ...custom
}: Object) => (
  <FormGroup validationState={getValidationState(touched, error, warning)}>
    <InputGroup bsClass="input-group inline-input">
      <FormControl type="text" {...input} {...custom} />
    </InputGroup>
  </FormGroup>
);

export const renderInlineTextAreaField = ({
  input,
  store,
  storeSubscription,
  meta: { touched, error, warning },
  ...custom
}: Object) => (
  <FormGroup validationState={getValidationState(touched, error, warning)}>
    <InputGroup bsClass="input-group inline-input">
      <FormControl componentClass="textarea" {...input} {...custom} />
    </InputGroup>
  </FormGroup>
);

export const renderFileField = ({
  input,
  classes,
  label,
  browseLabel,
  meta: { touched, error, warning },
  ...custom
}: Object) => (
  <FormGroup validationState={getValidationState(touched, error, warning)}>
    <label className={classes}>{label}</label>
    <FormControl
      type="password"
      placeholder={browseLabel}
      {...input}
      {...custom}
    />
    {touched && <HelpBlock>{error || warning}</HelpBlock>}
  </FormGroup>
);

export const renderRadioButton = ({
  custom,
  input,
  option,
  disabled,
}: Object) => (
  <div className="form-group">
    <input
      type="radio"
      key={option.value || 'undefinedValue'}
      {...input}
      value={option.value}
      {...custom}
      checked={option.value === input.value}
      disabled={disabled}
    />
  </div>
);

export const renderRadioOption = ({
  option,
  inline,
  input,
  custom,
  radioClass,
  disabled,
  title,
}: Object) => {
  return (
    <Radio
      inline
      key={option.value || 'undefinedValue'}
      {...input}
      value={option.value}
      {...custom}
      className={radioClass || ''}
      checked={option.value === input.value}
      disabled={disabled}
      title={title}
    >
      {option.text}
    </Radio>
  );
};

export const renderRadioField = ({
  option,
  inline,
  className,
  label,
  input,
  custom,
  meta: { touched, error, warning },
}: Object) => (
  <FormGroup
    className={className}
    validationState={getValidationState(touched, error, warning)}
  >
    {label && <ControlLabel className="input">{label}</ControlLabel>}
    {renderRadioOption({ option, inline, input, custom })}
    {touched && <HelpBlock>{error || warning}</HelpBlock>}
  </FormGroup>
);

export const renderRadioGroupField = ({
  input,
  label,
  labelClass,
  radioClass,
  meta: { touched, error, warning },
  options,
  className,
  fieldName,
  inline,
  disabled,
  ...custom
}: Object) => {
  const radioOptions = options.map((option) =>
    renderRadioOption({ option, inline, input, custom, radioClass, disabled }),
  );
  return (
    <FormGroup
      className={className}
      validationState={getValidationState(touched, error, warning)}
    >
      {label && (
        <ControlLabel className={`input ${labelClass}`}>{label}</ControlLabel>
      )}
      {radioOptions}
      {touched && (error || warning) && (
        <HelpBlock>{error || warning}</HelpBlock>
      )}
    </FormGroup>
  );
};

export const extractDateFormat = (intl: Object) => {
  const dateToFormat = new Date(2017, 11, 22);
  const formattedDate = intl.formatDate(dateToFormat, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
  return formattedDate
    .replace('2017', 'YYYY')
    .replace('12', 'MM')
    .replace('22', 'DD');
};

export const normalizePhone = (phoneNumber: string) => {
  if (!phoneNumber) {
    return phoneNumber;
  }

  const phone = phoneNumber.replace(/[^\d]/g, '');
  if (phone.length <= 3) {
    return `(${phone}`;
  }
  if (phone.length <= 6) {
    return `(${phone.slice(0, 3)}) ${phone.slice(3)}`;
  }
  return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
};

export const formatPeriod = (period: string) => {
  const year = period.slice(0, 4);
  const month = period.slice(4);
  return `${year}-${month}`;
};

export const required = (val: any) =>
  isNil(val) || isEmpty(val) ? 'This field is required.' : undefined;
export const requiredString = (val?: string) =>
  isNil(val) || val.trim().length === 0 ? 'This field is required.' : undefined;
export const requiredSelect = (val: any) =>
  val === 'default' ? 'This field is required.' : undefined;
export const positive = (val: number) =>
  val > 0 ? undefined : 'This field must be positive.';
export const greaterOrEqual = (val: number) =>
  val >= 0 ? undefined : 'This field must be greater than or equal to zero';
export const positiveOrZero = (val: number) =>
  val >= 0 ? undefined : 'This field must be 0 or positive.';
export const min = (min: number) => (val: number) =>
  val >= min ? undefined : `The minimum value for this field is ${min}`;
export const number = (value: any) =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined;
export const maxlength =
  (
    max: number,
    err?: string = `The field must be less than ${max + 1} characters.`,
  ) =>
  (value?: string) =>
    value && value.length > max ? err : undefined;
export const minlength =
  (
    min: number,
    err?: string = `The field must be more than ${min} characters.`,
  ) =>
  (value?: string) =>
    value && value.length < min ? err : undefined;
export const notZero = (val: string) =>
  Number(val) === 0 ? 'The field cannot be zero.' : undefined;

export const validateAddress = (address: string) => {
  if (typeof address !== 'string') return 'This field is required.';

  return address.trim().length === 0 ? 'Must be a valid address.' : undefined;
};

export const validateNonRequiredAddress = (address: string) => {
  if (typeof address !== 'string') return undefined;

  return address.length > 0 && address.trim().length === 0
    ? 'Must be a valid address.'
    : undefined;
};

export const nonEmptyString = (str: string) => {
  if (typeof str !== 'string') return undefined;

  return str.length > 0 && str.trim().length === 0
    ? 'Field cannot be spaces only.'
    : undefined;
};

export const validateName = (name: string) =>
  name && /^[a-zA-Z][a-zA-Z0-9 .',&-]*$/i.test(name)
    ? undefined
    : 'Must be a valid name';

export const validateNonRequiredName = (name: string) => {
  if ([null, undefined, ''].includes(name)) {
    return undefined;
  }
  return name && /^[a-zA-Z][a-zA-Z0-9 .',&-]*$/i.test(name)
    ? undefined
    : 'Must be a valid name';
};

export const phoneNumber = (phoneNumber: string) =>
  !(phoneNumber && !/^[2-9]{1}\d{9}$/.test(phoneNumber))
    ? undefined
    : 'Must be a valid phone number';
export const email = (email: string) =>
  !(email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email))
    ? undefined
    : 'Must be a valid email';
export const petTag = (tag: string) =>
  !(tag && !/^[a-zA-Z0-9 .'-]*$/i.test(tag))
    ? undefined
    : 'Must be a valid pet tag';

const DATE_REGEX = /[0-1][0-9]\/[0-3][0-9]\/[0-9]{4}/;

const isDate = (date) => typeof date.getMonth === 'function';

export const validDate = (date: any) => {
  if (!date) return;
  if (typeof date === 'string')
    return DATE_REGEX.test(date) && moment(date).isValid()
      ? undefined
      : 'Invalid date.';

  const momentDate = isDate(date) ? moment(date) : date;

  if (!momentDate.isValid || !momentDate.isValid()) return 'Invalid date.';

  return undefined;
};

export const validateIsInteger = (value: string) => {
  if (!value) return;
  return Number.isInteger(Number(value))
    ? undefined
    : 'Must be a whole number.';
};

export const validateIsPositive = (value: string) => {
  if (!value) return;
  return Number(value) >= 0 ? undefined : 'Must be a positive number.';
};

/**
 * Get default values for a renderMultiSelectField
 * @param  {string} type Name of the redux-form field
 * @param  {Object} initialValues redux-form's initialValues
 * @param  {Array<any>} arr Array of options passed to the renderMultiSelectField
 * @returns Array
 */
export const getMultiSelectDefaultValue = (
  type: string,
  initialValues: Object,
  arr: Array<any>,
): Array<any> => {
  const ids = pathOr([], [type], initialValues);
  return arr.filter((a) => ids.indexOf(a.value) !== -1);
};

export const validateDateIsNotFuture = (
  currentDate: Object,
  selectedDate: Object,
) => {
  const today = moment();
  return today.isSameOrAfter(currentDate, 'day');
};
