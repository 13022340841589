const HUD_SUBSIDY_CODES = {
  SECTION_8: '1',
  RENT_SUPPLEMENT: '2',
  RAP: '3',
  SECTION_236: '4',
  BMIR: '5',
  '811_PRA_DEMO': '6',
  SECTION_202_PRAC: '7',
  SECTION_811_PRAC: '8',
  MARKET_RENT: '0',
  SECTION_202_162: '9',
};

export default HUD_SUBSIDY_CODES;
