import React, { useState, useCallback, memo } from 'react';
import { Box } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { NumericInput } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { columns } from './hooks';

const Percent = () => <Box sx={{ paddingLeft: 1 }}>%</Box>;

const ProposedStateInput = memo(
  ({ stateKey, proposedState, handleProposedChange, rowId }) => {
    const handleChange = useCallback(
      (e) => {
        const value = e?.target?.value;
        handleProposedChange(stateKey, value, rowId);
      },
      [stateKey, handleProposedChange, rowId],
    );

    return (
      <NumericInput
        value={proposedState[`${rowId}-${stateKey}`]}
        onChange={handleChange}
        startAdornment={<Percent />}
        step="0.1"
        HelperTextProps={{
          sx: { display: 'none' },
        }}
        sx={{
          paddingRight: 1,
        }}
      />
    );
  },
);

const useRows = ({ sortedAndFilteredResults }) => {
  const [proposedState, setProposedState] = useState({});

  const handleProposedChange = useCallback((stateKey, value, rowId) => {
    setProposedState((prevState) => ({
      ...prevState,
      [`${rowId}-${stateKey}`]: value,
    }));
  }, []);

  return sortedAndFilteredResults.map((row) => {
    const columnData = Object.keys(columns).reduce((acc, column) => {
      const rowId = row.id;
      const currentColumnValue = row[`current${column}`];
      const totalColumnIncome = row[`total${column}Income`];
      const numUnits = row.numUnits;
      const proposedChange =
        proposedState[`${rowId}-new${column}ProposedChange`] / 100 + 1 || 1;

      const newColumnValue = currentColumnValue * proposedChange || null;
      const newPotentialIncome = newColumnValue * numUnits || null;

      return {
        ...acc,
        [`current${column}`]: {
          value: currentColumnValue,
          variant: 'currency',
        },
        [`total${column}Income`]: {
          value: totalColumnIncome,
          variant: 'currency',
        },
        [`new${column}ProposedChange`]: {
          value: (
            <ProposedStateInput
              stateKey={`new${column}ProposedChange`}
              rowId={rowId}
              proposedState={proposedState}
              handleProposedChange={handleProposedChange}
            />
          ),
        },
        [`new${column}`]: {
          value: newColumnValue,
          variant: 'currency',
        },
        [`new${column}PotentialIncome`]: {
          value: newPotentialIncome,
          variant: 'currency',
        },
      };
    }, {});

    return {
      id: row.id,
      floorPlan: {
        variant: 'link',
        to: row.floorPlanLink,
        value: row.floorPlan,
      },
      bedsBaths: {
        value: row.bedsBaths,
      },
      numUnits: {
        value: row.numUnits,
        variant: 'number',
      },
      ...columnData,
    };
  });
};

export default useRows;
