import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Button,
  SubmitButton,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { SettingsIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import ElementWithPermissions from '../../../components/ElementWithPermissions';

import { useGenerateAffordableWaitlistReport } from './hooks';

import messages from '../messages';

const ManageAffordableWaitlistButtons = ({
  organizationId,
  propertyId,
  disabled,
  openModalConfigurePreferences,
  promptToaster,
}) => {
  const { mutate: generateReport, isLoading: isReportLoading } =
    useGenerateAffordableWaitlistReport({
      organizationId,
      propertyId,
      toasterFn: promptToaster,
    });

  return (
    <>
      <ElementWithPermissions
        scope={['affordable-waitlist-conigure-preferences']}
      >
        <Button // Configure Preferences
          variant={'primarySubtle'}
          startIcon={<SettingsIcon />}
          disabled={disabled}
          onClick={openModalConfigurePreferences}
          sx={{
            height: 'fit-content',
          }}
        >
          <FormattedMessage {...messages.configurePreferences} />
        </Button>
      </ElementWithPermissions>
      <SubmitButton // Generate Report
        variant={'shout'}
        disabled={disabled}
        sx={{
          height: 'fit-content',
        }}
        onClick={generateReport}
        isSubmitting={isReportLoading}
      >
        <FormattedMessage {...messages.generateReport} />
      </SubmitButton>
    </>
  );
};

export default ManageAffordableWaitlistButtons;
