export { AFFORDABLE_PROGRAMS } from '../utils/affordable';

export const HUD_PROGRAM_NAME = 'HUD';
export const HOME_PROGRAM_NAME = 'HOME';
export const CITC_PROGRAM_NAME = 'CITC';
export const RD_PROGRAM_NAME = 'RD';
export const LIHTC_PROGRAM_NAME = 'LIHTC';
export const LIHTC_HOME_PROGRAM_NAME = 'LIHTC/HOME';

export const PROGRAM_NAMES_KEY_MAP = {
  hud: HUD_PROGRAM_NAME,
  home: HOME_PROGRAM_NAME,
  citc: CITC_PROGRAM_NAME,
  rd: RD_PROGRAM_NAME,
  lihtc: LIHTC_PROGRAM_NAME,
  lihtcHome: LIHTC_HOME_PROGRAM_NAME,
};
