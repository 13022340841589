import messages from './messages';
import { StateProgramSetup } from '../StateProgramSetup';

const NOAHSetup = ({
  editMode,
  onChange,
  values,
  promptToaster,
  isLoadingPassbookRateData,
}: Props) => {
  const noahConfig = {
    programName: 'NOAH',
    titleMessage: messages.title,
    showInterimCerts: true,
    showOverrideQualification: true,
    showAnnualRecertMonth: true,
  };

  return (
    <StateProgramSetup
      editMode={editMode}
      onChange={onChange}
      values={values}
      promptToaster={promptToaster}
      isLoadingPassbookRateData={isLoadingPassbookRateData}
      config={noahConfig}
    />
  );
};

export default NOAHSetup;
