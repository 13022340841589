import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.Screening.title',
    defaultMessage: 'Screening',
  },
  submitScreening: {
    id: 'App.Screening.submitScreening',
    defaultMessage: 'Submit Screening',
  },
  finalScreeningStatus: {
    id: 'App.Screening.finalScreeningStatus',
    defaultMessage: 'Final Screening Status',
  },
  reasonForDecision: {
    id: 'App.Screening.reasonForDecision',
    defaultMessage: 'Reason for Decision',
  },
  credit: {
    id: 'App.Screening.credit',
    defaultMessage: 'Credit',
  },
  criminal: {
    id: 'App.Screening.criminal',
    defaultMessage: 'Criminal',
  },
  evictions: {
    id: 'App.Screening.evictions',
    defaultMessage: 'Evictions',
  },
  missingRequiredFields: {
    id: 'App.Screening.missingRequiredFields',
    defaultMessage: 'Missing Required Fields',
  },
  notReady: {
    id: 'App.Screening.notReady',
    defaultMessage: 'Not ready',
  },
  notStarted: {
    id: 'App.Screening.notStarted',
    defaultMessage: 'Not started',
  },
  details: {
    id: 'App.Screening.details',
    defaultMessage: 'Details',
  },
  submitScreeningModalTitle: {
    id: 'App.Screening.submitScreeningModalTitle',
    defaultMessage: 'Application Screening',
  },
  estimatedMonthlyRentAmount: {
    id: 'App.Screening.estimatedMonthlyRentAmount',
    defaultMessage: 'Estimated Monthly Rent Amount',
  },
  cancel: {
    id: 'App.Screening.cancel',
    defaultMessage: 'Cancel',
  },
  confirmAndSubmit: {
    id: 'App.Screening.confirmAndSubmit',
    defaultMessage: 'Confirm & Submit',
  },
  submitScreeningModalInfo: {
    id: 'App.Screening.submitScreeningModalInfo',
    defaultMessage:
      "Screenings will use the monthly rent amount entered above to ensure the household's financial qualifications " +
      "align with the unit's rent. If you change the rent amount, " +
      'submitting a new screening will re-run all screenings ' +
      'based on the updated amount. Once all results are returned, you can make a final decision.',
  },
  screeningSubmitted: {
    id: 'App.Screening.screeningSubmitted',
    defaultMessage: 'Screening Submitted',
  },
  success: {
    id: 'App.Screening.success',
    defaultMessage: 'Success',
  },
  error: {
    id: 'App.Screening.error',
    defaultMessage: 'Error',
  },
  required: {
    id: 'App.Screening.required',
    defaultMessage: 'Required',
  },
  choose: {
    id: 'App.Screening.choose',
    defaultMessage: 'Choose',
  },
  approved: {
    id: 'App.Screening.approved',
    defaultMessage: 'Approved',
  },
  approvedWithConditions: {
    id: 'App.Screening.approvedWithConditions',
    defaultMessage: 'Approved with Conditions',
  },
  declined: {
    id: 'App.Screening.declined',
    defaultMessage: 'Declined',
  },
  saveFinalDecision: {
    id: 'App.Screening.saveFinalDecision',
    defaultMessage: 'Save Final Decision',
  },
  screeningSubmittedOn: {
    id: 'App.Screening.screeningSubmittedOn',
    defaultMessage: 'Screening submitted on',
  },
  by: {
    id: 'App.Screening.by',
    defaultMessage: 'by',
  },
  failedToSubmitScreening: {
    id: 'App.Screening.failedToSubmitScreening',
    defaultMessage: 'Failed to submit screening',
  },
});

export default messages;
