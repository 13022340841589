import { useState } from 'react';
import {
  useModal,
  useConfirmModal,
} from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
// eslint-disable-next-line max-len
import { HouseholdRentableItemsModalForm } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import useReactHookFormProps from '../../hooks/useReactHookFormProps';
import useManageQueries from './hooks.useManageQueries';
import useManageSelections from './hooks.useManageSelections';
import useManageEffects from './hooks.useManageEffects';
import useManageFields from './hooks.useManageFields';
import useHandlers from './hooks.useHandlers';
import useManageProps from './hooks.useManageProps';
import type { UseRentableItems } from './types';

export const useRentableItems = ({
  actualOrAnticipatedMoveOutDate,
  hasAssignedUnit,
  householdId,
  promptToaster,
  setHasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate,
  refetchHouseholdRentableItemsTrigger,
}: UseRentableItems) => {
  const { open, openModal, closeModal } = useModal();
  const { openConfirm, openConfirmModal, closeConfirmModal } =
    useConfirmModal();
  const [householdRentableItemId, setHouseholdRentableItemId] = useState();
  const [editMode, setEditMode] = useState(false);

  // React Hook Form
  const ReactHookFormProps = useReactHookFormProps({
    defaultValues: {
      type: '',
      numberId: '',
      priceOfItem: '',
      startDate: '',
      endDate: '',
    },
  });

  const {
    clearErrors,
    errors,
    isValid,
    isDirty,
    handleSubmit,
    watch,
    reset,
    setError,
    setValue,
  } = ReactHookFormProps;

  const typeValue = watch('type');
  const numberIdValue = watch('numberId');
  const selectedStartDate = watch('startDate');
  const selectedEndDate = watch('endDate');

  // Queries
  const {
    allHouseholdRentableItems,
    allPropertyFeesWithRentableItems,
    allPropertyRentableItems,
    createHouseholdRentableItem,
    deleteHouseholdRentableItem,
    selectedHouseholdRentableItem,
    updateHouseholdRentableItem,
  } = useManageQueries({
    promptToaster,
    householdRentableItemId,
    householdId,
  });

  // Manage selections
  const {
    hasEndDateAfterAnticipatedMoveOutDate,
    selectedPropertyFeeWithRentableItem,
    selectedPropertyItemOfPropertyFeeWithRentableItem,
  } = useManageSelections({
    actualOrAnticipatedMoveOutDate,
    allPropertyFeesWithRentableItemsData:
      allPropertyFeesWithRentableItems?.data,
    allHouseholdRentableItemsData: allHouseholdRentableItems?.data,
    numberIdValue,
    typeValue,
  });

  // Manage effects
  useManageEffects({
    editMode,
    hasEndDateAfterAnticipatedMoveOutDate,
    open,
    refetchHouseholdRentableItems: allHouseholdRentableItems.refetch,
    reset,
    selectedPropertyFeeWithRentableItem,
    selectedHouseholdRentableItemData: selectedHouseholdRentableItem.data,
    setEditMode,
    setHasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate,
    setHouseholdRentableItemId,
    setValue,
    refetchHouseholdRentableItemsTrigger,
  });

  // Manage field props
  const formFields = useManageFields({
    actualOrAnticipatedMoveOutDate,
    allPropertyFeesWithRentableItemsData: allPropertyFeesWithRentableItems.data,
    clearErrors,
    editMode,
    errors,
    fields: HouseholdRentableItemsModalForm.defaultProps.fields,
    selectedHouseholdRentableItemData: selectedHouseholdRentableItem.data,
    selectedPropertyFeeWithRentableItem,
    selectedPropertyItemOfPropertyFeeWithRentableItem,
    selectedStartDate,
    selectedEndDate,
    setError,
  });

  // Handlers
  const { handleDelete, onItemClick, onSubmit } = useHandlers({
    allHouseholdRentableItems,
    allPropertyFeesWithRentableItems,
    closeConfirmModal,
    closeModal,
    createHouseholdRentableItem,
    deleteHouseholdRentableItem,
    editMode,
    handleSubmit,
    householdId,
    householdRentableItemId,
    openModal,
    setEditMode,
    setHouseholdRentableItemId,
    setValue,
    updateHouseholdRentableItem,
  });

  // Component props
  const { modalProps, rentableItemsModalFormProps, rentableItemsProps } =
    useManageProps({
      ReactHookFormProps,
      actualOrAnticipatedMoveOutDate,
      closeConfirmModal,
      closeModal,
      editMode,
      formFields,
      handleDelete,
      hasAssignedUnit,
      hasEndDateAfterAnticipatedMoveOutDate,
      householdRentableItems: allHouseholdRentableItems?.data,
      isDirty,
      isValid,
      onItemClick,
      onSubmit,
      open,
      openConfirm,
      openConfirmModal,
      openModal,
    });

  return {
    allPropertyRentableItems,
    allPropertyFeesWithRentableItems,
    formFields,
    modalProps,
    rentableItemsProps,
    rentableItemsModalFormProps,
  };
};
