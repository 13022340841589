import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.RentForecast.RentForecastHeader.Title',
    defaultMessage: 'Forecast Rents',
  },
  createRentForecast: {
    id: 'App.RentForecast.RentForecastHeader.CreateRentForecast',
    defaultMessage: 'Create Rent Forecast',
  },
  newRentForecast: {
    id: 'App.RentForecast.NewRentForecast',
    defaultMessage: 'New Rent Forecast',
  },
  name: {
    id: 'App.RentForecast.Name',
    defaultMessage: 'Name',
  },
  placeholder: {
    id: 'App.RentForecast.Placeholder',
    defaultMessage: 'Forecast Name...',
  },
  floorPlans: {
    id: 'App.RentForecast.FloorPlans',
    defaultMessage: 'Floor Plans',
  },
  bedsBaths: {
    id: 'App.RentForecast.BedsBaths',
    defaultMessage: 'Beds/Baths',
  },
  numUnits: {
    id: 'App.RentForecast.NumUnits',
    defaultMessage: '# of Units',
  },
  currentValue: {
    id: 'App.RentForecast.CurrentValue',
    defaultMessage: 'Current {column}',
  },
  totalIncome: {
    id: 'App.RentForecast.TotalIncome',
    defaultMessage: 'Total {column} Income',
  },
  proposedChange: {
    id: 'App.RentForecast.ProposedChange',
    defaultMessage: 'Proposed Change',
  },
  newValue: {
    id: 'App.RentForecast.NewValue',
    defaultMessage: 'New {column}',
  },
  newPotentialIncome: {
    id: 'App.RentForecast.NewPotentialIncome',
    defaultMessage: 'New {column} Potential Income',
  },
  currentIncomeTooltip: {
    id: 'App.RentForecast.CurrentIncome',
    defaultMessage:
      'The Total Current Income is calculated by the Current amount X the # of Units',
  },
  newProposedAmountTooltip: {
    id: 'App.RentForecast.NewProposedAmount',
    defaultMessage:
      'The New Proposed amount is calculated by the Current amount X the Proposed Change',
  },
  newPotentialIncomeTooltip: {
    id: 'App.RentForecast.NewPotentialIncomeTooltip',
    defaultMessage:
      'The New Potential Income is calculated by the New Proposed amount X the # of Units',
  },
  success: {
    id: 'App.RentForecast.Success',
    defaultMessage: 'Success',
  },
  createdForecast: {
    id: 'App.RentForecast.CreatedForecast',
    defaultMessage: 'Created Rent Forecast',
  },
  budgetSaved: {
    id: 'App.RentForecast.BudgetSaved',
    defaultMessage: 'Budget Saved Successfully!',
  },
  reviewRDBudget: {
    id: 'App.RentForecast.ReviewRDBudget',
    defaultMessage: 'Review RD Budget',
  },
  comments: {
    id: 'App.RentForecast.Comments',
    defaultMessage: 'Comments',
  },
  review: {
    id: 'App.RentForecast.Review',
    defaultMessage: 'Review',
  },
  intacctHeader: {
    id: 'App.RentForecast.IntacctHeader',
    defaultMessage: 'Refresh from Intacct',
  },
  intacctBody: {
    id: 'App.RentForecast.IntacctBody',
    defaultMessage:
      'Refreshing will update all information with the latest data from Intacct.',
  },
  intacctWarningPart1: {
    id: 'App.RentForecast.IntacctWarningPart1',
    defaultMessage: 'Items labeled',
  },
  intacctWarningPart2: {
    id: 'App.RentForecast.IntacctWarningPart2',
    defaultMessage: '"Modified After Intacct Sync"',
  },
  intacctWarningPart3: {
    id: 'App.RentForecast.IntacctWarningPart3',
    defaultMessage:
      'must be updated in Intacct first. If they are not, any changes ' +
      'made in Fortress since the last refresh will be overwritten.',
  },
  intacctSuccess: {
    id: 'App.RentForecast.IntacctSuccess',
    defaultMessage: 'Data from Intacct has been updated.',
  },
  date: {
    id: 'App.RentForecast.Date',
    defaultMessage: 'Year',
  },
  datePlaceholder: {
    id: 'App.RentForecast.DatePlaceholder',
    defaultMessage: 'YYYY',
  },
});

export default messages;
