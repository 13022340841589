import React, { useMemo } from 'react';
import { WorkOrder } from '../../../App/types';
import {
  Table,
  TableHeader,
  useTableManageColumns,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import messages from '../../messages';
import { isNil, pathOr } from 'ramda';
import { getDateDiffInDays, getDateUTC } from '../../../../utils/date-helpers';
import { getUrlWithSelectedPropertyId } from '../../../../utils/navigation-helpers';
import usePermissionsHook from '../../../../components/ElementWithPermissions/usePermissionsHook';
import useUniqueTableName from '../../../../hooks/useUniqueTableName';
import useBaseTableProps from '../../../../hooks/useBaseTableProps';

type Props = {
  handleSortChange: Function,
  headers: Object[],
  intl: Object,
  isLoading: boolean,
  onPageChange: Function,
  perPage: number,
  order: string,
  orderBy: string,
  page: number,
  paginationMeta: any,
  workOrders: Array<WorkOrder>,
};

function WorkOrderTable({
  handleSortChange,
  headers,
  intl,
  isLoading,
  onPageChange,
  order,
  orderBy,
  page = 1,
  perPage,
  paginationMeta = {},
  workOrders = [],
}: Props) {
  const name = useUniqueTableName('resident-work-orders');
  const { pageCount, totalCount } = paginationMeta;
  const isWorkOrderReadPermitted = usePermissionsHook({
    scope: ['workorder-read'],
  });
  const rows = useMemo(() => {
    return workOrders.map((workOrder) => {
      const finishedDate = workOrder.finishedDate;
      const lastStatusChange = workOrder.lastStatusChangeDate;
      const statusDescription = workOrder.statusDesc;
      const dateToCompare =
        statusDescription === 'Open' ||
        statusDescription === 'On Hold' ||
        statusDescription === 'In Progress'
          ? getDateUTC()
          : statusDescription === 'Canceled'
          ? lastStatusChange
          : isNil(finishedDate)
          ? lastStatusChange
          : finishedDate;

      const daysOpen = getDateDiffInDays(dateToCompare, workOrder.createdAt);

      const otherCommonAreaLocation =
        workOrder?.otherCommonAreaLocation ?? null;
      // $FlowFixMe
      let location = pathOr(false, ['commonAreaLocationId'], workOrder)
        ? // $FlowFixMe
          pathOr(
            '',
            ['commonAreaLocation', 'commonAreaLocationDescription'],
            workOrder,
          )
        : workOrder.unitNumber;
      if (location === 'Other' && otherCommonAreaLocation) {
        location = `${location} - ${otherCommonAreaLocation}`;
      }

      const issue = workOrder.issue ? workOrder.issue : null;
      const issueDescription =
        issue && issue.issueDescription ? issue.issueDescription : '';
      // $FlowFixMe
      const otherDescription = pathOr(null, ['otherDescription'], workOrder);
      const description = otherDescription
        ? `Other - ${otherDescription}`
        : issueDescription;
      return {
        statusDesc: {
          variant: 'status',
          value: statusDescription,
        },
        createdAt: {
          variant: 'dateTime',
          value: workOrder.createdAt,
        },
        issueDescription: {
          variant: isWorkOrderReadPermitted ? 'link' : undefined,
          value: description,
          target: '_blank',
          to: getUrlWithSelectedPropertyId(
            `/edit-work-order/${workOrder.detailId}`,
          ),
        },
        priorityLevelDesc: {
          variant: 'priority',
          value: workOrder.priorityLevelDesc,
        },
        daysOpen: { variant: 'number', value: daysOpen },
        finishedDate: {
          variant: 'date',
          value: workOrder.finishedDate,
        },
      };
    });
  }, [isWorkOrderReadPermitted, workOrders]);
  const baseTableProps = useBaseTableProps();
  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
    handleTruncateChange,
    truncatedColumns,
  } = useTableManageColumns({
    name,
    headers,
    firstRow: rows[0],
  });

  return (
    <div data-testid="work-order-table">
      <TableHeader
        title={intl.formatMessage(messages.workOrders)}
        count={totalCount ?? 0}
        actions={undefined}
      />
      <Table
        {...baseTableProps}
        allColumnsHidden={allColumnsHidden}
        columnOptions={columnOptions}
        count={workOrders.length}
        currentPage={page}
        handleColumnChange={handleColumnChange}
        handleSortChange={handleSortChange}
        handleTruncateChange={handleTruncateChange}
        headers={filteredHeaders}
        height={800}
        intl={intl}
        isLoading={isLoading}
        name={name}
        order={order}
        orderBy={orderBy}
        pageCount={pageCount}
        perPage={perPage}
        rows={rows}
        selectedColumns={selectedColumns}
        totalCount={totalCount || 0}
        truncatedColumns={truncatedColumns}
        onPageChange={(event, page) => {
          onPageChange(page);
        }}
      />
    </div>
  );
}

export default WorkOrderTable;
