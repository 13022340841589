import _ from 'lodash';
import { formatDateTimeDisplayLocal } from '@fortress-technology-solutions/fortress-component-library/utils';
import { actionsMapper } from './ManageAffordableWaitlistModals/constants';

export const formatNotesForSingleCell = (notes = []) => {
  const result = notes
    .map((note) => {
      const reasons =
        note?.reasons && Array.isArray(note?.reasons)
          ? note.reasons.map((reason) => _.startCase(reason)).join(',')
          : '';

      const description = note?.description
        ? note?.description.replace(/"/g, '""')
        : '';
      const reasonsContent = reasons ? `Reasons: ${reasons}` : '';
      // eslint-disable-next-line max-len
      const formattedString = `Action: ${
        actionsMapper[note.action]
      }, Description: ${description}, ${reasonsContent}, Created At: ${formatDateTimeDisplayLocal(
        note.createdAt,
      )} \n`;

      return formattedString;
    })
    .join('');

  return result;
};
