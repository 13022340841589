import {
  Accordion,
  Box,
  Button,
  IconBadgeStatus,
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import React from 'react';
import { injectIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import { post } from '../../utils/api';
import FinalDecisionForm from './FinalDecisionForm';
import messages from './messages';
import SubmitScreeningModal from './SubmitScreeningModal';
import TransUnionScreening from './TransUnionScreening';
import type { Props } from './types';
import { useScreeningData } from './hooks';

function CreditCriminalStatusScreening({
  intl,
  isTransUnionActive,
  screening: screeningNotReadyData,
  householdId,
  applicationId,
}: Props) {
  const [isSubmitScreeningModalOpen, setIsSubmitScreeningModalOpen] =
    React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = () => setExpanded(!expanded);

  const missingRequiredFields = React.useMemo(() => {
    if (screeningNotReadyData?.error !== 1) return [];
    return screeningNotReadyData?.message
      ?.split(',')
      .map((field) => field.trim());
  }, [screeningNotReadyData?.message, screeningNotReadyData?.error]);

  const {
    transUnionStatuses,
    refetchHouseholdScreening,
    transUnionStatusesAreAllApproved,
    submissionData,
    screeningStatus,
  } = useScreeningData({
    householdId,
    missingRequiredFields,
  });

  return (
    <Accordion
      summary={
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          width="100%"
          paddingRight={2}
        >
          <IconBadgeStatus variant={screeningStatus} />
          <Typography variant="bodyLarge">
            {intl.formatMessage(messages.title)}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            variant="primarySubtle"
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              setIsSubmitScreeningModalOpen(true);
            }}
            disabled={missingRequiredFields.length > 0}
          >
            {intl.formatMessage(messages.submitScreening)}
          </Button>
        </Stack>
      }
      expanded={expanded}
      onChange={handleChange}
      sx={{
        marginBottom: 6,
      }}
    >
      <Stack spacing={2}>
        {isTransUnionActive && (
          <Stack>
            <TransUnionScreening
              intl={intl}
              missingRequiredFields={missingRequiredFields}
              creditStatus={transUnionStatuses.creditStatus}
              criminalStatus={transUnionStatuses.criminalStatus}
              evictionStatus={transUnionStatuses.evictionStatus}
            />
            {submissionData && (
              <Typography
                variant="bodySmall"
                color="text.secondary"
                flexItem
                alignSelf="flex-end"
                marginTop={0.5}
              >
                {intl.formatMessage(messages.screeningSubmittedOn)}{' '}
                {submissionData.submittedOn} {intl.formatMessage(messages.by)}{' '}
                {submissionData.submittedBy}
              </Typography>
            )}
          </Stack>
        )}
        <FinalDecisionForm
          intl={intl}
          isReadyForDecision={transUnionStatusesAreAllApproved}
        />
      </Stack>
      <SubmitScreeningModal
        intl={intl}
        open={isSubmitScreeningModalOpen}
        onClose={() => setIsSubmitScreeningModalOpen(false)}
        onSubmit={(amount) => {
          post(
            '/screeningService/submit',
            JSON.stringify({
              applicationId,
              monthlyRentAmount: amount,
            }),
          )
            .then((response) => {
              toastr.success(
                intl.formatMessage(messages.success),
                intl.formatMessage(messages.screeningSubmitted),
              );
              refetchHouseholdScreening();
            })
            .catch(() => {
              toastr.error(
                intl.formatMessage(messages.error),
                intl.formatMessage(messages.failedToSubmitScreening),
              );
            });
        }}
      />
    </Accordion>
  );
}

export default injectIntl(CreditCriminalStatusScreening);
