import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import messages from './messages';
import validate from './validate';
import { renderTextAreaField } from '../../../utils/redux-form-helper';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import type { Prospect } from '../../ProspectProfile/types';
import type { StatusChange } from '../types';
import {
  Title,
  Divider,
  DetailList,
} from '../../../components/ProfileVisualComponents';

type Option = {
  value: string,
  text: string,
};

type Props = {
  initialValues: { prospect: Prospect, statusChange: StatusChange },
  latestLog: Object,
  handleSubmit: Function,
  handleNewStatusChange: Function,
  prospectStatusList: Array<Option>,
  pristine: boolean,
  valid: boolean,
  intl: Object,
  handleCancel: Function,
  statusChange: Object,
};

type StateProps = {
  statusChange: Object,
};

const formatPhone = (phoneNumber: string) => {
  return phoneNumber
    ? `(${phoneNumber.substr(0, 3)}) ${phoneNumber.substr(
        3,
        3,
      )}-${phoneNumber.substr(6, 4)}`
    : '---';
};

const dashesIfNullOrUndefined = (data: string) => (!data ? '---' : data);

export const ProfileDetailsEditMode = (props: Props) => {
  const {
    intl,
    initialValues,
    latestLog,
    handleSubmit,
    handleNewStatusChange,
    prospectStatusList,
    pristine,
    valid,
    handleCancel,
    statusChange,
  } = props;

  const firstName = dashesIfNullOrUndefined(initialValues.prospect.firstName);
  const lastName = dashesIfNullOrUndefined(initialValues.prospect.lastName);
  const email = dashesIfNullOrUndefined(initialValues.prospect.emailAddress);
  const phone = formatPhone(initialValues.prospect.phoneNumber);

  const renderLastestNote =
    latestLog && latestLog.notes
      ? [
          <Col key={1} xs={12} sm={3}>
            <label className="profile-details">
              <FormattedMessage {...messages.notes} />
            </label>
          </Col>,
          <Col key={2} xs={12} sm={9}>
            <span className="notes-cont">{latestLog.notes}</span>
          </Col>,
        ]
      : null;

  const renderNotesField =
    (statusChange && statusChange.statusId) !==
    initialValues.statusChange.statusId
      ? [
          <Col key={1} xs={12} md={3}>
            <label className="profile-details">
              <FormattedMessage {...messages.notes} />
            </label>
          </Col>,
          <Col key={2} xs={12} md={9}>
            <Field
              name="statusChange.notes"
              component={renderTextAreaField}
              rows="3"
              bsSize="sm"
              placeholder={intl.formatMessage(messages.addNewNote)}
              maxLength="250"
            />
          </Col>,
        ]
      : renderLastestNote;
  const isDisabledButton =
    pristine ||
    !valid ||
    statusChange.statusId === initialValues.statusChange.statusId;
  return (
    <>
      <>
        <Title>
          <h1 key="1">
            {firstName} {lastName}
          </h1>
        </Title>
      </>
      <>
        <form onSubmit={handleSubmit}>
          <div className="profile-view-edit">
            <Row>
              <Col xs={12} md={3}>
                <label className="profile-details">
                  <FormattedMessage {...messages.status} />
                </label>
              </Col>
              <Col xs={12} md={9}>
                <div className="input-group inline-input">
                  <Field
                    name="statusChange.statusId"
                    component="select"
                    className="form-control input-sm"
                    onChange={handleNewStatusChange}
                  >
                    {prospectStatusList.map((status) => (
                      <option key={status.value} value={status.value}>
                        {status.text}
                      </option>
                    ))}
                  </Field>
                </div>
              </Col>
              {renderNotesField}
            </Row>
            <Row>
              <Col xs={6}>
                <Button
                  bsStyle="default"
                  className="pull-right"
                  onClick={() => handleCancel(pristine)}
                >
                  Cancel
                </Button>
              </Col>
              <Col xs={6}>
                <ElementWithPermissions scope={['prospect-update']}>
                  <Button
                    bsStyle="primary"
                    className="pull-left"
                    type="submit"
                    disabled={isDisabledButton}
                  >
                    Save Changes
                  </Button>
                </ElementWithPermissions>
              </Col>
            </Row>
            <Divider />
            <h2>
              <FormattedMessage {...messages.contactInformation} />
            </h2>
            <DetailList>
              <li>
                {' '}
                <FormattedMessage {...messages.phone} />
                {` ${phone}`}
              </li>
              <li>
                {' '}
                <FormattedMessage {...messages.email} />
                <a href={`mailto:${email}`}> {email}</a>
              </li>
            </DetailList>
          </div>
        </form>
      </>
    </>
  );
};

const selector = formValueSelector('peopleProfileEditDetails');

const mapStateToProps = (state): StateProps => ({
  statusChange: selector(state, 'statusChange'),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'peopleProfileEditDetails',
    validate: validate,
  })(ProfileDetailsEditMode),
);
