import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import * as R from 'ramda';
import { Box } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Title } from '../../components/ProfileVisualComponents';
import IconTitle from '../../components/ProfileVisualComponents/IconTitle';
import ContactsSection from './ContactsSection';
import InsuranceSection from './InsuranceSection';
import PeopleSection from './PeopleSection';
import messages from './messages';
import InsuranceModal from './InsuranceModal';
import * as hooks from './hooks';
import type { SelectedProperty } from './types';
import ContactModal from './ContactModal';
import RentableItems from '../../components/ResidentProfileRentableItems';
import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { promptToaster } from '../App/actions';
import { useFlags } from 'launchdarkly-react-client-sdk';

type Props = {
  actions: any,
  householdId: string,
  selectedProperty: Object,
  householdMembers: Array<Object>,
  editHouseholdMember: Function,
  residentId?: string,
  anyContact?: boolean,
  anyInsurance?: boolean,
  onContactCheckboxClick: Function,
  onInsuranceCheckboxClick: Function,
  hasAssignedUnit: boolean,
  isPriorResident: boolean,
  actualOrAnticipatedMoveOutDate: Object,
  setHasHouseholdRentableItems: (state: boolean) => void,
  setHasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate: (
    state: boolean,
  ) => void,
};

const PanelRow = ({ children, hideFooter = false }) => (
  <Row>
    {children}
    {!hideFooter && <div className="panel-footer" />}
  </Row>
);

const ProfileHeader = () => (
  <Title>
    <Row>
      <Col xs={12} sm={9}>
        <IconTitle
          icon={<i className="et-people" />}
          message={messages.tenant}
        />
      </Col>
    </Row>
  </Title>
);

const ProfileBody = ({
  actions,
  contacts,
  insurances,
  onInsuranceModalOpen,
  onInsuranceSelect,
  onContactModalOpen,
  onContactSelect,
  householdMembers,
  editHouseholdMember,
  residentId,
  onContactCheckboxClick,
  onInsuranceCheckboxClick,
  anyContact,
  anyInsurance,
  householdId,
  hasAssignedUnit,
  isPriorResident,
  actualOrAnticipatedMoveOutDate,
  setHasHouseholdRentableItems = () => {},
  setHasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate,
}) => {
  const flags = useFlags();
  const disableCheckbox = !R.isNil(residentId);
  const contactCheckboxChecked = anyContact === false;
  const insuranceCheckboxChecked = anyInsurance === false;

  return (
    <>
      <PanelRow>
        <PeopleSection
          householdMembers={householdMembers}
          editHouseholdMember={editHouseholdMember}
          residentId={residentId}
        />
      </PanelRow>
      <PanelRow>
        <ContactsSection
          contacts={contacts}
          onAddClick={onContactModalOpen}
          onNameClick={onContactSelect}
          checkboxChecked={contactCheckboxChecked}
          disableCheckbox={disableCheckbox}
          onCheckboxClick={onContactCheckboxClick}
        />
      </PanelRow>
      <PanelRow hideFooter>
        {flags.rentableItems && (
          <div style={{ padding: '0 8px' }}>
            <RentableItems
              actualOrAnticipatedMoveOutDate={actualOrAnticipatedMoveOutDate}
              hasAssignedUnit={hasAssignedUnit}
              isPriorResident={isPriorResident}
              householdId={householdId}
              promptToaster={actions.promptToaster}
              refetchHouseholdRentableItemsTrigger={0}
              setHasHouseholdRentableItems={setHasHouseholdRentableItems}
              setHasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate={
                setHasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate
              }
            />
          </div>
        )}
      </PanelRow>
      <PanelRow hideFooter>
        <InsuranceSection
          insurances={insurances}
          onAddClick={onInsuranceModalOpen}
          onTypeClick={onInsuranceSelect}
          checkboxChecked={insuranceCheckboxChecked}
          disableCheckbox={disableCheckbox}
          onCheckboxClick={onInsuranceCheckboxClick}
        />
      </PanelRow>
    </>
  );
};

function Tenant({
  actions,
  householdId,
  selectedProperty,
  householdMembers,
  editHouseholdMember,
  residentId,
  anyContact,
  anyInsurance,
  onContactCheckboxClick,
  onInsuranceCheckboxClick,
  hasAssignedUnit,
  isPriorResident,
  actualOrAnticipatedMoveOutDate,
  setHasHouseholdRentableItems,
  setHasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate,
}: Props) {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [isInsuranceModalOpen, setIsInsuranceModalOpen] = useState(false);
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [insurances, refreshInsurances] = hooks.useTenantInsurances(
    householdId,
    selectedProperty,
  );
  const [contacts, refreshContacts] = hooks.useTenantContacts(
    householdId,
    selectedProperty,
  );
  const organizationId = R.prop('organizationId', selectedProperty);
  const propertyId = R.prop('id', selectedProperty);

  const handleContactModalOpen = () => {
    setIsContactModalOpen(true);
  };

  const handleContactModalClose = () => {
    setIsContactModalOpen(false);
    if (selectedContact) setSelectedContact(null);
  };

  const handleContactSelect = (contact) => {
    setSelectedContact(contact);
    handleContactModalOpen();
  };

  const handleInsuranceModalOpen = () => {
    setIsInsuranceModalOpen(true);
  };

  const handleInsuranceModalClose = () => {
    setIsInsuranceModalOpen(false);
    if (selectedInsurance) setSelectedInsurance(null);
  };

  const handleInsuranceSelect = (insurance) => {
    setSelectedInsurance(insurance);
    handleInsuranceModalOpen();
  };

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <ProfileHeader />
        <ProfileBody
          actions={actions}
          contacts={contacts || []}
          insurances={insurances || []}
          onInsuranceSelect={handleInsuranceSelect}
          onInsuranceModalOpen={handleInsuranceModalOpen}
          onContactSelect={handleContactSelect}
          onContactModalOpen={handleContactModalOpen}
          householdMembers={householdMembers}
          editHouseholdMember={editHouseholdMember}
          residentId={residentId}
          anyContact={anyContact}
          anyInsurance={anyInsurance}
          onContactCheckboxClick={onContactCheckboxClick}
          onInsuranceCheckboxClick={onInsuranceCheckboxClick}
          householdId={householdId}
          organizationId={organizationId}
          propertyId={propertyId}
          setHasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate={
            setHasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate
          }
          setHasHouseholdRentableItems={setHasHouseholdRentableItems}
          actualOrAnticipatedMoveOutDate={actualOrAnticipatedMoveOutDate}
          isPriorResident={isPriorResident}
          hasAssignedUnit={hasAssignedUnit}
        />
      </Box>

      <ContactModal
        show={isContactModalOpen}
        onHide={handleContactModalClose}
        contact={selectedContact}
        organizationId={organizationId}
        householdId={householdId}
        refreshContacts={refreshContacts}
      />
      <InsuranceModal
        show={isInsuranceModalOpen}
        onHide={handleInsuranceModalClose}
        insurance={selectedInsurance}
        organizationId={organizationId}
        householdId={householdId}
        refreshInsurances={refreshInsurances}
      />
    </>
  );
}

function mapStateToProps(state): { selectedProperty: SelectedProperty } {
  return {
    selectedProperty: R.path(['app', 'selectedProperty'], state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    actions: bindActionCreators(
      {
        promptToaster,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Tenant);
