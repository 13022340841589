import { useMemo } from 'react';
import useSelectedProperty from '../../hooks/useSelectedProperty';
import RentableItemsService from '../../services/rentableItemsService';
import {
  useCreateHouseholdRentableItem,
  useDeleteHouseholdRentableItem,
  useFetchAllHouseholdRentableItems,
  useFetchAllPropertyFeesWithRentableItems,
  useFetchAllPropertyRentableItems,
  useFetchHouseholdRentableItem,
  useUpdateHouseholdRentableItem,
} from './hooks.queriesAndMutations';
import type { UseManageQueries } from './types';

const useManageQueries = ({
  householdId,
  householdRentableItemId,
  promptToaster,
}: UseManageQueries) => {
  const { id: propertyId, organizationId } = useSelectedProperty();
  const rentableItemsService = useMemo(() => new RentableItemsService(), []);

  // Mutations
  const createHouseholdRentableItem = useCreateHouseholdRentableItem({
    propertyId,
    organizationId,
    rentableItemsService,
    promptToaster,
  });
  const updateHouseholdRentableItem = useUpdateHouseholdRentableItem({
    propertyId,
    organizationId,
    rentableItemsService,
    promptToaster,
  });
  const deleteHouseholdRentableItem = useDeleteHouseholdRentableItem({
    propertyId,
    organizationId,
    rentableItemsService,
    promptToaster,
  });

  // Getters
  const allPropertyRentableItems = useFetchAllPropertyRentableItems({
    propertyId,
    organizationId,
    rentableItemsService,
  });
  const allPropertyFeesWithRentableItems =
    useFetchAllPropertyFeesWithRentableItems({
      propertyId,
      organizationId,
      rentableItemsService,
    });
  const allHouseholdRentableItems = useFetchAllHouseholdRentableItems({
    propertyId,
    organizationId,
    householdId,
    rentableItemsService,
  });
  const selectedHouseholdRentableItem = useFetchHouseholdRentableItem({
    propertyId,
    organizationId,
    id: householdRentableItemId,
    rentableItemsService,
  });

  return {
    allHouseholdRentableItems,
    allPropertyFeesWithRentableItems,
    allPropertyRentableItems,
    createHouseholdRentableItem,
    deleteHouseholdRentableItem,
    selectedHouseholdRentableItem,
    updateHouseholdRentableItem,
  };
};

export default useManageQueries;
