import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import DocumentTitle from 'react-document-title';
import { FormattedMessage } from 'react-intl';
import qs from 'qs';
import { pathOr, isNil, intersection } from 'ramda';
import { SizedBox } from '@fortress-technology-solutions/fortress-component-library/Atoms';

import FloorplanDetails from './FloorplanDetails';
import FloorplanPricing from './FloorplanPricing';
import Amenities from './Amenities';
import AmenitiesV2 from './AmenitiesV2';
import MonthlyRecurringCharges from './MonthlyRecurringCharges';
import GeneralUnitInformation from './GeneralUnitInformation';
import UnitStatus from './UnitStatus';
import UnitLeaseInformation from './UnitLeaseInformation';
import FloorplansFormModal from './FloorplansFormModal';

import type { GlobalState } from '../App/types';
import type { Unit } from './types';

import * as viewUnitActions from './actions';
import { getAllFloorPlans } from '../ManageFloorPlans/actions';

import messages from './messages';
import { getCurrentUser } from '../ApplicationProfile/selectors';

import { FORM_NAME } from './constants';
import { getAffordableFloorPlanProgramTypes } from '../../utils/affordable';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import { promptToaster } from '../App/actions';
import { assignHideUnitFromOnlineAvailabilityToUnit } from './utils';
import UnitHistory from './UnitHistory';
import { AFFORDABLE_PROGRAMS } from '../../constants/affordableProgramNames';
import HUD_SUBSIDY_CODES from '../../constants/hudSubsidyCodes';

type Props = {
  actions: Object,
  flags: { hideUnitFromPublicListing: boolean },
  history: Object,
  intl: any,
  location: Object,
  match: Object,
  selectedProperty: Object,
};

type StateProps = {
  allowances: Array<Object>,
  floorplanDetails: Object,
  isAffordable: boolean,
  isAffordableMixedProperty: boolean,
  unit: Object,
  unitStatuses: Array<Object>,
  currentUser: Object,
  floorplans: Array<Object>,
  setAsideSummaryKPI: Array<Object> | null,
  incomeAvgPercent: number | null,
  actualIncomeAvg: number | null,
};

type State = {
  notes: string,
  prev: string,
  selectedStatus: string | null,
  modal: string,
  unitId: string | null,
  floorplanId: string | null,
  shouldClearUnit: boolean,
};
class ViewUnit extends Component<Props & StateProps, State> {
  timer: any = null;

  constructor(props) {
    super(props);
    this.state = {
      notes: null,
      prev: '',
      selectedStatus: null,
      modal: '',
      unitId: null,
      floorplanId: null,
      shouldClearUnit: false,
    };
  }

  showModal = () => {
    this.setState({ modal: FORM_NAME });
  };

  resetModal = () => {
    this.setState({ modal: '' });
  };

  handleModalSubmit = (values: Object) => {
    this.setState({ modal: '' });

    const updatedFloorplanId = pathOr(null, ['updatedFloorplanId'], values);
    if (updatedFloorplanId) {
      this.updateUnit({ floorPlanId: updatedFloorplanId });
    }
  };

  /**
   * Refresh the unit object with it's latest values after an update
   */
  refreshUnit = (unit: Unit) => {
    this.props.actions.updateUnitSuccess(unit);
  };

  componentWillMount() {
    const unitId = pathOr(null, ['match', 'params', 'unitId'], this.props);
    const floorplanId = pathOr(
      null,
      ['match', 'params', 'floorplanId'],
      this.props,
    );
    const prev = pathOr(
      null,
      ['prev'],
      qs.parse(this.props.location.search.slice(1)),
    );

    // The following atrocity is sponsored by Redux :)
    const unitIdFromStateProps = this?.props?.unit?.id;
    /*
    1. Avoid calling clear when loading floorPlanId since it's already handled
    2. The reason I use include is cuz react-router doesn't remove the '?prev=available'
       param that is sent through the url, don't blame me, that's how the path is defined.
    */
    const shouldClearUnit =
      isNil(floorplanId) &&
      !isNil(unitIdFromStateProps) &&
      !unitId?.includes(unitIdFromStateProps);
    // $FlowFixMe
    this.setState({ unitId, floorplanId, prev, notes: null, shouldClearUnit });
  }

  componentDidMount() {
    if (this.state.shouldClearUnit) {
      this.props?.actions?.cleanLoadedUnit?.();
    }
    this.loadPage();
  }

  componentDidUpdate(prevProps, prevState) {
    const notes = pathOr(null, ['unit', 'notes'], this.props);
    const stateNotes = this.state.notes;
    const unitId = pathOr(null, ['match', 'params', 'unitId'], this.props);
    const floorplanId = pathOr(
      null,
      ['match', 'params', 'floorplanId'],
      this.props,
    );

    const prevUnitId = prevState.unitId;
    const prevFloorplanId = prevState.floorplanId;
    const prev = pathOr(
      null,
      ['prev'],
      qs.parse(this.props.location.search.slice(1)),
    );

    const prevUnitFloorplanId = pathOr(
      null,
      ['unit', 'floorPlanId'],
      prevProps,
    );
    const currentUnitFloorplanId = pathOr(
      null,
      ['unit', 'floorPlanId'],
      this.props,
    );

    if (!isNil(notes) && isNil(stateNotes)) this.setState({ notes });

    if (unitId !== prevUnitId && floorplanId === null) {
      // $FlowFixMe
      this.setState({ unitId, floorplanId, prev }, () => {
        this.loadPage();
      });
    }

    if (floorplanId !== prevFloorplanId && unitId === null) {
      // $FlowFixMe
      this.setState({ unitId, floorplanId, prev }, () => {
        this.loadPage();
      });
    }

    if (prevUnitFloorplanId !== currentUnitFloorplanId) {
      this.props.actions.getIncomeAverageData();
    }
  }

  loadPage() {
    const { unitId, floorplanId } = this.state;
    const {
      getUnitById,
      getUnitStatuses,
      getFloorplanById,
      getAllFloorPlans,
      getIncomeAverageData,
      cleanLoadedUnit,
    } = this.props.actions;

    if (unitId) {
      getUnitById(unitId);
      getUnitStatuses();
      getAllFloorPlans();
      getIncomeAverageData();
    }

    if (floorplanId) {
      cleanLoadedUnit();
      getFloorplanById(floorplanId);
    }
  }

  componentWillUnmount(): void {
    clearTimeout(this.timer);
  }

  updateUnit = (payload: any) => {
    const {
      actions: { updateUnit },
      flags: { hideUnitFromPublicListing },
      match: {
        params: { unitId },
      },
      unitStatuses,
    } = this.props;

    let unitData = { ...payload };

    if (hideUnitFromPublicListing) {
      const isUpdatingUnitStatusId =
        Object.keys(payload)?.includes('unitStatusId');

      if (isUpdatingUnitStatusId) {
        unitData = assignHideUnitFromOnlineAvailabilityToUnit({
          unit: unitData,
          unitStatuses,
        });
      }
    }

    updateUnit({ unitId, ...unitData });
  };

  updateNotes = (e: any) => {
    clearTimeout(this.timer);
    const notes = e.target.value;
    this.setState({ notes }, () => this.startTimer(notes));
  };

  startTimer = (notes) => {
    this.timer = setTimeout(() => this.updateUnit({ notes }), 1000);
  };

  renderBackLink = () => {
    if (!this.state.prev) {
      return (
        <a className="btn-text" onClick={() => this.props.history.goBack()}>
          <i className="et-chevron-left" />
          <FormattedMessage {...messages.goBack} />
        </a>
      );
    }
    if (this.state.prev === 'manage') {
      return (
        <a
          className="btn-text"
          onClick={() =>
            navigateToUrlWithSelectedPropertyId('/manage-rent-roll')
          }
        >
          <i className="et-chevron-left" />
          <FormattedMessage {...messages.goBackManage} />
        </a>
      );
    }
    if (this.state.prev === 'available') {
      return (
        <a
          className="btn-text"
          onClick={() =>
            navigateToUrlWithSelectedPropertyId('/manage-unit-availability')
          }
        >
          <i className="et-chevron-left" />
          <FormattedMessage {...messages.goBackAvailable} />
        </a>
      );
    }
    if (this.state.prev === 'manage-commercial') {
      return (
        <a
          className="btn-text"
          onClick={() =>
            navigateToUrlWithSelectedPropertyId('/manage-commercial-rent-roll')
          }
        >
          <i className="et-chevron-left" />
          <FormattedMessage {...messages.goBackManageCommercial} />
        </a>
      );
    }

    //Just to make Source Code inspection more clear
    if (this.state.prev === 'unavailable') {
      return null;
    }

    return null;
  };

  parseAmenities = (fees: Array<Object>) => {
    return fees.reduce((prev, fee) => {
      let type;
      if (fee.propertyFees) {
        type = pathOr(null, ['propertyFees', 'feeType'], fee);
      } else {
        type = pathOr(null, ['feeType'], fee);
      }
      if (type && type === 'Amenity') {
        prev.push(fee);
      }
      return prev;
    }, []);
  };

  checkUserPermissions = (testScope: Array<string>) => {
    const userScopes = this.props.currentUser.permissions.map(
      (permission) => permission.scope,
    );
    return intersection(testScope, userScopes).length > 0;
  };

  handleFloorPlanImageChange = () => {
    const { floorplanId } = this.state;
    const { getFloorplanById } = this.props.actions;

    getFloorplanById(floorplanId);
  };

  render() {
    const {
      unit,
      intl,
      unitStatuses,
      floorplanDetails,
      floorplans,
      incomeAvgPercent,
      setAsideSummaryKPI,
      actualIncomeAvg,
      history,
      flags: {
        unitHistoryTable: isUnitHistoryFlagOn,
        section236: section236Flag,
      },
      match: {
        params: { unitId, floorplanId, propertyId },
      },
      selectedProperty,
      actions: { getUnitById, promptToaster },
      flags,
    } = this.props;
    const { notes } = this.state;
    const unitNumber = unitId ? unit.number : '';
    const floorplan = unitId ? unit.floorPlan : floorplanDetails;
    let fees;
    const rdRents = selectedProperty?.setup?.rdProjectType?.rdrents || {};

    if (unitId) {
      const unitFees = pathOr([], ['unitFees'], unit);
      const fpFees = pathOr([], ['floorPlan', 'floorPlanFees'], unit);
      fees = [...unitFees, ...fpFees];
    } else {
      const propertyFees = pathOr(
        [],
        ['property', 'propertyFees'],
        floorplanDetails,
      );
      const fpFees = pathOr([], ['floorPlanFees'], floorplanDetails);
      fees = [...propertyFees, ...fpFees];
    }
    const amenities = this.parseAmenities(fees);
    const allowances = pathOr(
      [],
      ['floorplanDetails', 'allowances'],
      this.props,
    );
    const isAffordable = pathOr(
      false,
      ['floorplanDetails', 'isAffordable'],
      this.props,
    );
    const { isHUDFloorPlan, isLIHTCFloorPlan, isRDFloorPlan } =
      getAffordableFloorPlanProgramTypes(floorplan);
    const initialFormValues = {
      updatedFloorplanId: pathOr('', ['id'], floorplan),
    };
    const isUnitCommercial =
      pathOr(false, ['floorPlan', 'isCommercial'], unit) &&
      pathOr(false, ['floorPlan', 'isCommercialLeasingActive'], unit);

    const isAffordableMixedProperty = ['Mixed', 'Affordable'].includes(
      selectedProperty?.propertyClass?.name,
    );
    const isAffordableMixedLIHTC =
      isAffordableMixedProperty && isLIHTCFloorPlan;

    let isSection236 = false;
    if (section236Flag) {
      const fpHudCode = (floorplan?.floorPlanAffordablePrograms ?? []).find(
        (fp) =>
          fp?.propertyAffordableProgram?.masterAffordableProgram?.name ===
          AFFORDABLE_PROGRAMS.HUD,
      )?.hudCode;
      const propertyHUDSubsidy = (
        selectedProperty?.propertyHUDSubsidy ?? []
      ).map((hud) => hud?.masterHUDSubsidy?.code);

      isSection236 =
        propertyHUDSubsidy.includes(HUD_SUBSIDY_CODES.SECTION_236) ||
        fpHudCode === HUD_SUBSIDY_CODES.SECTION_236;
    }
    const isUnitAmenitiesScreen = unitNumber !== '';

    return (
      <DocumentTitle title={this.props.intl.formatMessage(messages.title)}>
        <div>
          <FloorplansFormModal
            show={this.state.modal === FORM_NAME}
            floorplans={floorplans}
            onClose={this.resetModal}
            onSubmit={this.handleModalSubmit}
            initialValues={initialFormValues}
            isUnitCommercial={isUnitCommercial}
            isAffordableMixedProperty={isAffordableMixedProperty}
            incomeAveragingPercent={incomeAvgPercent}
            setAsideSummaryKPI={setAsideSummaryKPI}
            actualIncomeAvg={actualIncomeAvg}
          />
          <div className="page-unit-view">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xs-12 col-md-12">
                  {this.renderBackLink()}
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-xs-12 col-md-5">
                  <FloorplanDetails
                    loadFloorPlan={this.props.actions.getFloorplanById}
                    incomeAveragingPercent={incomeAvgPercent}
                    actualIncomeAvg={actualIncomeAvg}
                    unitNumber={unitNumber}
                    floorplan={floorplan}
                    showModal={this.showModal}
                    isAffordableMixedProperty={isAffordableMixedProperty}
                    selectedProperty={selectedProperty}
                    toasterFn={promptToaster}
                    onFloorPlanImageChange={this.handleFloorPlanImageChange}
                    refetchUnit={() => getUnitById(unitId)}
                  />
                  {flags.adjustUnitAmenitiesAtUnitLevel &&
                  isUnitAmenitiesScreen ? (
                    <AmenitiesV2
                      intl={intl}
                      amenities={amenities}
                      propertyId={propertyId}
                      unitId={this.props.unit.id}
                      refreshUnit={this.loadPage.bind(this)}
                    />
                  ) : (
                    <Amenities
                      intl={intl}
                      unitNumber={unitNumber}
                      amenities={amenities}
                    />
                  )}
                  {unitId ? (
                    <MonthlyRecurringCharges intl={intl} unit={unit} />
                  ) : null}
                </div>
                {unitId ? (
                  <div className="col-xs-12 col-md-7">
                    <GeneralUnitInformation
                      intl={intl}
                      unit={unit}
                      selectedProperty={selectedProperty}
                      refreshUnit={this.refreshUnit}
                      isHUDFloorPlan={isHUDFloorPlan}
                      getUnitById={getUnitById}
                      flags={flags}
                    />
                    <UnitStatus
                      intl={intl}
                      notes={notes}
                      unit={unit}
                      unitStatuses={unitStatuses}
                      updateNotes={this.updateNotes}
                      updateUnit={this.updateUnit}
                      checkUserPermissions={this.checkUserPermissions}
                    />
                    <UnitLeaseInformation
                      intl={intl}
                      unit={unit}
                      isUnitCommercial={isUnitCommercial}
                    />
                    {isUnitHistoryFlagOn && isAffordableMixedProperty && (
                      <>
                        <UnitHistory
                          intl={intl}
                          unitId={unitId}
                          floorPlanId={floorplan?.id}
                          history={history}
                          propertyId={propertyId}
                        />
                        <SizedBox h={20} />
                      </>
                    )}
                  </div>
                ) : (
                  <FloorplanPricing
                    intl={intl}
                    onEditClick={() => {}}
                    id={floorplanId}
                    isAffordableMixedProperty={isAffordableMixedProperty}
                    allowances={allowances}
                    isAffordable={isAffordable}
                    isHUDFloorPlan={isHUDFloorPlan}
                    isRDFloorPlan={isRDFloorPlan}
                    isLIHTCFloorPlan={isLIHTCFloorPlan}
                    isAffordableMixedLIHTC={isAffordableMixedLIHTC}
                    rdRents={rdRents}
                    isSection236={isSection236}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

export const mapStateToProps = (state: GlobalState): Object => {
  const { app } = state;
  return {
    unit: state.viewUnit.unit,
    unitStatuses: state.viewUnit.unitStatuses,
    floorplanDetails: state.viewUnit.floorplanDetails,
    floorplans: state.manageFloorPlans.floorPlans,
    currentUser: getCurrentUser(state),
    selectedProperty: app.selectedProperty,
    setAsideSummaryKPI: state.viewUnit.setAsideSummaryKPI,
    incomeAvgPercent: state.viewUnit.incomeAvgPercent,
    actualIncomeAvg: state.viewUnit.actualIncomeAvg,
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      ...viewUnitActions,
      getAllFloorPlans,
      promptToaster,
    },
    dispatch,
  );
  return { actions };
}

const connected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ViewUnit));

export default withLDConsumer()(connected);
