export const STATUSES = {
  NOT_STARTED: 'notStarted',
  NOT_READY: 'notReady',
  PENDING: 'pending',
  APPROVED: 'approved',
  REFER: 'refer',
  INCOMPLETE: 'incomplete',
};

export const PROVIDERS = {
  TRANSUNION: 'TransUnion',
};

export const TRANSUNION_SCREENING_METHODS = {
  CREDIT: 'credit',
  CRIMINAL: 'criminal',
  EVICTION: 'eviction',
};

export const SCREENING_POLL_INTERVAL = 2000;
