import { useMemo, useCallback, useState } from 'react';
import KpiService from '../../services/kpiService';
import { useQuery } from 'react-query';
import {
  useTableFilterSortSearchManager,
  useTableManageColumns,
  useTableFilterSortData,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { formatPercentage } from '@fortress-technology-solutions/fortress-component-library/utils/index';
import { getHeaders, getPropertyPathMap } from './constantsV2';
import {
  calculateSumOfEachField,
  mapValuesByField,
  weightedAverage,
} from './utils';
import * as api from '../../utils/api';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useUniqueTableName from '../../hooks/useUniqueTableName';
import useComplianceOverviewQuery from '../../hooks/data-fetching/useComplianceOverviewQuery';
import { COMPLIANCE_TYPES } from '../ComplianceOverview/constants';
import useGetPastDueCertsPerProperty from '../ComplianceOverview/hooks.useParsePastDueCerts';
import { processTableFilterOptions } from '../../utils/table-helpers';
import camelCase from 'lodash/camelCase';

const NAME = 'portfolioOverview';
export const usePortfolio = ({ organizationId, userId = '' }) => {
  const name = useUniqueTableName(NAME);

  const {
    portfolioSummaryScreenInWholeNumbers,
    complianceOverviewReviewedAndNumberOfDays,
    propertyRoleAssignments,
    pastDueColumn,
  } = useFlags();

  const { portfolios, isPortfoliosLoading } = useFetchPortfolios({
    organizationId,
    pastDueColumn,
    propertyRoleAssignments,
  });

  const complianceType = COMPLIANCE_TYPES.NEEDS_REVIEW;
  const complianceOverview = useComplianceOverviewQuery({
    organizationId,
    complianceType,
  });

  const isLoading = isPortfoliosLoading || complianceOverview.isLoading;
  const pastDueCertsPerPropertyList = useGetPastDueCertsPerProperty({
    compalianceOverviewResults: complianceOverview.results,
    complianceType,
    complianceOverviewReviewedAndNumberOfDays,
    pastDueColumn,
  });

  const results = useParsePortfolios({
    isLoading,
    portfolios,
    pastDueCertsPerPropertyList,
  });

  const propertyRoles = useMemo(() => {
    if (!propertyRoleAssignments) return [];

    const propertyRolesArray =
      results
        .find((result) => result.propertyRoles)
        ?.propertyRoles.map((role) => role.roleName) ?? [];
    // Remove Duplicates
    return [...new Set(propertyRolesArray)];
  }, [results, propertyRoleAssignments]);

  const headers = useHeaders({
    propertyRoles,
    results,
    propertyRoleAssignments,
    pastDueColumn,
  });

  const PROPERTY_PATH_MAP = usePropertyPathMap({
    propertyRoles,
    pastDueColumn,
  });

  const {
    order,
    orderBy,
    handleSortChange,
    filterState,
    filterTypeState,
    dateState,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    searchState,
  } = useTableFilterSortSearchManager({
    name,
    initialOrderBy: 'propertyName',
    initialOrder: 'ASC',
    headers,
  });

  const sortedAndFilteredResults = useTableFilterSortData({
    results,
    order,
    orderBy,
    PROPERTY_PATH_MAP,
    searchState,
    filterState,
    filterTypeState,
    dateState,
  });

  const rows = useRows({
    sortedAndFilteredResults,
    portfolioSummaryScreenInWholeNumbers,
    pastDueColumn,
  });

  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
    handleTruncateChange,
    truncatedColumns,
  } = useTableManageColumns({
    name,
    headers,
    firstRow: rows[0],
  });

  const { sum, valuesList } = useMemo(() => {
    const sum = calculateSumOfEachField(sortedAndFilteredResults);
    const valuesList = mapValuesByField(sortedAndFilteredResults);
    return { sum, valuesList };
  }, [sortedAndFilteredResults]);

  const footerRow = useTotalRow({
    results: sortedAndFilteredResults,
    sum,
    valuesList,
    portfolioSummaryScreenInWholeNumbers,
    pastDueColumn,
  });
  const KPIData = useKPIData({
    results: sortedAndFilteredResults,
    sum,
    valuesList,
    portfolioSummaryScreenInWholeNumbers,
  });

  const { propertyId, reportId, refreshedAt } = useMemo(() => {
    const result = sortedAndFilteredResults?.length
      ? sortedAndFilteredResults[0]
      : {};

    return {
      propertyId: result.propertyId ?? '',
      reportId: result.reportId ?? '',
      refreshedAt: result.refreshedAt
        ? moment(result.refreshedAt)
            .utc()
            .add(moment().utcOffset(), 'minutes')
            .format('MM/DD/YYYY H:mmA')
        : null,
    };
  }, [sortedAndFilteredResults]);

  const {
    onCSVButtonClick,
    isCSVExportLoading,
    onPDFButtonClick,
    isPDFExportLoading,
  } = useCSVPDFExport({
    organizationId,
    propertyId,
    reportId,
    pastDueCertsData: { pastDueColumn, pastDueCertsPerPropertyList },
  });

  return {
    KPIData,
    allColumnsHidden,
    columnOptions,
    totalCount: results?.length ?? 0,
    count: rows?.length ?? 0,
    filteredHeaders,
    footerRow,
    handleColumnChange,
    handleSortChange,
    handleTruncateChange,
    isCSVExportLoading,
    isLoading,
    isPDFExportLoading,
    name,
    onCSVButtonClick,
    onPDFButtonClick,
    order,
    orderBy,
    refreshedAt,
    rows,
    selectedColumns,
    truncatedColumns,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    filterState,
    filterTypeState,
    dateState,
    searchState,
  };
};

const useFetchPortfolios = ({ organizationId }) => {
  const portfolioService = useMemo(() => new KpiService(), []);
  const queryKey = ['portfolioOverview', organizationId];
  const { data, isLoading } = useQuery(
    queryKey,
    () => {
      return portfolioService.getPortfolio(organizationId);
    },
    {
      enabled: Boolean(organizationId.length),
      onError: (e) => {},
      refetchOnWindowFocus: false,
    },
  );

  const portfolios = useMemo(() => data ?? [], [data]);

  return { portfolios, isLoading };
};
/**
 * @notes
 *  - will update the portfolios array with the pastDueCertifications value.
 * Check useGetPastDueCertsPerProperty() for more info
 *  - will parse the portfolios array to return the correct values for the table
 */
const useParsePortfolios = ({
  isLoading,
  portfolios = [],
  pastDueCertsPerPropertyList,
}) => {
  return useMemo(() => {
    if (!portfolios?.length || isLoading) return [];
    const propertiesWithCertsNotReviewedYetIds = Object.keys(
      pastDueCertsPerPropertyList,
    );

    const portfoliosToParse = pastDueCertsPerPropertyList
      ? portfolios.map((item) => {
          item.pastDueCertifications =
            propertiesWithCertsNotReviewedYetIds.includes(item.propertyId)
              ? pastDueCertsPerPropertyList[item.propertyId]
              : 0;
          return item;
        })
      : [...portfolios];

    return portfoliosToParse?.map((row) => {
      const {
        id,
        property,
        refreshedAt,
        numTotalUnits,
        numOccupiedUnits,
        numScheduledMoveIns,
        numScheduledMoveOuts,
        delinquencyPercent,
        delinquencyChargesTotal,
        mtdDeposits,
        pendingDeposits,
        periodToDateConcessions: concessions,
        numWorkOrdersOpen,
        avgWorkOrderDaysOpen,
        lastMonthConcessions,
        lastMonthCharges,
        lastMonthDeposits,
        downUnits,
        numVacantReady,
        numVacantNotReady,
        numNtvCurrentMonth,
        numNtvNextMonth,
        numGrossLeasesApproved,
        numGrossLeasesNotApproved,
        numAvailable,
        pastDueCertifications,
        propertyRoles,
      } = row;
      const roleColumns = propertyRoles?.reduce((acc, role) => {
        return {
          ...acc,
          [camelCase(role.roleName)]:
            role.assignedUser && role.assignedUser !== ''
              ? role.assignedUser
              : null,
        };
      }, {});
      const currentOccupancy = numOccupiedUnits / numTotalUnits;
      const netOccupancy =
        (numOccupiedUnits + numScheduledMoveIns - numScheduledMoveOuts) /
        numTotalUnits;
      const vacantUnits = numVacantReady + numVacantNotReady;
      return {
        id,
        propertyName: property?.name ?? '',
        propertyId: property?.id ?? '',
        reportId: property?.reports[0]?.id ?? '',
        refreshedAt,
        numTotalUnits,
        vacantUnits,
        numOccupiedUnits,
        currentOccupancy,
        netOccupancy,
        delinquencyPercent,
        delinquencyChargesTotal,
        mtdDeposits,
        pendingDeposits,
        concessions,
        numWorkOrdersOpen: numWorkOrdersOpen ? numWorkOrdersOpen : 0,
        avgWorkOrderDaysOpen: avgWorkOrderDaysOpen ? avgWorkOrderDaysOpen : 0,
        numScheduledMoveIns,
        numScheduledMoveOuts,
        lastMonthConcessions,
        lastMonthCharges,
        lastMonthDeposits,
        downUnits,
        numNtvCurrentMonth,
        numNtvNextMonth,
        numGrossLeasesApproved,
        numGrossLeasesNotApproved,
        numAvailable,
        pastDueCertifications,
        propertyRoles,
        ...roleColumns,
      };
    });
  }, [portfolios, pastDueCertsPerPropertyList]);
};
const useRows = ({
  sortedAndFilteredResults,
  portfolioSummaryScreenInWholeNumbers,
  pastDueColumn,
}) => {
  return useMemo(() => {
    return sortedAndFilteredResults?.map((result) => {
      const {
        id,
        propertyId,
        propertyName,
        numTotalUnits,
        vacantUnits,
        numOccupiedUnits,
        numScheduledMoveIns,
        numScheduledMoveOuts,
        delinquencyPercent,
        delinquencyChargesTotal,
        mtdDeposits,
        pendingDeposits,
        concessions,
        numWorkOrdersOpen,
        avgWorkOrderDaysOpen,
        numNtvCurrentMonth,
        numNtvNextMonth,
        numGrossLeasesApproved,
        numGrossLeasesNotApproved,
        numAvailable,
        pastDueCertifications,
        propertyRoles,
      } = result;

      const currentOccupancy = numOccupiedUnits / numTotalUnits;
      const netOccupancy =
        (numOccupiedUnits + numScheduledMoveIns - numScheduledMoveOuts) /
        numTotalUnits;
      const propertyRolesColumns = propertyRoles?.reduce((acc, role) => {
        return {
          ...acc,
          [camelCase(role.roleName)]: {
            value: role.assignedUser,
          },
        };
      }, {});
      const rows = {
        id,
        propertyName: {
          variant: 'link',
          to: propertyId?.length ? `/property/${propertyId}` : null,
          value: propertyName,
        },
        numTotalUnits: {
          variant: 'number',
          value: numTotalUnits,
          numberFormatOptions: { rounded: true },
        },
        numOccupiedUnits: {
          variant: 'number',
          value: numOccupiedUnits,
          numberFormatOptions: { rounded: true },
        },
        vacantUnits: {
          variant: 'number',
          value: vacantUnits,
          numberFormatOptions: { rounded: true },
        },
        currentOccupancy: {
          variant: 'number',
          value: formatPercentage(currentOccupancy),
        },
        netOccupancy: {
          variant: 'number',
          value: formatPercentage(netOccupancy),
        },
        delinquencyPercent: {
          variant: 'number',
          value: formatPercentage(delinquencyPercent),
        },
        delinquencyChargesTotal: {
          variant: 'currency',
          value: delinquencyChargesTotal,
          numberFormatOptions: { rounded: true },
        },
        mtdDeposits: {
          variant: 'currency',
          value: mtdDeposits,
          numberFormatOptions: { rounded: true },
        },
        pendingDeposits: {
          variant: 'currency',
          value: pendingDeposits,
          numberFormatOptions: { rounded: true },
        },
        concessions: {
          variant: 'currency',
          value: concessions,
          numberFormatOptions: { rounded: true },
        },
        numWorkOrdersOpen: {
          variant: 'number',
          value: numWorkOrdersOpen ?? 0,
          numberFormatOptions: { rounded: true },
        },
        avgWorkOrderDaysOpen: {
          variant: 'number',
          value: avgWorkOrderDaysOpen ?? 0,
          numberFormatOptions: { rounded: true },
        },
        numNtvCurrentMonth: {
          variant: 'number',
          value: numNtvCurrentMonth ?? 0,
          numberFormatOptions: { rounded: true },
        },
        numNtvNextMonth: {
          variant: 'number',
          value: numNtvNextMonth ?? 0,
          numberFormatOptions: { rounded: true },
        },
        numGrossLeasesApproved: {
          variant: 'number',
          value: numGrossLeasesApproved ?? 0,
          numberFormatOptions: { rounded: true },
        },
        numGrossLeasesNotApproved: {
          variant: 'number',
          value: numGrossLeasesNotApproved ?? 0,
          numberFormatOptions: { rounded: true },
        },
        numAvailable: {
          variant: 'number',
          value: numAvailable ?? 0,
          numberFormatOptions: { rounded: true },
        },
        ...(pastDueColumn
          ? {
              pastDueCertifications: {
                variant: 'number',
                value: pastDueCertifications ?? 0,
                numberFormatOptions: { rounded: true },
              },
            }
          : {}),
        ...propertyRolesColumns,
      };
      if (!portfolioSummaryScreenInWholeNumbers) {
        const updatedRows = {};
        Object.keys(rows).forEach((key) => {
          updatedRows[key] = { ...rows[key], numberFormatOptions: undefined };
        });
        return updatedRows;
      }
      return rows;
    });
  }, [
    sortedAndFilteredResults,
    portfolioSummaryScreenInWholeNumbers,
    pastDueColumn,
  ]);
};

const useTotalRow = ({
  sum,
  valuesList,
  portfolioSummaryScreenInWholeNumbers,
  pastDueColumn,
}) => {
  return useMemo(() => {
    if (!Object.keys(sum).length && !Object.keys(valuesList).length) return {};
    const totalDq =
      (sum.mtdDeposits + sum.pendingDeposits) / sum.delinquencyChargesTotal;
    const rows = {
      propertyName: '',
      numTotalUnits: {
        variant: 'number',
        value: sum.numTotalUnits,
        numberFormatOptions: { rounded: true },
      },
      numOccupiedUnits: {
        variant: 'number',
        value: sum.numOccupiedUnits,
        numberFormatOptions: { rounded: true },
      },
      vacantUnits: {
        variant: 'number',
        value: sum.vacantUnits,
        numberFormatOptions: { rounded: true },
      },
      currentOccupancy: {
        variant: 'number',
        value: formatPercentage(sum.numOccupiedUnits / sum.numTotalUnits),
      },
      netOccupancy: {
        variant: 'number',
        value: formatPercentage(
          weightedAverage(valuesList.netOccupancy, valuesList.numTotalUnits),
        ),
      },
      delinquencyPercent: {
        variant: 'number',
        value: Number.isFinite(totalDq) ? formatPercentage(totalDq) : '---%',
      },
      delinquencyChargesTotal: {
        variant: 'currency',
        value: sum.delinquencyChargesTotal,
        numberFormatOptions: { rounded: true },
      },
      mtdDeposits: {
        variant: 'currency',
        value: sum.mtdDeposits,
        numberFormatOptions: { rounded: true },
      },
      pendingDeposits: {
        variant: 'currency',
        value: sum.pendingDeposits,
        numberFormatOptions: { rounded: true },
      },
      concessions: {
        variant: 'currency',
        value: sum.concessions,
        numberFormatOptions: { rounded: true },
      },
      numWorkOrdersOpen: {
        variant: 'number',
        value: Number(sum.numWorkOrdersOpen),
        numberFormatOptions: { rounded: true },
      },
      avgWorkOrderDaysOpen: {
        variant: 'number',
        value: weightedAverage(
          valuesList.avgWorkOrderDaysOpen,
          valuesList.numWorkOrdersOpen,
        ).toFixed(2),
        numberFormatOptions: { rounded: true },
      },
      numNtvCurrentMonth: {
        variant: 'number',
        value: Number(sum.numNtvCurrentMonth),
        numberFormatOptions: { rounded: true },
      },
      numNtvNextMonth: {
        variant: 'number',
        value: Number(sum.numNtvNextMonth),
        numberFormatOptions: { rounded: true },
      },
      numGrossLeasesApproved: {
        variant: 'number',
        value: Number(sum.numGrossLeasesApproved),
        numberFormatOptions: { rounded: true },
      },
      numGrossLeasesNotApproved: {
        variant: 'number',
        value: Number(sum.numGrossLeasesNotApproved),
        numberFormatOptions: { rounded: true },
      },
      numAvailable: {
        variant: 'number',
        value: Number(sum.numAvailable),
        numberFormatOptions: { rounded: true },
      },
      ...(pastDueColumn
        ? {
            pastDueCertifications: {
              variant: 'number',
              value: Number(sum.pastDueCertifications),
              numberFormatOptions: { rounded: true },
            },
          }
        : {}),
    };
    if (!portfolioSummaryScreenInWholeNumbers) {
      const updatedRows = {};
      Object.keys(rows).forEach((key) => {
        updatedRows[key] = { ...rows[key], numberFormatOptions: undefined };
      });
      return updatedRows;
    }
    return rows;
  }, [sum, valuesList, portfolioSummaryScreenInWholeNumbers, pastDueColumn]);
};
const useKPIData = ({
  sum,
  valuesList,
  portfolioSummaryScreenInWholeNumbers,
}) => {
  return useMemo(() => {
    const netOccupancyChange =
      sum.numScheduledMoveIns - sum.numScheduledMoveOuts;
    const totalDq =
      (sum.mtdDeposits + sum.pendingDeposits) / sum.delinquencyChargesTotal;
    const values = {
      occupancy: {
        totalUnits: sum.numTotalUnits,
        occupiedUnits: sum.numOccupiedUnits,
        vacantUnits: sum.vacantUnits,
        unOccupiedUnits: sum.numTotalUnits - sum.numOccupiedUnits,
        percentage: formatPercentage(sum.numOccupiedUnits / sum.numTotalUnits),
      },
      netOccupancy: {
        occupiedUnits: sum.numOccupiedUnits + netOccupancyChange,
        unOccupiedUnits:
          sum.numTotalUnits - sum.numOccupiedUnits - netOccupancyChange,
        netOccupancy: sum.netOccupancy,
        totalUnits: sum.numTotalUnits,
        percentage: formatPercentage(
          weightedAverage(valuesList.netOccupancy, valuesList.numTotalUnits),
        ),
        moveIns: sum.numScheduledMoveIns,
        moveOuts: sum.numScheduledMoveOuts,
      },
      income: {
        charges: sum.delinquencyChargesTotal,
        deposits: sum.mtdDeposits,
        pendingDeposits: sum.pendingDeposits,
        depositsPercentOfCharges: Number.isFinite(totalDq)
          ? formatPercentage(totalDq)
          : '---%',
        lastPeriodCharges: sum.lastMonthCharges,
        lastPeriodDeposits: sum.lastMonthDeposits,
        numberFormatOptions: { rounded: true },
      },
      concessions: {
        concessions: sum.concessions,
        lastPeriodConcessions: sum.lastMonthConcessions,
        numberFormatOptions: { rounded: true },
      },
      workOrders: {
        workOrdersOpen: sum.numWorkOrdersOpen,
        avgWorkOrderDaysOpen: `${weightedAverage(
          valuesList.avgWorkOrderDaysOpen,
          valuesList.numWorkOrdersOpen,
        ).toFixed(2)}`,
      },
      noticeToVacates: {
        currentMonth: sum.numNtvCurrentMonth,
        nextMonth: sum.numNtvNextMonth,
      },
      leasing: {
        numGrossLeasesApproved: sum.numGrossLeasesApproved,
        numGrossLeasesNotApproved: sum.numGrossLeasesNotApproved,
        numAvailable: sum.numAvailable,
      },
    };
    if (!portfolioSummaryScreenInWholeNumbers) {
      const updatedValues = {};
      Object.keys(values).forEach((key) => {
        updatedValues[key] = { ...values[key], numberFormatOptions: undefined };
      });
      return updatedValues;
    }
    return values;
  }, [sum, valuesList, portfolioSummaryScreenInWholeNumbers]);
};

const useCSVPDFExport = ({
  organizationId,
  propertyId,
  reportId,
  pastDueCertsData: { pastDueColumn, pastDueCertsPerPropertyList },
}) => {
  const kpiService = new KpiService();
  const [isCSVExportLoading, setIsCSVExportLoading] = useState(false);
  const [isPDFExportLoading, setIsPDFExportLoading] = useState(false);
  const hasParams =
    organizationId?.length && propertyId?.length && reportId?.length;

  const onCSVButtonClick = useCallback(async () => {
    setIsCSVExportLoading(true);
    if (pastDueColumn) {
      await kpiService.updatePastDueCertificationsColumn(organizationId, {
        pastDueCertsData: pastDueCertsPerPropertyList,
      });
    }
    await api.getDownload(
      `/${organizationId}/${propertyId}/csv-reports/${reportId}?offset=${moment().utcOffset()}`,
      `Portfolio_Summary_${moment().format('YYYYMMDD')}.csv`,
    );
    setIsCSVExportLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    organizationId,
    propertyId,
    reportId,
    pastDueColumn,
    pastDueCertsPerPropertyList,
  ]);

  const onPDFButtonClick = useCallback(async () => {
    setIsPDFExportLoading(true);
    if (pastDueColumn) {
      await kpiService.updatePastDueCertificationsColumn(organizationId, {
        pastDueCertsData: pastDueCertsPerPropertyList,
      });
    }
    await api.getDownload(
      `/${organizationId}/${propertyId}/pdf-reports/${reportId}?offset=${moment().utcOffset()}`,
      `Portfolio_Summary_${moment().format('YYYYMMDD')}.pdf`,
    );
    setIsPDFExportLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    organizationId,
    propertyId,
    reportId,
    pastDueColumn,
    pastDueCertsPerPropertyList,
  ]);

  return hasParams
    ? {
        onCSVButtonClick,
        isCSVExportLoading,
        onPDFButtonClick,
        isPDFExportLoading,
      }
    : {};
};

export const useHeaders = ({
  propertyRoles,
  results,
  propertyRoleAssignments,
  pastDueColumn,
}) => {
  const filterOptions = useBuildFilterOptions({
    results,
    propertyRoles,
  });
  return useMemo(() => {
    const headers = getHeaders({
      propertyRoles,
      propertyRoleAssignments,
      pastDueColumn,
    });
    const headersWithFilterOptions = headers.map((header) => ({
      ...header,
      filterOptions: filterOptions[header.id] ?? null,
    }));
    return headersWithFilterOptions;
  }, [propertyRoles, filterOptions, propertyRoleAssignments, pastDueColumn]);
};

export const usePropertyPathMap = ({
  propertyRoles,
  propertyRoleAssignments,
  pastDueColumn,
}) => {
  return useMemo(
    () =>
      getPropertyPathMap({
        propertyRoles,
        propertyRoleAssignments,
        pastDueColumn,
      }),
    [propertyRoles, propertyRoleAssignments, pastDueColumn],
  );
};

export const useBuildFilterOptions = ({ results, propertyRoles }) => {
  return useMemo(() => {
    const options = propertyRoles.reduce((acc, role) => {
      return {
        ...acc,
        [camelCase(role)]: [],
      };
    }, {});

    if (results) {
      results.forEach((result) => {
        propertyRoles.forEach((role) => {
          if (result[camelCase(role)]) {
            options[camelCase(role)].push(result[camelCase(role)]);
          }
        });
      });
    }

    processTableFilterOptions(options);
    return options;
  }, [results, propertyRoles]);
};
