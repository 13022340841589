import { FormattedMessage } from 'react-intl';

// Components
import { Typography } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { SetupSwitch } from '../shared';
import ProgramPassbookRate from '../ProgramPassbookRate';
import SetupGridItem from '../../../SetupGridItem';
import { AnnualRecertMonthPicker } from '../shared';

// Constants
import messages from './messages';
import messagesAffordableSetup from '../../messages';

import type { PassbookRate } from '../shared';

interface ProgramFlags {
  initialCertsRequired: boolean;
  hasHUDProgramOnly: boolean;
  rolloverIncomeAndAssets: boolean;
  affordableTransfers: boolean;
}

interface ProgramValues {
  stateId: string;
  projectIdTic: string;
  XMLPropertyName: string;
  XMLProjectName: string;
  requiresInitialCertificationForNonHUD: boolean;
  isOverrideQualificationEnabled: boolean;
  autoRolloverIncomeAndAssets: boolean;
  interimCert: boolean;
  flags: ProgramFlags;
  passbookRates: Array<PassbookRate>;
  annualRecertMonth?: Object;
}

interface ProgramConfig {
  programName: string; // e.g., 'Deffered Loan', 'NOAH', etc.
  titleMessage: any; // FormattedMessage reference
  stateIdPlaceholder?: string;
  showInterimCerts?: boolean;
  showOverrideQualification?: boolean;
  showAltXMLNames?: boolean;
  showAnnualRecertMonth?: boolean;
}

type Props = {
  promptToaster: Function,
  isLoadingPassbookRateData: boolean,
  editMode: boolean,
  onChange: Function,
  values: ProgramValues,
  config: ProgramConfig,
};

export const StateProgramSetup = ({
  editMode,
  onChange,
  values,
  promptToaster,
  isLoadingPassbookRateData,
  config,
}: Props) => {
  const {
    requiresInitialCertificationForNonHUD,
    isOverrideQualificationEnabled,
    autoRolloverIncomeAndAssets,
    interimCert,
    annualRecertMonth,
    flags: {
      initialCertsRequired,
      hasHUDProgramOnly,
      rolloverIncomeAndAssets,
      affordableTransfers,
    },
    passbookRates,
  } = values;

  const {
    programName,
    titleMessage,
    showInterimCerts = true,
    showOverrideQualification = true,
    showAnnualRecertMonth = true,
  } = config;

  const handleAnnualRecertMonthChange = (date: Object, validate: Object) => {
    onChange({
      target: { name: `setup.${programName}AnnualRecertMonth`, value: date },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={'h3'}>
          <FormattedMessage {...titleMessage} />
        </Typography>
      </Grid>

      {!hasHUDProgramOnly && initialCertsRequired && (
        <SetupGridItem
          label={
            <FormattedMessage
              {...messages.initialCertForNonHUD}
              values={{
                programName: programName,
              }}
            />
          }
          TooltipProps={{
            title: (
              <FormattedMessage
                {...messages.initialCertForNonHUDTip}
                values={{
                  programName: programName,
                }}
              />
            ),
          }}
        >
          <SetupSwitch
            switchId={`${programName.toLowerCase()}initial-cert-for-non-HUD-switch`}
            editMode={editMode}
            name={`setup.requiresInitialCertificationFor${programName}`}
            disabled={!editMode}
            value={requiresInitialCertificationForNonHUD}
            onChange={onChange}
          />
        </SetupGridItem>
      )}

      {affordableTransfers && showInterimCerts && (
        <SetupGridItem label={<FormattedMessage {...messages.interimCerts} />}>
          <SetupSwitch
            switchId={`${programName.toLowerCase()}interim-certs-switch`}
            editMode={editMode}
            name={`setup.interimCert${programName}`}
            disabled={!editMode}
            value={interimCert}
            onChange={onChange}
          />
        </SetupGridItem>
      )}

      {showOverrideQualification && (
        <SetupGridItem
          label={<FormattedMessage {...messages.overrideQualification} />}
        >
          <SetupSwitch
            switchId={`${programName.toLowerCase()}-override-qualification-switch`}
            editMode={editMode}
            name={`setup.isOverrideQualificationEnabled${programName}`}
            disabled={!editMode}
            value={isOverrideQualificationEnabled}
            onChange={onChange}
          />
        </SetupGridItem>
      )}

      {showAnnualRecertMonth && (
        <SetupGridItem
          label={
            <FormattedMessage
              {...messagesAffordableSetup.annualRecertMonth}
              values={{ program: programName }}
            />
          }
        >
          <AnnualRecertMonthPicker
            handleAnnualRecertMonthChange={handleAnnualRecertMonthChange}
            editMode={editMode}
            programName={programName}
            value={annualRecertMonth}
          />
        </SetupGridItem>
      )}

      {rolloverIncomeAndAssets && (
        <>
          <Grid item xs={12} sm={6} />
          <SetupGridItem
            label={
              <FormattedMessage
                {...messagesAffordableSetup.autoRolloverIncomeAndAssets}
              />
            }
            TooltipProps={{
              title: (
                <FormattedMessage
                  {...messagesAffordableSetup.autoRolloverIncomeAndAssetsDescription}
                />
              ),
            }}
          >
            <SetupSwitch
              switchId={`${programName.toLowerCase()}-auto-rollover-income-and-assets-switch`}
              editMode={editMode}
              name={`setup.${programName}AutoRolloverIncomeAndAssets`}
              disabled={!editMode}
              value={autoRolloverIncomeAndAssets}
              onChange={onChange}
            />
          </SetupGridItem>
        </>
      )}

      <Grid item xs={12}>
        <ProgramPassbookRate
          key={`${programName}_PassbookRates`}
          programName={programName}
          isLoadingPassbookRateData={isLoadingPassbookRateData}
          passbookRates={passbookRates}
        />
      </Grid>
    </Grid>
  );
};
