import { get, post } from '../utils/api';

export default class RdBudgetFormService {
  async upsertRdBudgetForm(data) {
    return await post('/rd-budget-form', JSON.stringify(data));
  }
  async getRdBudgetForm(rentForecastId) {
    return await get(`/rd-budget-form/${rentForecastId}`);
  }
}
