import {
  comparator,
  find,
  isEmpty,
  isNil,
  lt,
  omit,
  pathEq,
  pathOr,
  pick,
  propEq,
  sort,
} from 'ramda';
import differenceBy from 'lodash/differenceBy';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import { ASSET_ROW_NAMES } from '../../QualificationForms/AssetInformationForm/constants';
import { INCOME_ROW_NAMES } from '../../QualificationForms/IncomeVerificationForm/constants';
import { getCurrentNonOptionalCharge } from '../../../utils/affordable';
import type {
  AffordableTabsDisplayConfig,
  FilterAffordableFormsOptions,
} from './types';
import { AFFORDABLE_PROGRAMS } from '../../../constants/affordableProgramNames';
export const DEFAULT_OPTION = { value: '', text: 'Choose', disabled: true };

const CERTIFICATION_TYPE_MOVE_IN = 'MOVE_IN';
const CERTIFICATION_TYPE_MOVE_OUT = 'MOVE_OUT';
const CERTIFICATION_TYPE_TERMINATION = 'TERMINATION';
const CERTIFICATION_TYPE_INTERIM = 'INTERIM';
const CERTIFICATION_TYPE_GROSS_RENT = 'GROSS_RENT';

export const ENUM_COMPLIANCE_APPROVAL_HISTORY_ACTIONS = {
  SUBMISSION_PACKET_UPLOAD: 'SUBMISSION_PACKET_UPLOAD',
  HOUSEHOLD_COMPOSITION_CHANGED: 'HOUSEHOLD_COMPOSITION_CHANGED',
  UNIT_UNASSIGNED: 'UNIT_UNASSIGNED',
};

export const HINGE_ADMIN_NAME = 'Hinge Admin';

const GUARANTOR_LEASE_SIGNER = 'Guarantor Lease Signer';
/**
 * Utils native to the functionality of the Qualification Tab
 */
const parseDropDownOption = ({ id, code, name }) => ({
  value: id,
  text: name || code,
});

export const parseDropdownOptions = (
  arrOfUnparsedOptions: Array<Object>,
  parseFn: Function = parseDropDownOption,
  allowDefault?: boolean,
): Array<Object> => {
  const parsedOptions = arrOfUnparsedOptions.map(parseFn);
  return [
    allowDefault
      ? { ...DEFAULT_OPTION, value: null, disabled: false }
      : DEFAULT_OPTION,
  ].concat(parsedOptions);
};

export const parseProgramDocumentTypesForDropDown = (
  affordableProgramDocTypes: Array<Object>,
): Array<Object> => {
  const documentTypes = affordableProgramDocTypes.map(
    ({ documentType }) => documentType,
  );
  return parseDropdownOptions(documentTypes);
};

const parseDropDownOptionForCompliancePacket = (packet: Object) => {
  const { id, code, name, isFinalPacket, masterAffordableProgram } = packet;
  const masterAffordableProgramId = pathOr(
    null,
    ['id'],
    masterAffordableProgram,
  );
  return {
    value: id,
    text: name || code,
    isFinalPacket,
    masterAffordableProgramId,
  };
};

export const parseProgramDocumentTypesForFinalPacket = (
  affordableProgramDocTypes: Array<Object>,
): Array<Object> => {
  const documentTypes = affordableProgramDocTypes
    .filter(({ isFinalPacket, needsReview }) => isFinalPacket || !needsReview)
    .map(({ documentType, isFinalPacket, masterAffordableProgram }) => ({
      ...documentType,
      isFinalPacket,
      masterAffordableProgram,
    }));

  return documentTypes.map(parseDropDownOptionForCompliancePacket);
};

export const generateUtilityAllowancesDropdownOptions = (
  floorPlanUtilityAllowances: Array<Object>,
): Array<Object> => {
  /**
   * The values that we receive here are at the floorplan level. Because
   * multiple floorplans might share the same utility allowance (misnamed as
   * publicHousingAuthority below) we have to create an object keyed to the
   * utility allowance IDs in order to get the individual utility allowance
   * programs.
   */
  if ((floorPlanUtilityAllowances?.length ?? 0) < 1) {
    return [DEFAULT_OPTION];
  }

  const allowances = floorPlanUtilityAllowances
    .filter(
      (floorPlanUtilityAllowance) =>
        floorPlanUtilityAllowance?.allowances?.pha?.id,
    ) // keep only those with pha IDs
    .filter(function uniqueAllowanceIdsOnly(floorPlanUtilityAllowance) {
      const publicHousingAuthorityId =
        floorPlanUtilityAllowance.allowances.pha.id;
      return (
        !this.has(publicHousingAuthorityId) &&
        this.add(publicHousingAuthorityId)
      );
    }, new Set()) // keep only unique allowances IDs
    .map((floorPlanUtilityAllowance) => {
      const text = floorPlanUtilityAllowance.allowances.pha.name ?? '---';
      const value = floorPlanUtilityAllowance.allowances.pha.id;
      return { text, value };
    }); // create objects for dropdown
  return [DEFAULT_OPTION, ...allowances];
};

export const parseDropdownsOptionsWithCodesAndNames = (
  arrOfUnparsedOptions: Array<Object>,
): Array<Object> => {
  const parsedOptions = arrOfUnparsedOptions.map(({ id, code, name }) => ({
    value: id,
    text: `${code} - ${name}`,
  }));
  return [DEFAULT_OPTION].concat(parsedOptions);
};

export const parseApplicantForms = (
  applicantFormIds: Array<string>,
  affordableForms: Array<Object>,
) => {
  if (!applicantFormIds || !affordableForms) {
    return [];
  }
  return applicantFormIds.reduce((forms, id) => {
    const form = find(pathEq(['document', 'id'], id))(affordableForms);
    return form ? [...forms, form] : forms;
  }, []);
};

export const parseInitialFormsModalValues = (applicantForms: Array<Object>) => {
  return applicantForms.reduce((initialValues, form) => {
    const documentId = pathOr(null, ['document', 'id'], form);
    if (documentId) {
      return {
        ...initialValues,
        [documentId]: true,
      };
    }
    return initialValues;
  }, {});
};

const ticOptionMapper = (option) => {
  const name = pathOr('', ['name'], option);
  return {
    text: name,
    value: name,
  };
};

const ticSourceFilter = (sourceName: string) => (doc: Object) =>
  doc.source === sourceName && doc.type === 'TIC';

export const getTICOptions = (
  documentTypes: Array<Object>,
  selectedProperty: Object,
): Array<Object> => {
  const state = pathOr('', ['physicalState'], selectedProperty).slice(-2);
  const propertyName = pathOr('', ['name'], selectedProperty);
  const defaultTICOption = { value: '', text: 'Choose', disabled: true };

  if (!documentTypes) {
    return [defaultTICOption];
  }

  const propertySourcedTICDocs = documentTypes.filter(
    ticSourceFilter(propertyName),
  );

  if (propertySourcedTICDocs.length > 0) {
    const propertySourcedOptions = propertySourcedTICDocs.map(ticOptionMapper);
    return [defaultTICOption, ...propertySourcedOptions];
  }

  const stateSourcedTICOptions = documentTypes
    .filter(ticSourceFilter(state))
    .map(ticOptionMapper);
  return [defaultTICOption, ...stateSourcedTICOptions];
};

export const parseInitialHouseholdValues = (
  qualTabInitialValues: Object,
  fpNonOptionalCharges: Array<Object> = [],
) => {
  const receivingAssistanceValue = pathOr(
    null,
    ['receivingAssistance'],
    qualTabInitialValues,
  );
  const receivingAssistance =
    receivingAssistanceValue !== null
      ? receivingAssistanceValue.toString()
      : null;
  const residentPayment = pathOr(
    0,
    ['residentPayment'],
    qualTabInitialValues,
  ).toFixed(2);
  const hapPayment = pathOr(0, ['hapPayment'], qualTabInitialValues).toFixed(2);
  const voucherEffectiveDate = qualTabInitialValues.voucherEffectiveDate
    ? moment(qualTabInitialValues.voucherEffectiveDate)
    : null;
  const specialNeedsDesignationIds =
    qualTabInitialValues?.specialNeedsDesignationIds ?? [];
  const nonOptionalCharge = (
    qualTabInitialValues?.nonOptionalCharge ??
    getCurrentNonOptionalCharge(fpNonOptionalCharges)
  ).toFixed(2);

  const initialHouseholdValues = {
    ...omit(['affordableDocuments'], qualTabInitialValues),
    receivingAssistance,
    voucherEffectiveDate,
    residentPayment,
    hapPayment,
    specialNeedsDesignationIds,
    nonOptionalCharge,
  };
  return initialHouseholdValues;
};

export const frMemberChecklistFormsComplete = (
  checklists: Array<Object>,
  applicantId: string,
  affordableQualificationId: string,
) => {
  const qualificationChecklists = checklists || [];
  const applicantChecklists = qualificationChecklists.filter((chklist) => {
    return pathOr(null, ['applicantId'], chklist) === applicantId;
  });
  const applicantQualificationChecklist = find(
    propEq('affordableQualificationId', affordableQualificationId),
  )(applicantChecklists);
  if (
    !applicantQualificationChecklist ||
    !applicantQualificationChecklist.assetChecklist ||
    !applicantQualificationChecklist.incomeChecklist
  ) {
    return false;
  }
  const notCompleteAssetRows = ASSET_ROW_NAMES.filter(
    (rowName) => !applicantQualificationChecklist.assetChecklist[rowName],
  );

  const checklistCreatedAt = pathOr(
    '',
    ['createdAt'],
    applicantQualificationChecklist,
  );

  // TODO: Remove. This is a hack for when SSI went live.
  const requiredFields = moment(checklistCreatedAt).isAfter(
    moment('2021-02-09'),
  )
    ? INCOME_ROW_NAMES
    : INCOME_ROW_NAMES.filter((name) => name !== 'supplementalSecurity');

  const notCompleteIncomeRows = requiredFields.filter(
    (rowName) => !applicantQualificationChecklist.incomeChecklist[rowName],
  );
  const notCompleteRows = notCompleteAssetRows.concat(notCompleteIncomeRows);

  return notCompleteRows.length === 0;
};

export const checkAllChecklistFormsComplete = (
  frMembers: Array<Object>,
  checklists: Array<Object>,
  affordableQualificationId: string,
  residentId: ?string,
  certificationType?: string,
  isTerminationOrMoveOut?: boolean,
) => {
  const incomeAndAssetsComplete = frMembers
    .map(({ id }) =>
      frMemberChecklistFormsComplete(checklists, id, affordableQualificationId),
    )
    .every((memberComplete) => memberComplete === true);

  if (
    !residentId ||
    isTerminationOrMoveOut ||
    certificationType === CERTIFICATION_TYPE_MOVE_IN
  ) {
    return incomeAndAssetsComplete;
  }

  const recertsComplete = frMembers
    .map((member) => {
      const recerts = pathOr([], ['recerts'], member);
      const recertForm = find(
        propEq('affordableQualificationId', affordableQualificationId),
      )(recerts);
      return pathOr(false, ['isComplete'], recertForm);
    })
    .every((recertComplete) => recertComplete === true);

  return incomeAndAssetsComplete && recertsComplete;
};

export const checkAllRecertFormsComplete = (frMembers: Array<Object>) =>
  frMembers.map(({ recertComplete }) => recertComplete).includes(false);

export const parseResidentQualificationValuesForSubmittal = (
  formValues: Object,
) => {
  const { HHincomeAtMoveIn, HHnumOccupantsAtMoveIn, priorMasterHUDSubsidyId } =
    formValues;
  if (HHincomeAtMoveIn || HHnumOccupantsAtMoveIn) {
    formValues = {
      ...formValues,
      HHmoveInInfoManual: true,
    };
  }
  if (priorMasterHUDSubsidyId && priorMasterHUDSubsidyId === 'Choose')
    formValues = {
      ...formValues,
      priorMasterHUDSubsidyId: null,
    };
  const toScrubArray = ['HHincomeAtMoveIn', 'HHnumOccupantsAtMoveIn'].reduce(
    (scrubArr, key) => {
      return formValues[key] === '' ? [...scrubArr, key] : scrubArr;
    },
    [],
  );
  return toScrubArray.length > 0 ? omit(toScrubArray, formValues) : formValues;
};

/**
 * Utils to be used by the parent components:
 * - ApplicationProfile
 * - ResidentProfile.
 */

const MASTER_PROGRAM_PATH = [
  'propertyAffordableProgram',
  'masterAffordableProgram',
];
const PROGRAM_NAME_PATH = [...MASTER_PROGRAM_PATH, 'name'];
const PROGRAM_ID_PATH = [...MASTER_PROGRAM_PATH, 'id'];
const UNIT_PROGRAMS_PATH = ['floorPlan', 'floorPlanAffordablePrograms'];
const QUAL_TAB_PICK_VALUES = [
  'receivingAssistance',
  'publicHousingAuthority',
  'residentPayment',
  'hapPayment',
  'householdUtilityAllowanceId',
  'rentalAssistanceSourceId',
  'voucherEffectiveDate',
  'complianceApprovalId',
  'hudIncomeLimitId',
  'isComplianceReviewed',
  'affordableDocuments',
  'specialNeedsDesignationIds',
  'nonOptionalCharge',
];
const HUD_QUAL_TAB_PICK_VALUES = [
  'anticipatedVoucherDate',
  'anticipatedVoucherOverride',
  'voucherEffectiveDate',
  'dateOfDeath',
  'affordablePreviousHousingId',
  'affordableDisplacementStatusCodeId',
  'canWorkChildcareApplicantIds',
  'canWorkDisabilityAssistanceApplicantIds',
  'masterHouseholdCitizenshipEligibilityId',
  'ttpAtRadConversion',
  'masterTerminationCodeId',
  'masterMoveOutCodeId',
  'eivCompleted',
  'priorMasterHUDSubsidyId',
];
const DEFAULT_NO_PROGRAMS = {
  affordableTabs: [],
  hudTab: null,
  householdUtilityAllowanceId: null,
};

/**
 * Reduces an array of Affordable Programs down to an array of their names
 */
const getProgramNames = (programs: Array<Object>): Array<string> => {
  const programNamesArray = programs.reduce((names, program) => {
    const programName = pathOr(null, PROGRAM_NAME_PATH, program);
    return programName ? [...names, programName] : names;
  }, []);
  return programNamesArray;
};

/**
 * Parses an affordable program to the relevent initial values.
 */
const parseQualificationForTab = (qualification: Object) => {
  const affordableQualificationId = pathOr(null, ['id'], qualification);
  const certificationType = pathOr(null, ['certificationType'], qualification);
  const programName = pathOr(null, PROGRAM_NAME_PATH, qualification);
  const programId = pathOr(null, PROGRAM_ID_PATH, qualification);

  const propertyAffordableProgramId = pathOr(
    null,
    ['propertyAffordableProgramId'],
    qualification,
  );

  if (certificationType !== CERTIFICATION_TYPE_MOVE_IN) {
    const recertificationStarted = pathOr(
      null,
      ['recertificationStarted'],
      qualification,
    );

    return {
      programName,
      programId,
      affordableQualificationId,
      recertificationStarted,
      propertyAffordableProgramId,
      affordableQualification: qualification,
    };
  }

  return {
    programName,
    programId,
    affordableQualificationId,
    propertyAffordableProgramId,
    affordableQualification: qualification,
  };
};

export const parseQualificationTabInitialValues = (qualification: Object) => {
  const programName = pathOr(null, PROGRAM_NAME_PATH, qualification);
  const values =
    programName === AFFORDABLE_PROGRAMS.HUD
      ? HUD_QUAL_TAB_PICK_VALUES
      : QUAL_TAB_PICK_VALUES;
  const qualTabInitialValues = pick(values, qualification);
  const anticipatedVoucherDate = qualTabInitialValues.anticipatedVoucherDate
    ? moment(qualTabInitialValues.anticipatedVoucherDate)
    : null;
  const voucherEffectiveDate = qualTabInitialValues.voucherEffectiveDate
    ? moment(qualTabInitialValues.voucherEffectiveDate)
    : null;
  const dateOfDeath = qualTabInitialValues.dateOfDeath
    ? moment(qualTabInitialValues.dateOfDeath)
    : null;
  const eivCompleted = !isNil(qualTabInitialValues.eivCompleted)
    ? qualTabInitialValues.eivCompleted.toString()
    : null;
  return {
    ...qualTabInitialValues,
    anticipatedVoucherDate,
    voucherEffectiveDate,
    dateOfDeath,
    eivCompleted,
  };
};

/**
 * Takes an array of Qualifications and sorts them into an object keyed for the
 * two categories of cert: `hudQual` and `affordableQuals`
 */
const sortQualificationBuckets = (affordableQualifications: Array<Object>) => {
  return affordableQualifications.reduce(
    (sortedQuals, qual) => {
      const qualProgramName = pathOr('', PROGRAM_NAME_PATH, qual);
      if (qualProgramName === AFFORDABLE_PROGRAMS.HUD) {
        return { ...sortedQuals, hudQual: qual };
      }
      const affordableQuals = [...sortedQuals.affordableQuals, qual];
      return { ...sortedQuals, affordableQuals };
    },
    { hudQual: null, affordableQuals: [] },
  );
};

const getUtilityAllowanceIdFromTab = (parsedQualTab: ?Object) => {
  return pathOr(
    null,
    ['affordableQualification', 'householdUtilityAllowanceId'],
    parsedQualTab,
  );
};

/**
 * Takes an array of Qualifications that apply to the household and returns
 * their inforomation configured for display.
 */
const parseTabsForDisplay = (
  applicableQualifications: Array<Object>,
): AffordableTabsDisplayConfig => {
  const { hudQual, affordableQuals } = sortQualificationBuckets(
    applicableQualifications,
  );

  const hudTab = hudQual ? parseQualificationForTab(hudQual) : null;
  const parsedAffordableTabs = affordableQuals.map(parseQualificationForTab);

  const householdUtilityAllowanceIdHUD = getUtilityAllowanceIdFromTab(hudTab);
  if (parsedAffordableTabs.length === 0) {
    return {
      hudTab,
      affordableTabs: [],
      householdUtilityAllowanceId: householdUtilityAllowanceIdHUD,
    };
  }

  const lihtcTab = (parsedAffordableTabs ?? []).find((tab) =>
    (tab?.programName ?? '').includes(AFFORDABLE_PROGRAMS.LIHTC),
  );

  if (lihtcTab) {
    const householdUtilityAllowanceIdLIHTC =
      getUtilityAllowanceIdFromTab(lihtcTab);
    const householdUtilityAllowanceId =
      householdUtilityAllowanceIdHUD || householdUtilityAllowanceIdLIHTC;
    return { affordableTabs: [lihtcTab], hudTab, householdUtilityAllowanceId };
  }

  const ticProgram = parsedAffordableTabs[0];
  const householdUtilityAllowanceIdTIC =
    getUtilityAllowanceIdFromTab(ticProgram);
  const householdUtilityAllowanceId =
    householdUtilityAllowanceIdHUD || householdUtilityAllowanceIdTIC;
  return { affordableTabs: [ticProgram], hudTab, householdUtilityAllowanceId };
};

/**
 * Filters out the Qualifications that do not apply to the assigned unit, and
 * then parses them for display.
 */
const configureAndParseTabsForAssignedUnit = (
  affordableQualifications: Array<Object>,
  unit: Object,
): AffordableTabsDisplayConfig => {
  const floorPlanPrograms = pathOr([], UNIT_PROGRAMS_PATH, unit);
  const floorPlanProgramNames = getProgramNames(floorPlanPrograms);
  const qualsFilteredByFloorPlanPrograms = affordableQualifications.filter(
    (qual) => {
      const qualProgramName = pathOr('', PROGRAM_NAME_PATH, qual);
      return floorPlanProgramNames.includes(qualProgramName);
    },
  );

  return parseTabsForDisplay(qualsFilteredByFloorPlanPrograms);
};

/**
 * Returns the appropriately configured options for the display of the
 * the qualification tab(s) for an applicant.
 * @param affordableQualifications
 * @param unit
 * @returns {AffordableTabsDisplayConfig|{hudTab: null, affordableTabs: [], householdUtilityAllowanceId: null}}
 */
export const configureAffordableTabsDisplay = (
  affordableQualifications: Array<Object>,
  unit: ?Object,
): AffordableTabsDisplayConfig => {
  const hasAffordableQualifications =
    affordableQualifications && affordableQualifications.length > 0;

  if (!hasAffordableQualifications) {
    return DEFAULT_NO_PROGRAMS;
  }

  if (!unit && hasAffordableQualifications) {
    return parseTabsForDisplay(affordableQualifications);
  }

  if (unit && hasAffordableQualifications) {
    return configureAndParseTabsForAssignedUnit(affordableQualifications, unit);
  }

  return DEFAULT_NO_PROGRAMS;
};

export const parseMultiSelectValues = (valArray: Array<Object>) => {
  return valArray && valArray.length > 0
    ? // $FlowFixMe
      valArray.map((val: Object) => val.value)
    : null;
};

/**
 * Reduces an array of Affordable Programs down to an array of their names and
 * their propertyAffordableProgramId.
 */
const getParsedFloorPlanPrograms = (programs: Array<Object>): Array<Object> => {
  const programNamesArray = programs.reduce((parsedPrograms, program) => {
    const programName = pathOr(null, PROGRAM_NAME_PATH, program);
    const propertyAffordableProgramId = pathOr(
      null,
      ['propertyAffordableProgram', 'id'],
      program,
    );
    const programToAdd = { programName, propertyAffordableProgramId };
    return programName ? [...parsedPrograms, programToAdd] : parsedPrograms;
  }, []);
  return programNamesArray;
};

const parseResidentHUDTab = (
  affordableQualifications: Array<Object>,
  programName: string,
  propertyAffordableProgramId: string,
  tabsObject: Object,
) => {
  const hudActiveQualifications = affordableQualifications.reduce(
    (hudQualifications, currentQual) => {
      const qualProgramName = pathOr('', PROGRAM_NAME_PATH, currentQual);
      if (qualProgramName === programName) {
        return [...hudQualifications, currentQual];
      }
      return hudQualifications;
    },
    [],
  );

  if (hudActiveQualifications.length === 0) {
    return {
      ...tabsObject,
      hudTab: {
        programName,
        propertyAffordableProgramId,
        affordableQualification: {},
      },
    };
  }

  if (hudActiveQualifications.length === 1) {
    return {
      ...tabsObject,
      hudTab: parseQualificationForTab(hudActiveQualifications[0]),
    };
  }

  const moveOutCert = find(
    pathEq(['certificationType'], CERTIFICATION_TYPE_MOVE_OUT),
  )(hudActiveQualifications);

  if (moveOutCert) {
    return {
      ...tabsObject,
      hudTab: parseQualificationForTab(moveOutCert),
    };
  }

  const terminationCert = find(
    pathEq(['certificationType'], CERTIFICATION_TYPE_TERMINATION),
  )(hudActiveQualifications);

  if (terminationCert) {
    return {
      ...tabsObject,
      hudTab: parseQualificationForTab(terminationCert),
    };
  }

  const correctionCert = find(pathEq(['isCorrection'], true))(
    hudActiveQualifications,
  );

  if (correctionCert) {
    return {
      ...tabsObject,
      hudTab: parseQualificationForTab(correctionCert),
    };
  }

  const interimCert = find(
    pathEq(['certificationType'], CERTIFICATION_TYPE_INTERIM),
  )(hudActiveQualifications);

  if (interimCert) {
    return {
      ...tabsObject,
      hudTab: parseQualificationForTab(interimCert),
    };
  }

  const byCreationDate = comparator((a, b) => lt(a.createdAt, b.createdAt));
  const oldestQual = sort(byCreationDate, hudActiveQualifications)[0];

  return {
    ...tabsObject,
    hudTab: parseQualificationForTab(oldestQual),
  };
};

const parseResidentGeneralTab = (
  affordableQualifications: Array<Object>,
  programName: string,
  propertyAffordableProgramId: string,
  tabsObject: Object,
) => {
  const currAffordableTabs = pathOr([], ['affordableTabs'], tabsObject);
  const existingAffordableQualification = find(
    pathEq(PROGRAM_NAME_PATH, programName),
  )(affordableQualifications);
  if (!existingAffordableQualification) {
    const affordableTabs = [
      // $FlowFixMe
      ...currAffordableTabs,
      { programName, propertyAffordableProgramId, affordableQualification: {} },
    ];
    return { ...tabsObject, affordableTabs };
  }
  return {
    ...tabsObject,
    affordableTabs: [
      ...currAffordableTabs,
      parseQualificationForTab(existingAffordableQualification),
    ],
  };
};

/**
 * Map floorplan programs to an active Qualification. If active qualification
 * doesn't exist create empty version.
 */
const parseResidentTabs = (
  parsedfloorPlanPrograms: Array<Object>,
  affordableQualifications: Array<Object>,
  propertyAffordablePrograms: Array<Object>,
  flags: Object,
) => {
  /* A new hybrid program has been added called LIHTC/HOME, if this program
   * is present on the property, the LIHTC and HOME tabs should appear as one
   * so we need to filter out LIHTC and HOME from the floorplan programs.
   * If the LIHTC/HOME program is present, but the floorPlan only has one of
   * the programs, we should still show the proper program tab instead of the hybrid one.
   */
  const lihtcHomeProgram = propertyAffordablePrograms.find(
    (pap) => pap?.masterAffordableProgram?.name === AFFORDABLE_PROGRAMS.LIHTC,
  );
  const hasLihtcAndHomePrograms =
    !isNil(
      parsedfloorPlanPrograms.find(
        (fpap) => fpap?.programName === AFFORDABLE_PROGRAMS.LIHTC,
      ),
    ) &&
    !isNil(
      parsedfloorPlanPrograms.find(
        (fpap) => fpap?.programName === AFFORDABLE_PROGRAMS.HOME,
      ),
    );

  // Extract reopened certifications programs
  const reopenedCertsPrograms = affordableQualifications
    .filter(
      ({ certificationStatus, isActive }) =>
        isActive && certificationStatus === 'REOPEN',
    )
    .map(({ propertyAffordableProgram }) => ({
      programName: propertyAffordableProgram?.masterAffordableProgram?.name,
      propertyAffordableProgramId: propertyAffordableProgram?.id,
    }));

  // Get the propertyAffordablePrograms from the reopened certs that are not in the floorPlanPrograms
  const missingPap = differenceBy(
    reopenedCertsPrograms,
    parsedfloorPlanPrograms,
    'propertyAffordableProgramId',
  );

  const floorplanTabs = parsedfloorPlanPrograms.reduce(
    (tabsObject, program) => {
      const programName = program?.programName ?? '';
      const propertyAffordableProgramId =
        program?.propertyAffordableProgramId ?? '';

      if (programName === AFFORDABLE_PROGRAMS.HUD) {
        return parseResidentHUDTab(
          affordableQualifications,
          programName,
          propertyAffordableProgramId,
          tabsObject,
        );
      }

      const shouldApplyLayeredRules =
        hasLihtcAndHomePrograms &&
        !isNil(lihtcHomeProgram) &&
        [AFFORDABLE_PROGRAMS.LIHTC, AFFORDABLE_PROGRAMS.HOME].includes(
          programName,
        );

      if (shouldApplyLayeredRules) {
        const hasReopenedAq = !isNil(
          (affordableQualifications ?? []).find(
            ({ certificationStatus, isActive, propertyAffordableProgram }) =>
              isActive &&
              certificationStatus === 'REOPEN' &&
              propertyAffordableProgram?.masterAffordableProgram?.name ===
                programName,
          ),
        );
        const currentTabsObject = hasReopenedAq
          ? parseResidentGeneralTab(
              affordableQualifications,
              programName,
              propertyAffordableProgramId,
              tabsObject,
            )
          : tabsObject;
        return parseResidentGeneralTab(
          affordableQualifications,
          programName,
          propertyAffordableProgramId,
          currentTabsObject,
        );
      }
      return parseResidentGeneralTab(
        affordableQualifications,
        programName,
        propertyAffordableProgramId,
        tabsObject,
      );
    },
    DEFAULT_NO_PROGRAMS,
  );
  const parsedTabs = missingPap.reduce((tabsObject, program) => {
    const { programName, propertyAffordableProgramId } = program ?? {
      programName: '',
      propertyAffordableProgramId: '',
    };
    return parseResidentGeneralTab(
      affordableQualifications,
      programName,
      propertyAffordableProgramId,
      tabsObject,
    );
  }, floorplanTabs);

  let { affordableTabs, hudTab } = parsedTabs;

  affordableTabs = affordableTabs.map((tab) => {
    const urlProgramName = (tab.programName ?? '').replace(/\//g, '_');

    return {
      ...tab,
      urlProgramName,
    };
  });

  // Combine program names for non-HUD/RD programs
  const combinedAffordableTabs = affordableTabs.reduce((acc, tab) => {
    if (
      tab.programName !== AFFORDABLE_PROGRAMS.HUD &&
      tab.programName !== AFFORDABLE_PROGRAMS.RD
    ) {
      const combinedName = propertyAffordablePrograms
        // do not combine HUD/RD programs
        .filter(
          (p) =>
            ![AFFORDABLE_PROGRAMS.HUD, AFFORDABLE_PROGRAMS.RD].includes(
              p.masterAffordableProgram.name,
            ),
        )
        .map((p) => p.masterAffordableProgram.name)
        .join(' / ');

      return [
        ...acc,
        {
          ...tab,
          combinedName,
        },
      ];
    }
    return [...acc, tab];
  }, []);

  const resultTabs = uniqBy(
    combinedAffordableTabs,
    'propertyAffordableProgramId',
  );

  const householdUtilityAllowanceIdHUD = hudTab
    ? getUtilityAllowanceIdFromTab(hudTab)
    : null;
  const householdUtilityAllowanceIdGeneral =
    resultTabs.length > 0 ? getUtilityAllowanceIdFromTab(resultTabs[0]) : null;
  const householdUtilityAllowanceId =
    householdUtilityAllowanceIdHUD || householdUtilityAllowanceIdGeneral;

  return {
    ...parsedTabs,
    householdUtilityAllowanceId,
    affordableTabs: uniqBy(resultTabs, 'propertyAffordableProgramId'),
  };
};

/**
 * Returns the appropriately configured options for the display of the
 * the qualification tab(s) for a resident.
 */
export const configureResidentApplicantAffordableTabsDisplay = (
  affordableQualifications: Array<Object>,
  property: Object,
  unit: Object,
  flags: Object,
  isApplicant?: boolean,
): AffordableTabsDisplayConfig => {
  /**
   * Determine Affordable programs that apply to the floorplan
   */
  const propertyAffordablePrograms = property?.pap ?? [];
  let floorPlanPrograms = pathOr([], UNIT_PROGRAMS_PATH, unit);
  if (isApplicant && isNil(unit)) {
    const hasLIHTCProgram = !isNil(
      affordableQualifications.find((aq) =>
        (
          aq?.propertyAffordableProgram?.masterAffordableProgram?.name ?? ''
        ).includes(AFFORDABLE_PROGRAMS.LIHTC),
      ),
    );
    floorPlanPrograms = affordableQualifications.reduce((acc, aq) => {
      if (!isNil(aq.propertyAffordableProgram)) {
        if (
          (hasLIHTCProgram &&
            [AFFORDABLE_PROGRAMS.HUD, AFFORDABLE_PROGRAMS.LIHTC].includes(
              aq?.propertyAffordableProgram?.masterAffordableProgram?.name,
            )) ||
          !hasLIHTCProgram
        ) {
          return [
            ...acc,
            {
              propertyAffordableProgram: aq.propertyAffordableProgram,
            },
          ];
        }
        return acc;
      }
      return acc;
    }, []);
  }
  const parsedfloorPlanPrograms = getParsedFloorPlanPrograms(floorPlanPrograms);

  const nonGrossRentAffordableQualifications = affordableQualifications.filter(
    (aq) => aq.certificationType !== CERTIFICATION_TYPE_GROSS_RENT,
  );

  const qualificationsToParse = isApplicant
    ? nonGrossRentAffordableQualifications
    : nonGrossRentAffordableQualifications.filter((aq) => aq.isActive);

  return parseResidentTabs(
    parsedfloorPlanPrograms,
    qualificationsToParse,
    propertyAffordablePrograms,
    flags,
  );
};

export const getRecertDueDate = (
  recerts: Array<Object>,
  affordableProgram: string,
): string => {
  if (isEmpty(recerts) || isNil(recerts)) return '';
  return recerts.reduce((acc, program) => {
    const programName = pathOr('', ['pap', 'map', 'name'], program);
    if (programName === affordableProgram) {
      return program.recertDueDate;
    }
    return acc;
  }, '');
};

export const getAllNonGuarantorFRMembers = (
  frMembers: Array<Object>,
): Array<Object> => {
  return frMembers.filter((member) => member?.type !== GUARANTOR_LEASE_SIGNER);
};

export const getAffordableMembers = (members) => {
  return members
    .filter(
      // remove pets, guarantors, live-in-caretakers, and children with joint custody under 50%
      (applicant) =>
        applicant?.applicantType?.name !== 'Pet' &&
        applicant?.applicantType?.name !== 'Guarantor Lease Signer' &&
        applicant?.affordableRelationship?.name !== 'Live-in Caretaker' &&
        applicant?.applicantMinor?.jointCustody !== 'yesLessThan50',
    )
    .map((applicant) => ({
      id: applicant.id,
      name: applicant?.name ?? '',
      relationship: applicant?.affordableRelationship?.name ?? '',
    }));
};

export const getNonAffordableMembers = (members) => {
  return members
    .filter(
      (applicant) =>
        applicant?.applicantType?.name !== 'Pet' &&
        (applicant?.applicantType?.name === 'Guarantor Lease Signer' ||
          applicant?.affordableRelationship?.name === 'Live-in Caretaker' ||
          applicant?.applicantMinor?.jointCustody === 'yesLessThan50'),
    )
    .map((applicant) => {
      let tooltip = '';
      if (applicant?.applicantType.name === 'Guarantor Lease Signer') {
        tooltip = 'Guarantor Lease Signer';
      } else if (
        applicant?.affordableRelationship?.name === 'Live-in Caretaker'
      ) {
        tooltip = 'Live in Caretaker';
      } else if (applicant?.applicantMinor?.jointCustody === 'yesLessThan50') {
        tooltip = 'Child with Joint Custody under 50%';
      }

      return {
        id: applicant.id,
        name: applicant?.name ?? '',
        relationship: applicant?.affordableRelationship?.name ?? '',
        tooltip,
      };
    });
};

export const filterAffordableForms = (
  forms: Array<Object>,
  options: FilterAffordableFormsOptions,
): Array<Object> => {
  const { isAffordableMixed = false, state = '', programName } = options;
  return forms.filter((form) => {
    if (state?.includes('AR')) {
      const isLIHTCHOME = [
        AFFORDABLE_PROGRAMS.LIHTC,
        AFFORDABLE_PROGRAMS.HOME,
        AFFORDABLE_PROGRAMS['LIHTC/HOME'],
      ].includes(programName);
      const isARStateRequired =
        form?.type === 'State Required' && form?.source === 'AR';
      const shouldShow = isARStateRequired
        ? isLIHTCHOME && isAffordableMixed
        : true;
      return shouldShow;
    }

    // default
    return true;
  });
};

export default {
  frMemberChecklistFormsComplete,
  checkAllChecklistFormsComplete,
  checkAllRecertFormsComplete,
  getTICOptions,
  parseApplicantForms,
  parseInitialHouseholdValues,
  parseInitialFormsModalValues,
  parseDropdownOptions,
  parseQualificationTabInitialValues,
  parseResidentQualificationValuesForSubmittal,
  generateUtilityAllowancesDropdownOptions,
  parseMultiSelectValues,
  getAffordableMembers,
  getNonAffordableMembers,
  filterAffordableForms,
};
