import ApplicationService from '../../services/applicationService';
// eslint-disable-next-line max-len
import type { ConfirmProps } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress/Modal/Modal.types';
import messages from './messages';
import useIntl from '../../hooks/useIntl';

export function convertToApplicant(data, organizationId) {
  const applicationService = new ApplicationService();

  return applicationService.save(data, organizationId);
}

export function useModalConfirmProps(
  open: boolean,
  onClick: (confirm: boolean) => void,
): ConfirmProps {
  const intl = useIntl();
  const actionsProps = [
    { children: 'No', onClick: () => onClick(false) },
    { children: 'Yes', onClick: () => onClick(true) },
  ];

  return {
    open,
    actionsProps,
    title: intl.formatMessage(messages.convertConfirmation),
  };
}
