import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.BasicInformationSection.Title',
    defaultMessage: 'Basic Information',
  },
  firstNameLabel: {
    id: 'App.BasicInformationSection.FirstNameLabel',
    defaultMessage: 'Legal First Name',
  },
  middleNameLabel: {
    id: 'App.BasicInformationSection.MiddleNameLabel',
    defaultMessage: 'Middle Name',
  },
  lastNameLabel: {
    id: 'App.BasicInformationSection.LastNameLabel',
    defaultMessage: 'Last Name',
  },
  preferredNameLabel: {
    id: 'App.BasicInformationSection.PreferredNameLabel',
    defaultMessage: 'Preferred Name',
  },
  birthDateLabel: {
    id: 'App.BasicInformationSection.BirthDateLabel',
    defaultMessage: 'Date of Birth',
  },
  stateIdNumberLabel: {
    id: 'App.BasicInformationSection.StateIdLabel',
    defaultMessage: 'Driver License/State ID',
  },
  stateIdIssuerLabel: {
    id: 'App.BasicInformationSection.StateIdIssuerLabel',
    defaultMessage: 'Driver License/ID State',
  },
  socialSecurityNumberLabel: {
    id: 'App.BasicInformationSection.SocialSecurityNumberLabel',
    defaultMessage: 'Social Security Number or TIN',
  },
  passportNumberPlaceholder: {
    id: 'App.BasicInformationSection.PassportNumberPlaceholder',
    defaultMessage: 'Passport Number',
  },
  passportCountryLabel: {
    id: 'App.BasicInformationSection.PassportCountryLabel',
    defaultMessage: 'Passport Country',
  },
  alienRegistrationLabel: {
    id: 'App.BasicInformationSection.AlienRegistrationLabel',
    defaultMessage: 'Alien Reg # (if no SSN)',
  },
  alienRegistrationPlaceholder: {
    id: 'App.BasicInformationSection.AlienRegistrationPlaceholder',
    defaultMessage: 'Alien Registration Number',
  },
  requiredFieldsHint: {
    id: 'App.BasicInformationSection.requiredFieldsHint',
    /* eslint-disable max-len */
    defaultMessage:
      '**You must provide at least one of the following: Driver License/State ID (if so, select a State), Social Security Number or Taxpayer Identification Number, or Passport (if so, select a Country). If you have a Social Security number, you must provide it.',
  },
  requiredFieldsAffordableHint: {
    id: 'App.BasicInformationSection.requiredFieldsAffordableHint',
    /* eslint-disable max-len */
    defaultMessage:
      '**You must provide at least one of the following: Driver License/State ID (if so, select a State), Social Security Number or Taxpayer Identification Number, Passport (if so, select a Country), or Alien Registration number. If you have a Social Security number, you must provide it.',
  },
  provideOne: {
    id: 'App.BasicInformationSection.provideOne',
    defaultMessage:
      'You must provide either SSN, license or passport to complete the application.',
  },
  provideOneAffordable: {
    id: 'App.BasicInformationSection.ProvideOneAffordable',
    defaultMessage:
      'You must provide either SSN, license, passport, or alien registration to complete the application.',
  },
  requiredForCompletion: {
    id: 'App.BasicInformationSection.RequiredForCompletion',
    defaultMessage: 'This field is required to complete the application',
  },
  passportLabel: {
    id: 'App.BasicInformationSection.passportLabel',
    defaultMessage: 'Passport #',
  },
});

export default messages;
