export const GET_ONE_APPLICATION = 'app/ApplicationProfile/GET_ONE_APPLICATION';
export const GET_ONE_APPLICATION_SUCCESS =
  'app/ApplicationProfile/GET_ONE_APPLICATION_SUCCESS';
export const GET_ONE_APPLICATION_ERROR =
  'app/ApplicationProfile/GET_ONE_APPLICATION_ERROR';

export const ASSIGN_APPLICATION = 'app/PeopleProfile/ASSIGN_APPLICATION';
export const UPDATE_APPLICATION = 'app/PeopleProfile/UPDATE_APPLICATION';
export const UPDATE_APPLICATION_SUCCESS =
  'app/PeopleProfile/UPDATE_APPLICATION_SUCCESS';
export const UPDATE_APPLICATION_ERROR =
  'app/PeopleProfile/UPDATE_APPLICATION_ERROR';

export const GET_DOCUMENT_TYPES = 'app/ManageDocuments/GET_DOCUMENT_TYPES';
export const GET_DOCUMENT_TYPES_SUCCESS =
  'app/ManageDocuments/GET_DOCUMENT_TYPES_SUCCESS';

export const GET_APPLICATION = 'app/ManageDocuments/GET_APPLICATION';
export const GET_APPLICATION_SUCCESS =
  'app/ManageDocuments/GET_APPLICATION_SUCCESS';

export const SAVE_DOCUMENT = 'app/ManageDocuments/SAVE_DOCUMENT';
export const SAVE_DOCUMENT_SUCCESS =
  'app/ManageDocuments/SAVE_DOCUMENT_SUCCESS';
export const SAVE_DOCUMENT_ERROR = 'app/ManageDocuments/SAVE_DOCUMENT_ERROR';

export const GET_ALL_DOCUMENTS = 'app/ManageDocuments/GET_ALL_DOCUMENTS';
export const GET_ALL_DOCUMENTS_SUCCESS =
  'app/ManageDocuments/GET_ALL_DOCUMENTS_SUCCESS';

export const UPDATE_COLUMNS_SORT_VALUE =
  'app/ManageDocuments/UPDATE_COLUMNS_SORT_VALUE';

export const GET_APPLICATION_DECISION_STATUS =
  'app/ApplicationProfile/GET_APPLICATION_DECISION_STATUS';
export const GET_APPLICATION_DECISION_STATUS_SUCCESS =
  'app/ApplicationProfile/GET_APPLICATION_DECISION_STATUS_SUCCESS';
export const GET_APPLICATION_DECISION_STATUS_ERROR =
  'app/ApplicationProfile/GET_APPLICATION_DECISION_STATUS_ERROR';

export const UPDATE_APPLICANT_CHECKLIST =
  'app/ApplicationProfile/UPDATE_APPLICANT_CHECKLIST';
export const UPDATE_APPLICANT_CHECKLIST_SUCCESS =
  'app/ApplicationProfile/UPDATE_APPLICANT_CHECKLIST_SUCCESS';
export const UPDATE_APPLICANT_CHECKLIST_ERROR =
  'app/ApplicationProfile/UPDATE_APPLICANT_CHECKLIST_ERROR';

export const CLEAN_LOADED_APPLICATIONS =
  'app/ManageApplications/CLEAN_LOADED_APPLICATIONS';

export const GET_APPLICATION_STATUS =
  'app/ApplicationProfile/GET_APPLICATION_DECISION_STATUS';
export const GET_APPLICATION_STATUS_SUCCESS =
  'app/ApplicationProfile/GET_APPLICATION_STATUS_SUCCESS';
export const GET_APPLICATION_STATUS_ERROR =
  'app/ApplicationProfile/GET_APPLICATION_STATUS_ERROR';

export const GET_SCREENING_STATUS =
  'app/ApplicationProfile/GET_SCREENING_STATUS';
export const GET_SCREENING_STATUS_SUCCESS =
  'app/ApplicationProfile/GET_SCREENING_STATUS_SUCCESS';
export const GET_SCREENING_STATUS_ERROR =
  'app/ApplicationProfile/GET_SCREENING_STATUS_ERROR';

export const SUBMIT_SCREENING_APPLICATION =
  'app/ApplicationProfile/SUBMIT_SCREENING_APPLICATION';
export const SUBMIT_SCREENING_APPLICATION_SUCCESS =
  'app/ApplicationProfile/SUBMIT_SCREENING_APPLICATION_SUCCESS';
export const SUBMIT_SCREENING_APPLICATION_ERROR =
  'app/ApplicationProfile/SUBMIT_SCREENING_APPLICATION_ERROR';

export const SUBMIT_SCREENING_RESULTS =
  'app/ApplicationProfile/SUBMIT_SCREENING_RESULTS';

export const SUBMIT_GENERATE_ADVERSE_ACTION_LETTER_SUCCESS =
  'app/ApplicationProfile/SUBMIT_GENERATE_ADVERSE_ACTION_LETTER_SUCCESS';
export const SUBMIT_GENERATE_ADVERSE_ACTION_LETTER_ERROR =
  'app/ApplicationProfile/SUBMIT_GENERATE_ADVERSE_ACTION_LETTER_ERROR';
export const SUBMIT_GENERATE_ADVERSE_ACTION_LETTER =
  'app/ApplicationProfile/SUBMIT_GENERATE_ADVERSE_ACTION_LETTER';

export const CLEAN_LOADED_APPLICATION =
  'app/ApplicationProfile/CLEAN_LOADED_APPLICATION';

export const SAVE_LEASE_DATA_TAB = 'app/ApplicationProfile/SAVE_LEASE_DATA_TAB';

export const GET_ASSIGNED_UNITS = 'app/ApplicationProfile/GET_ASSIGNED_UNITS';
export const GET_ASSIGNED_UNITS_SUCCESS =
  'app/ApplicationProfile/GET_ASSIGNED_UNITS_SUCCESS';
export const GET_ASSIGNED_UNITS_ERROR =
  'app/ApplicationProfile/GET_ASSIGNED_UNITS_ERROR';

export const UNASSIGN_UNIT = 'app/ApplicationProfile/UNASSIGN_UNIT';
export const UNASSIGN_UNIT_SUCCESS =
  'app/ApplicationProfile/UNASSIGN_UNIT_SUCCESS';
export const UNASSIGN_UNIT_ERROR = 'app/ApplicationProfile/UNASSIGN_UNIT_ERROR';
export const UPDATE_LABEL_END_DATE =
  'app/ApplicationProfile/UPDATE_LABEL_END_DATE';
export const SAVE_MONTHLY_CHARGE = 'app/ApplicationProfile/SAVE_MONTHLY_CHARGE';
export const DELETE_MONTHLY_CHARGE =
  'app/ApplicationProfile/DELETE_MONTHLY_CHARGE';
export const DELETE_MONTHLY_CHARGE_SUCCESS =
  'app/ApplicationProfile/DELETE_MONTHLY_CHARGE_SUCCESS';
export const DELETE_MONTHLY_CHARGE_ERROR =
  'app/ApplicationProfile/DELETE_MONTHLY_CHARGE_ERROR';
export const GET_MONTHLY_TRANSACTIONS =
  'app/ApplicationProfile/GET_MONTHLY_TRANSACTIONS';
export const GET_MONTHLY_TRANSACTIONS_SUCCESS =
  'app/ApplicationProfile/GET_MONTHLY_TRANSACTIONS_SUCCESS';
export const GET_MONTHLY_TRANSACTIONS_ERROR =
  'app/ApplicationProfile/GET_MONTHLY_TRANSACTIONS_ERROR';
export const SAVE_MONTHLY_CHARGE_SUCCESS =
  'app/ApplicationProfile/SAVE_MONTHLY_CHARGE_SUCCESS';
export const SET_MONTHLY_OPTION = 'app/ApplicationProfile/SET_MONTHLY_OPTION';
export const SAVE_LEASE_SUCCESS = 'app/ApplicationProfile/SAVE_LEASE_SUCCESS';

export const GET_UNIT_QUOTE = 'app/ApplicationProfile/GET_UNIT_QUOTE';
export const GET_UNIT_QUOTE_SUCCESS =
  'app/ApplicationProfile/GET_UNIT_QUOTE_SUCCESS';
export const GET_UNIT_QUOTE_ERROR =
  'app/ApplicationProfile/GET_UNIT_QUOTE_ERROR';

export const GENERATE_LEASE_DOCUMENT =
  'app/ApplicationProfile/GENERATE_LEASE_DOCUMENT';
export const GENERATE_LEASE_DOCUMENT_SUCCESS =
  'app/ApplicationProfile/GENERATE_LEASE_DOCUMENT_SUCCESS';
export const GENERATE_LEASE_DOCUMENT_ERROR =
  'app/ApplicationProfile/GENERATE_LEASE_DOCUMENT_ERROR';

export const RESEND_PAYLEASE_EMAIL =
  'app/ApplicationProfile/RESEND_PAYLEASE_EMAIL';

export const SEND_APPLICANT_PORTAL_INVITE_EMAIL =
  'app/ApplicationProfile/SEND_APPLICANT_PORTAL_INVITE_EMAIL';

export const UPDATE_PROPERTY_CLASS =
  'app/ApplicationProfile/UPDATE_PROPERTY_CLASS';
export const UPDATE_PROPERTY_CLASS_SUCCESS =
  'app/ApplicationProfile/UPDATE_PROPERTY_CLASS_SUCCESS';
export const UPDATE_PROPERTY_CLASS_ERROR =
  'app/ApplicationProfile/UPDATE_PROPERTY_CLASS_ERROR';

export const UPDATE_COMMERCIAL_APPLICATION =
  'app/PeopleProfile/UPDATE_COMMERCIAL_APPLICATION';
export const UPDATE_COMMERCIAL_APPLICATION_SUCCESS =
  'app/PeopleProfile/UPDATE_COMMERCIAL_APPLICATION_SUCCESS';
export const UPDATE_COMMERCIAL_APPLICATION_ERROR =
  'app/PeopleProfile/UPDATE_COMMERCIAL_APPLICATION_ERROR';

export const UPDATE_APPLICANT = 'app/ApplicationProfile/UPDATE_APPLICANT';
export const UPDATE_APPLICANT_SUCCESS =
  'app/ApplicationProfile/UPDATE_APPLICANT_SUCCESS';
export const UPDATE_APPLICANT_ERROR =
  'app/ApplicationProfile/UPDATE_APPLICANT_ERROR';

export const SOCKET_EVENT_ARS_PENDING =
  'app/ApplicationProfile/SOCKET_EVENT_ARS_PENDING';
export const SOCKET_EVENT_ARS_SUCCESS =
  'app/ApplicationProfile/SOCKET_EVENT_ARS_SUCCESS';
export const SOCKET_EVENT_ARS_ERROR =
  'app/ApplicationProfile/SOCKET_EVENT_ARS_ERROR';

export const SCREENING_RESULTS_INDEXES = {
  APPROVED: 1,
  ACCEPT_WITH_COND: 2,
  CONDITIONAL: 3,
  DECLINED: 4,
  REFER: 5,
};

export const SCREENING_RESULTS_APPROVED = [
  SCREENING_RESULTS_INDEXES.APPROVED,
  SCREENING_RESULTS_INDEXES.ACCEPT_WITH_COND,
  SCREENING_RESULTS_INDEXES.CONDITIONAL,
];

export const SCREENING_RESULTS_NEEDING_NOTES = [
  SCREENING_RESULTS_INDEXES.CONDITIONAL,
  SCREENING_RESULTS_INDEXES.DECLINED,
  SCREENING_RESULTS_INDEXES.REFER,
];

export const SCREENING_RESULTS_DECLINE_ENABLE = [
  SCREENING_RESULTS_INDEXES.APPROVED,
  SCREENING_RESULTS_INDEXES.ACCEPT_WITH_COND,
  SCREENING_RESULTS_INDEXES.CONDITIONAL,
  SCREENING_RESULTS_INDEXES.DECLINED,
  SCREENING_RESULTS_INDEXES.REFER,
];

export const APPLICATION_STATUS_IDS = {
  canceled: '9fe61e0a-144a-4bee-84ff-3fcc10557499',
  denied: '003d2106-22d1-47f7-8843-3e911606a936',
};
