/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ViewProperty.AffordableSetup.Programs.NOAH.Title',
    defaultMessage: 'NOAH',
  },
});

export default messages;
