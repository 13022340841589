import { useMemo, useState } from 'react';
import moment from 'moment';
import useIntl from '../../hooks/useIntl';
import RentForecastService from '../../services/rentForecastService';
import RdBudgetFormService from '../../services/rdBudgetFormService';
import { useMutation, useQuery } from 'react-query';
import messages from './messages';
import useUniqueTableName from '../../hooks/useUniqueTableName';
import {
  useTableFilterSortData,
  useTableFilterSortSearchManager,
  useTableManageColumns,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { PROPERTY_PATH_MAP } from './constants';
import useRows from './hooks.useRows';
import useSelectedProperty from '../../hooks/useSelectedProperty';
import { promptToaster } from '../App/actions';

const highlightHeader = {
  sx: {
    backgroundColor: 'primary.dark',
  },
};

export const columns = {
  HUDGrossRent: 'Gross Rent Limit',
  noteRent: 'Note Rent',
  basicRent: 'Basic Rent',
  quotingRentAmount: 'Current Quoting Rent',
  financialMarketRentAmount: 'Financial Market Rent',
  estimatedDepositAmount: 'Suggested Deposit',
  utilityAllowance: 'RD Utility Allowance',
};

export const useManageRentForecast = ({ refreshFn }) => {
  const intl = useIntl();
  const headers = useMemo(
    () => [
      {
        id: 'floorPlan',
        label: intl.formatMessage(messages.floorPlans),
        sortable: true,
      },
      {
        id: 'bedsBaths',
        label: intl.formatMessage(messages.bedsBaths),
        sortable: true,
      },
      {
        id: 'numUnits',
        label: intl.formatMessage(messages.numUnits),
        sortable: true,
      },
      ...Object.keys(columns).reduce((acc, columnKey) => {
        const columnValue = columns[columnKey];
        return acc.concat([
          {
            id: `current${columnKey}`,
            label: intl.formatMessage(messages.currentValue, {
              column: columnValue,
            }),
            sortable: true,
          },
          {
            id: `total${columnKey}Income`,
            label: intl.formatMessage(messages.totalIncome, {
              column: columnValue,
            }),
            sortable: true,
            info: intl.formatMessage(messages.currentIncomeTooltip),
          },
          {
            id: `new${columnKey}ProposedChange`,
            label: intl.formatMessage(messages.proposedChange),
            sortable: false,
            variant: 'div',

            ...highlightHeader,
          },
          {
            id: `new${columnKey}`,
            label: intl.formatMessage(messages.newValue, {
              column: columnValue,
            }),
            sortable: true,
            info: intl.formatMessage(messages.newProposedAmountTooltip),
            ...highlightHeader,
          },
          {
            id: `new${columnKey}PotentialIncome`,
            label: intl.formatMessage(messages.newPotentialIncome, {
              column: columnValue,
            }),
            sortable: true,
            info: intl.formatMessage(messages.newPotentialIncomeTooltip),
            ...highlightHeader,
          },
        ]);
      }, []),
    ],
    [],
  );

  const [results, setResults] = useState([]);
  const [rentForecastValues, setRentForecastValues] = useState({
    name: '',
    date: null,
  });
  const [currentRentForecast, setCurrentRentForecast] = useState('');
  const tableName = useUniqueTableName('rent-forecast');
  const { organizationId, id: propertyId } = useSelectedProperty();
  const handleResults = (data) => {
    const newData = data?.floorPlans?.map((item) => {
      return {
        floorPlan: item.internalName,
        floorPlanLink: `/property/${propertyId}/floorPlan/${item.id}`,
        bedsBaths: `${item.nBeds || 0} / ${item.nBaths || 0}`,
        numUnits: item?.floorplanUnitCount?.numTotalUnits || 0,
        id: item.id,
        ...Object.keys(columns).reduce((acc, column) => {
          return {
            ...acc,
            [`current${column}`]: item?.[column],
            [`total${column}Income`]:
              item?.[column] * item?.floorplanUnitCount?.numTotalUnits || null,
          };
        }, {}),
      };
    });

    setCurrentRentForecast(data?.rentForecasts?.[0]?.id);
    if (data?.rentForecasts?.[0]?.name) {
      setResults(newData);
    }
  };
  const callback = (data) => {
    handleResults(data);
    if (refreshFn) {
      refreshFn(data);
    }
  };

  const { results: rentForecasts, refetch: refetchRentForecasts } =
    useRentForecastQuery(organizationId, propertyId, handleResults);

  const { mutate: createRentForecast, isLoading } = useRentForecastMutation(
    organizationId,
    propertyId,
    rentForecastValues,
    callback,
    promptToaster,
  );

  const {
    filterState,
    filterTypeState,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    searchState,
  } = useTableFilterSortSearchManager({
    tableName,
    headers,
  });

  const sortedAndFilteredResults = useTableFilterSortData({
    results,
    order,
    orderBy,
    filterState,
    filterTypeState,
    searchState,
    dateState,
    PROPERTY_PATH_MAP: PROPERTY_PATH_MAP ?? {},
  });

  const rows = useRows({
    sortedAndFilteredResults,
  });

  const footerRow = {
    floorPlan: '',
    bedsBaths: '',
    numUnits: {
      value: rows.reduce((acc, row) => acc + row.numUnits.value, 0),
      variant: 'number',
    },
    ...Object.keys(columns).reduce((acc, column) => {
      return {
        ...acc,
        [`current${column}`]: {
          value:
            rows.reduce((acc, row) => acc + row[`current${column}`].value, 0) ||
            null,
          variant: 'currency',
        },
        [`total${column}Income`]: {
          value:
            rows.reduce(
              (acc, row) => acc + row[`total${column}Income`].value,
              0,
            ) || null,
          variant: 'currency',
        },
        [`new${column}`]: {
          value:
            rows.reduce((acc, row) => acc + row[`new${column}`].value, 0) ||
            null,
          variant: 'currency',
        },
        [`new${column}PotentialIncome`]: {
          value:
            rows.reduce(
              (acc, row) => acc + row[`new${column}PotentialIncome`].value,
              0,
            ) || null,
          variant: 'currency',
        },
      };
    }, {}),
  };

  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
  } = useTableManageColumns({
    tableName,
    headers,
    firstRow: rows[0],
  });

  return {
    allColumnsHidden,
    columnOptions,
    count: rows.length ?? 0,
    createRentForecast,
    currentRentForecast,
    dateState,
    filterState,
    filterTypeState,
    footerRow,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    headers: filteredHeaders,
    isLoading,
    order,
    orderBy,
    refetchRentForecasts,
    rentForecasts,
    rentForecastValues,
    rows,
    searchState,
    selectedColumns,
    setCurrentRentForecast,
    setRentForecastValues,
    tableName,
    totalCount: rows.length ?? 0,
  };
};

export const useRentForecastMutation = (
  organizationId,
  propertyId,
  rentForecastValues,
  refreshFn: Function,
  toasterFn: Function,
  reactQueryOptions?: Object,
) => {
  const service = useMemo(() => new RentForecastService(), []);
  const intl = useIntl();

  return useMutation(
    async (values) => {
      const payload = JSON.stringify({
        name: rentForecastValues.name,
        year: moment(rentForecastValues.date).startOf('year').format('YYYY'),
      });
      return service.createRentForecast(organizationId, propertyId, payload);
    },
    {
      ...reactQueryOptions,
      onSuccess: (data) => {
        toasterFn({
          title: intl.formatMessage(messages.success),
          message: intl.formatMessage(messages.createdForecast),
        });
        refreshFn(data);
      },
      onError: (error) => {
        toasterFn({
          type: 'error',
          message: error.toString(),
          title: 'Error',
        });
      },
    },
  );
};

export const useRentForecastQuery = (organizationId, propertyId, callback) => {
  const service = useMemo(() => new RentForecastService(), []);
  const queryKey = [
    'useManageUnitAvailabilityQuery',
    organizationId,
    propertyId,
  ];
  const { data, isLoading, refetch } = useQuery(
    queryKey,
    async () => {
      const rentForecasts = await service.getAllRentForecast(
        organizationId,
        propertyId,
      );
      callback(rentForecasts);
      return rentForecasts;
    },
    {
      enabled: Boolean(propertyId && organizationId),
    },
  );
  const results = useMemo(() => data ?? [], [data]);
  return {
    results,
    isLoading,
    refetch,
  };
};

export const useRdBudgetFormQuery = (rentForecastId, callback) => {
  const service = useMemo(() => new RdBudgetFormService(), []);
  const queryKey = ['useRdBudgetFormQuery', rentForecastId];
  const { data, isLoading, refetch } = useQuery(
    queryKey,
    async () => {
      const rdBudgetForm = await service.getRdBudgetForm(rentForecastId);
      callback(rdBudgetForm);
      return rdBudgetForm;
    },
    {
      enabled: Boolean(rentForecastId),
      refetchOnMount: true,
    },
  );
  const results = useMemo(() => data ?? [], [data]);
  return {
    results,
    isLoading,
    refetch,
  };
};
