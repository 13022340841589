import { useEffect } from 'react';
import {
  Button,
  Stack,
  Typography,
  Box,
  Input,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Modal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import {
  MoneyIcon,
  AlertInfoIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import { useForm, useController } from 'react-hook-form';
import type { Props } from './types';
import messages from '../messages';
import { sanitizeNumberInput } from '../../../utils/redux-form-helper';

function SubmitScreeningModal({ intl, open, onSubmit, onClose }: Props) {
  const { control, handleSubmit, formState, reset } = useForm({
    mode: 'all',
  });
  const {
    field: estimatedMonthlyRentAmountField,
    fieldState: estimatedMonthlyRentAmountFieldState,
  } = useController({
    control,
    name: 'estimatedMonthlyRentAmount',
    rules: {
      required: true,
    },
  });
  const onSubmitWrapper = handleSubmit(({ estimatedMonthlyRentAmount }) =>
    onSubmit(estimatedMonthlyRentAmount),
  );

  useEffect(() => {
    reset();
  }, [open, reset]);

  return (
    <form>
      <Modal
        open={open}
        onClose={onClose}
        title={intl.formatMessage(messages.submitScreeningModalTitle)}
        actions={
          <>
            <Button variant="text" onClick={onClose}>
              {intl.formatMessage(messages.cancel)}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                onSubmitWrapper();
                onClose();
              }}
              disabled={!formState.isDirty || !formState.isValid}
            >
              {intl.formatMessage(messages.confirmAndSubmit)}
            </Button>
          </>
        }
        ModalProps={{
          PaperProps: {
            sx: {
              width: {
                xs: 'calc(100% - 32px)',
                sm: 400,
              },
            },
          },
        }}
      >
        <Stack spacing={2}>
          <Stack>
            <Typography variant="labelLarge">
              {intl.formatMessage(messages.estimatedMonthlyRentAmount)}
            </Typography>
            <Input
              {...estimatedMonthlyRentAmountField}
              placeholder="0.00"
              required
              startAdornment={<MoneyIcon />}
              onChange={(e) => {
                const newValue = sanitizeNumberInput(e.target.value);
                estimatedMonthlyRentAmountField.onChange(newValue);
              }}
              error={!!estimatedMonthlyRentAmountFieldState.error}
            />
          </Stack>
          <Box padding={2} backgroundColor="#F8F8F8" borderRadius={1}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <AlertInfoIcon color="action" />
              <Typography variant="bodySmall" color="textSecondary">
                {intl.formatMessage(messages.submitScreeningModalInfo)}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Modal>
    </form>
  );
}

export default SubmitScreeningModal;
