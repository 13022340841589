import { useMemo } from 'react';
import { useMutation } from 'react-query';
import AffordableWaitlistService from '../../services/affordableWaitlistService';

/**
 * @param {*} param0
 * @returns
 */
export const useAddToWaitlist = ({
  organizationId,
  propertyId,
  applicationId,
  options,
}) => {
  const affordableWaitlistService = useMemo(
    () => new AffordableWaitlistService(),
    [],
  );
  const mutation = useMutation(
    ({ payload }) =>
      affordableWaitlistService.addToWaitlist(
        organizationId,
        propertyId,
        applicationId,
        payload,
      ),
    options,
  );

  return {
    ...mutation,
  };
};

/**
 * @param {*} param0
 * @returns
 */
export const useAddWaitlistNote = ({ organizationId, propertyId, options }) => {
  const affordableWaitlistService = useMemo(
    () => new AffordableWaitlistService(),
    [],
  );
  const mutation = useMutation(
    ({ payload, affordableWaitlistId }) =>
      affordableWaitlistService.addWaitlistNote(
        organizationId,
        propertyId,
        affordableWaitlistId,
        payload,
      ),
    options,
  );

  return {
    ...mutation,
  };
};

/**
 * @param {*} param0
 * @returns
 */
export const useSkipFromWaitlist = ({
  organizationId,
  propertyId,
  options,
}) => {
  const affordableWaitlistService = useMemo(
    () => new AffordableWaitlistService(),
    [],
  );
  const mutation = useMutation(
    ({ payload, affordableWaitlistId }) =>
      affordableWaitlistService.skipFromWaitlist(
        organizationId,
        propertyId,
        affordableWaitlistId,
        payload,
      ),
    options,
  );

  return {
    ...mutation,
  };
};

export const useConfirmFromWaitlist = ({
  organizationId,
  propertyId,
  options,
}) => {
  const affordableWaitlistService = useMemo(
    () => new AffordableWaitlistService(),
    [],
  );
  const mutation = useMutation(
    ({ payload, affordableWaitlistId }) =>
      affordableWaitlistService.confirmFromWaitlist(
        organizationId,
        propertyId,
        affordableWaitlistId,
        payload,
      ),
    options,
  );

  return {
    ...mutation,
  };
};

export const useReinstateToWaitlist = ({
  organizationId,
  propertyId,
  options,
}) => {
  const affordableWaitlistService = useMemo(
    () => new AffordableWaitlistService(),
    [],
  );
  const mutation = useMutation(
    ({ payload, affordableWaitlistId }) =>
      affordableWaitlistService.reinstateToWaitlist(
        organizationId,
        propertyId,
        affordableWaitlistId,
        payload,
      ),
    options,
  );

  return {
    ...mutation,
  };
};

export const useRemoveFromWaitlistMutation = ({
  organizationId,
  propertyId,
  options,
}) => {
  const affordableWaitlistService = useMemo(
    () => new AffordableWaitlistService(),
    [],
  );

  const mutation = useMutation(
    ({ payload }) =>
      affordableWaitlistService.removeFromWaitlist(
        organizationId,
        propertyId,
        payload,
      ),
    options,
  );

  return {
    removeFromWaitlist: mutation.mutate,
    removingFromWaitlist: mutation.isLoading,
  };
};

export const useDenyApplicantMutation = ({
  organizationId,
  propertyId,
  options,
}) => {
  const affordableWaitlistService = useMemo(
    () => new AffordableWaitlistService(),
    [],
  );
  const mutation = useMutation(
    ({ payload }) =>
      affordableWaitlistService.denyApplicationFromWaitlist(
        organizationId,
        propertyId,
        payload,
      ),
    options,
  );

  return {
    denyApplicant: mutation.mutate,
    denyingApplicant: mutation.isLoading,
  };
};
