import React from 'react';
import {
  MenuItem,
  ListItemText,
  ListSubheader,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { DownloadIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { MenuButton } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

type Props = {
  downloadLedgerSortByPostDateCSV: Function,
  downloadLedgerSortByPostDatePDF: Function,
  downloadLedgerSortByTransactionDateCSV: Function,
  downloadLedgerSortByTransactionDatePDF: Function,
};

function DownloadOptions(props: Props) {
  const {
    downloadLedgerSortByPostDateCSV,
    downloadLedgerSortByPostDatePDF,
    downloadLedgerSortByTransactionDateCSV,
    downloadLedgerSortByTransactionDatePDF,
  } = props;

  const options = [
    {
      disabled: true,
      text: (
        <ListSubheader sx={{ lineHeight: '16px', paddingX: 0 }}>
          <FormattedMessage {...messages.sortByPostDate} />
        </ListSubheader>
      ),
    },
    {
      text: <FormattedMessage {...messages.csv} />,
      onClick: downloadLedgerSortByPostDateCSV,
    },
    {
      text: <FormattedMessage {...messages.pdf} />,
      onClick: downloadLedgerSortByPostDatePDF,
      divider: true,
    },
    {
      disabled: true,
      text: (
        <ListSubheader sx={{ lineHeight: '16px', paddingX: 0 }}>
          <FormattedMessage {...messages.sortByTransactionDate} />
        </ListSubheader>
      ),
    },
    {
      text: <FormattedMessage {...messages.csv} />,
      onClick: downloadLedgerSortByTransactionDateCSV,
    },
    {
      text: <FormattedMessage {...messages.pdf} />,
      onClick: downloadLedgerSortByTransactionDatePDF,
    },
  ];

  return (
    <MenuButton
      id="download-options-menu"
      ButtonProps={{
        startIcon: <DownloadIcon />,
        size: 'small',
      }}
      MenuProps={{
        children: options.map(({ disabled, divider, text, onClick }) => (
          <MenuItem disabled={disabled} divider={divider} onClick={onClick}>
            <ListItemText primary={text} />
          </MenuItem>
        )),
      }}
    >
      <FormattedMessage {...messages.placeholder} />
    </MenuButton>
  );
}

export default DownloadOptions;
