import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';

// Redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import DocumentTitle from 'react-document-title';
// selectors
import {
  getAffordableRelationshipOptions,
  getApplicablePropertyClassOptions,
  getApplicantTypeOptions,
  getMinorRelationshipOptions,
  getNameSuffixOptions,
  getPetTypeOptions,
  getRelationshipOptions,
  getSelectedPropertyClass,
  getSelectedPropertyClassType,
  getSelectedPropertyClassVisible,
} from '../App/selectors';
import { getPropertyId, getProspectName } from './selectors';
// actions
import * as createApplicationActions from './actions';
import {
  getAllAffordableRelationships,
  getAllApplicantTypes,
  getAllNameSuffixes,
  getAllPetTypes,
  getAllPropertyClasses,
  getAllRelationships,
} from '../App/actions';
import { getPropertyTransactionCodes } from '../../components/CreateTransaction/actions';

// Components
import { AsyncBox } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { CreateApplicationForm } from '../../components/EditHouseholdForm';
import ManageAffordableWaitlistModals from '../ManageAffordableWaitlist/ManageAffordableWaitlistModals';

// Hooks
import useModalProps from '../../hooks/useModalProps';
import useAffordableWaitlistValidations from '../ManageAffordableWaitlist/hooks.useAffordableWaitlistValidations';
import { convertToApplicant, useModalConfirmProps } from './hooks';

// Types
import type { DropdownOption, Property } from '../App/types';
import type { Prospect } from '../ProspectProfile/types';
import type { Application } from './types';

// Utils
import { dissoc, pathOr } from 'ramda';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import {
  generateInitialAdult,
  generateInitialMinor,
  generateInitialPet,
} from '../../components/EditHouseholdForm/utils';
import { formatNBedsArr } from '../../utils/prospectPreferences-helpers';
import { formatPhoneNumber } from '@fortress-technology-solutions/fortress-component-library/utils';

// Constants
import messages from './messages';
import type { AddToWaitlistParams } from '../ManageAffordableWaitlist/types';
import { ModalConfirm } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';

type StateProps = {
  prospectId: string,
  prospect?: Prospect,
  prospectName: string,
  propertyClass: string,
  propertyId: string,
  primaryApplicantId: string,
  propertyClassVisible: boolean,
  selectedProperty?: Property,
  nameSuffixes: Array<DropdownOption>,
  propertyClasses: Array<DropdownOption>,
  relationships: Array<DropdownOption>,
  minorRelationship: Array<DropdownOption>,
  affordableRelationship: Array<DropdownOption>,
  applicantTypes: Array<DropdownOption>,
  propertyTransactionCodes: Array<any>,
  petTypes: Array<DropdownOption & { petBreeds: Array<DropdownOption> }>,
  locale: string,
  isAffordable: boolean,
  isHouseholdSubmitting: boolean,
  adultsCount: number,
  minorsCount: number,
  applicationFees: ?Object,
};
type Props = {
  intl: any,
  history: Object,
  actions: {
    clean: () => void,
    getOneProspect: (string) => void,
    getAllNameSuffixes: () => void,
    getAllPropertyClasses: () => void,
    getAllApplicantTypes: () => void,
    getAllRelationships: () => void,
    getAllAffordableRelationships: () => void,
    getPropertyTransactionCodes: () => void,
    getAllPetTypes: () => void,
    createApplication: (Application) => void,
    getApplicationFees: (numAdults: number) => void,
  },
};

/**
 * Check index_old.js for reference
 * ---
 * @param {*} props
 * @returns
 */
const CreateApplication = (props: StateProps & Props) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [prospectFormValues, setProspectFormValues] = useState({});
  const [waitlistFormValues, setWaitlistFormValues] = useState({});
  const modalProps = useModalProps();
  const {
    prospect,
    propertyClass,
    propertyClassVisible,
    prospectName,
    organizationId,
    propertyId,
    prospectId,
    affordableRelationship,
    applicationFees,
  } = props;
  const headOfHouseholdRelationship = affordableRelationship.find(({ text }) =>
    /Head/gi.test(text),
  );
  const adults = generateInitialAdult(prospect, headOfHouseholdRelationship);
  const minors = generateInitialMinor(prospect);
  const pets = generateInitialPet(prospect);

  /**
   * replacement for componentWillMount() and componentDidMount()
   */
  useEffect(() => {
    // Clean up before fetching
    props.actions.clean();
    // Fetch data after component mounts
    props.actions.getAllNameSuffixes();
    props.actions.getAllApplicantTypes();
    props.actions.getAllRelationships();
    props.actions.getAllAffordableRelationships();
    props.actions.getAllPropertyClasses();
    props.actions.getAllPetTypes();
    props.actions.getOneProspect(props.prospectId);
    props.actions.getPropertyTransactionCodes();
  }, [props.actions, props.prospectId]);

  /**
   * Replacement for componentDidUpdate(prevProps: Object)
   */
  useEffect(() => {
    props.actions.getApplicationFees(props.adultsCount);
  }, [props.adultsCount]);

  const confirmOnClick = (confirm: boolean) => {
    setConfirmOpen(false);

    if (confirm) {
      submitData();
    }
  };

  const modalConfirmProps = useModalConfirmProps(confirmOpen, confirmOnClick);

  const {
    affordableWaitlistFlag,
    hasAffordableWaitlistPermissionAdd,
    accountThisPropertyForWaitlist,
    affordableWaitlistPropertySetting,
    isAffordableWaitlistPropertySettingStatusClosed,
    propertyAffordablePrograms,
  } = useAffordableWaitlistValidations();

  const showAddToWaitlistModal =
    affordableWaitlistFlag &&
    accountThisPropertyForWaitlist &&
    affordableWaitlistPropertySetting &&
    hasAffordableWaitlistPermissionAdd &&
    !isAffordableWaitlistPropertySettingStatusClosed;

  const removeDefaultFromSelectFields = (values: any) => {
    const { isAffordable } = props;
    return {
      ...values,
      adults: values.adults.map((adult) => ({
        ...dissoc('id', adult),
        suffixId: adult.suffixId === 'default' ? undefined : adult.suffixId,
        relationship:
          adult.relationship === 'default' ? undefined : adult.relationship,
        affordableRelationship:
          adult.affordableRelationship === 'default' || !isAffordable
            ? undefined
            : adult.affordableRelationship,
      })),
      minors: values.minors.map((minor) => ({
        ...dissoc('id', minor),
        suffixId: minor.suffixId === 'default' ? undefined : minor.suffixId,
        relationship:
          minor.relationship === 'default' ? undefined : minor.relationship,
        affordableRelationship:
          minor.affordableRelationship === 'default' || !isAffordable
            ? undefined
            : minor.affordableRelationship,
      })),
    };
  };
  const handleCancel = () => {
    const route = `/prospect/${props.prospectId}`;
    navigateToUrlWithSelectedPropertyId(route);
  };

  const waitlistModalForm = () => {
    const addToWaitlistParams: AddToWaitlistParams = {
      waitlistDateRange: {
        waitlistStartTime: new Date(),
        waitlistEndTime: null,
      },
      info: {
        applicant: prospectName,
        phone: formatPhoneNumber(prospect.phoneNumber),
        incomeLevel: 'Not Determined',
        householdSize: props.adultsCount + props.minorsCount,
        preferredBedroomSize: formatNBedsArr(
          prospect?.prospectPreferences?.nBedsArr,
          prospect?.prospectPreferences?.nBeds,
        ),
        accessibleUnit: 'Not Determined',
      },
      affordablePrograms: propertyAffordablePrograms,
    };

    modalProps.handleOpen({
      modalType: 'addToWaitlist',
      addToWaitlistParams,
      manual: {
        onSubmit: (data) => {
          setWaitlistFormValues(data);
          setConfirmOpen(true);
        },
      },
    });
  };

  const submitData = async () => {
    setSubmitting(true);

    const data = prospectFormValues;

    if (showAddToWaitlistModal) {
      data['waitlist'] = waitlistFormValues;
    }

    try {
      const application = await convertToApplicant(data, organizationId);

      navigateToUrlWithSelectedPropertyId(`/application/${application.id}`);
    } catch (e) {
      if (showAddToWaitlistModal) {
        setSubmitting(false);
      }

      console.error(e);

      // TODO: Use fortress's library for toasts when implemented
      toastr.error(
        props.intl.formatMessage(messages.errorHeader),
        e.toString(),
      );
    }
  };

  const handleSubmit = async (values: any) => {
    setProspectFormValues(removeDefaultFromSelectFields(values));

    if (showAddToWaitlistModal) {
      waitlistModalForm();
    } else {
      setConfirmOpen(true);
    }
  };

  return (
    <DocumentTitle title="Fortress - Convert to Applicant">
      <AsyncBox
        loading={Boolean(
          !prospect && !headOfHouseholdRelationship?.value?.length,
        )}
        sx={{ minHeight: 'calc(100vh - 40px)' }}
      >
        {prospect && (
          <>
            <CreateApplicationForm
              initialValues={{
                adults,
                minors,
                pets,
                prospectId,
                propertyId,
                unitTypeId: propertyClass,
              }}
              pageTitle={`${props.intl.formatMessage(
                messages.convertConfirmation,
              )} ${prospectName}`}
              suffixList={props.nameSuffixes}
              relationshipList={props.relationships}
              minorRelationshipList={props.minorRelationship}
              affordableRelationship={props.affordableRelationship}
              applicantTypeList={props.applicantTypes}
              propertyClassList={props.propertyClasses}
              handleCancel={handleCancel}
              onSubmit={handleSubmit}
              propertyClassVisible={propertyClassVisible}
              petTypeList={props.petTypes}
              applicationFees={applicationFees}
              isAffordable={props.isAffordable}
              handleEditHouseholdMember={() => {}}
              isHouseholdSubmitting={props.isHouseholdSubmitting}
            />
            {showAddToWaitlistModal && (
              <ManageAffordableWaitlistModals
                organizationId={organizationId}
                propertyId={propertyId}
                intl={props.intl}
                isSubmitting={isSubmitting}
                {...modalProps}
              />
            )}
          </>
        )}
        <ModalConfirm {...modalConfirmProps} />
      </AsyncBox>
    </DocumentTitle>
  );
};

export const mapStateToProps = (
  state: Object,
  ownProps: Object,
): StateProps => {
  const { app, languageProvider, createApplication, currentProperty } = state;
  const propertyClassType = getSelectedPropertyClassType(state);
  const propertyClass = getSelectedPropertyClass(state);
  // $FlowFixMe
  const applicantPropertyClass = pathOr('', ['name'], propertyClass);
  const propertiesClasses = pathOr('', ['app', 'propertyClasses'], state);
  const affordableObject =
    propertiesClasses !== ''
      ? propertiesClasses.find((x) => x.name === 'Affordable')
      : '';
  return {
    prospectId: ownProps.match.params.prospectId,
    prospect: createApplication.prospect,
    selectedProperty: app.selectedProperty,
    nameSuffixes: getNameSuffixOptions(state),
    propertyClass,
    propertyClassVisible: getSelectedPropertyClassVisible(state),
    prospectName: getProspectName(state),
    organizationId: app.selectedProperty?.organizationId,
    propertyId: getPropertyId(state),
    propertyClasses: getApplicablePropertyClassOptions(state),
    propertyTransactionCodes: currentProperty.transactionCodes.codes || [],
    relationships: getRelationshipOptions(state),
    minorRelationship: getMinorRelationshipOptions(state),
    affordableRelationship: getAffordableRelationshipOptions(state),
    applicantTypes: getApplicantTypeOptions(state),
    petTypes: getPetTypeOptions(state),
    locale: languageProvider.locale,
    primaryApplicantId: createApplication.primaryApplicantId,
    adultsCount: pathOr(
      0,
      ['form', 'editHousehold', 'values', 'adults', 'length'],
      state,
    ),
    minorsCount: pathOr(
      0,
      ['form', 'editHousehold', 'values', 'minors', 'length'],
      state,
    ),
    applicationFees: createApplication.applicationFees,
    isAffordable:
      propertyClassType === 'Affordable' ||
      (propertyClassType === 'Mixed' &&
        applicantPropertyClass === 'Affordable') ||
      pathOr(
        false,
        ['form', 'editHousehold', 'values', 'unitTypeId'],
        state,
        // $FlowFixMe
      ) === pathOr('', ['id'], affordableObject),
    isHouseholdSubmitting: createApplication.isHouseholdSubmitting,
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      ...createApplicationActions,
      getAllNameSuffixes,
      getAllPropertyClasses,
      getAllApplicantTypes,
      getAllRelationships,
      getAllAffordableRelationships,
      getAllPetTypes,
      getPropertyTransactionCodes,
    },
    dispatch,
  );
  return { actions };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CreateApplication));
