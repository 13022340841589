import { useMutation, QueryOptions } from 'react-query';

import AffordableWaitlistService from '../../../services/affordableWaitlistService';

export const useGenerateAffordableWaitlistReport = ({
  organizationId,
  propertyId,
  toasterFn,
  options,
}: {
  organizationId: string,
  propertyId: string,
  options?: QueryOptions,
}) => {
  const affordableWaitlistService = new AffordableWaitlistService();

  const mutation = useMutation(
    () =>
      affordableWaitlistService.generateAffordableWaitlistReport(
        organizationId,
        propertyId,
      ),
    {
      ...options,
      onError: (error) => {
        toasterFn({
          type: 'error',
          message: error.toString(),
          title: 'Error',
        });
      },
    },
  );

  return mutation;
};

export default useGenerateAffordableWaitlistReport;
