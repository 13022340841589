import React, { memo } from 'react';
import {
  Input,
  Box,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import useIntl from '../../../hooks/useIntl';
import messages from '../messages';
import { Controller } from 'react-hook-form';

const Comments = ({ control }) => {
  const intl = useIntl();

  return (
    <Box mx={8}>
      <Typography variant="h2" my={2}>
        {intl.formatMessage(messages.comments)}
      </Typography>
      <Controller
        name="comments-section"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Input {...field} placeholder="Start typing..." multiline rows={20} />
        )}
      />
    </Box>
  );
};

export default memo(Comments);
