/**
 * Will have the messages displayed in the ManageAffordableWaitlist component
 */
const messages = {
  // Add to Waitlist Modal
  addToWaitlistTitle: {
    id: 'App.ManageAffordableWaitlist.AddToWaitlist.Title',
    defaultMessage: 'Add Household to Waitlist',
  },
  completeTheFollowing: {
    id: 'App.ManageAffordableWaitlist.AddToWaitlist.CompleteTheFollowing',
    defaultMessage: 'Complete the following:',
  },
  affordableProgram: {
    id: 'App.ManageAffordableWaitlist.AddToWaitlist.AffordableProgram',
    defaultMessage: 'Affordable Program',
  },
  anticipatedAnnualIncome: {
    id: 'App.ManageAffordableWaitlist.AddToWaitlist.AnticipatedAnnualIncome',
    defaultMessage: 'Anticipated Annual Income',
  },
  requestingAccomodation: {
    id: 'App.ManageAffordableWaitlist.AddToWaitlist.RequestingAccommodation',
    defaultMessage: 'Requesting Accommodation',
  },
  preferences: {
    id: 'App.ManageAffordableWaitlist.AddToWaitlist.Preferences',
    defaultMessage: 'Preferences',
  },
  // Configure Preferences Modal
  veteran: {
    id: 'App.ManageAffordableWaitlist.ConfigurePreferences.Veteran',
    defaultMessage:
      'Being a veteran or the unmarried spouse of a service person killed while in active service',
  },
  adultTransition: {
    id: 'App.ManageAffordableWaitlist.ConfigurePreferences.AdultTransition',
    defaultMessage: 'Being a young adult transitioning out of foster care',
  },
  involuntarily: {
    id: 'App.ManageAffordableWaitlist.ConfigurePreferences.Involuntarily',
    defaultMessage: 'Being involuntarily displaced',
  },
  disability: {
    id: 'App.ManageAffordableWaitlist.ConfigurePreferences.Disability',
    defaultMessage: 'Disability',
  },
  elderly: {
    id: 'App.ManageAffordableWaitlist.ConfigurePreferences.Elderly',
    defaultMessage: 'Elderly',
  },
  homeless: {
    id: 'App.ManageAffordableWaitlist.ConfigurePreferences.Homeless',
    defaultMessage: 'Homeless or living in a substandard housing',
  },
  paymore50: {
    id: 'App.ManageAffordableWaitlist.ConfigurePreferences.Paymore50',
    defaultMessage: 'Paying more than 50% of your income for rent',
  },
  residency: {
    id: 'App.ManageAffordableWaitlist.ConfigurePreferences.Residency',
    defaultMessage: 'Residency',
  },
  victims: {
    id: 'App.ManageAffordableWaitlist.ConfigurePreferences.Victims',
    defaultMessage: 'Victims of domestic violence',
  },
  workingFamilies: {
    id: 'App.ManageAffordableWaitlist.ConfigurePreferences.WorkingFamilies',
    defaultMessage: 'Working families',
  },
  // Add Note Modal
  addNoteTitle: {
    id: 'App.ManageAffordableWaitlist.AddNote.Title',
    defaultMessage: 'Add Note',
  },
  applicantContacted: {
    id: 'App.ManageAffordableWaitlist.AddNote.ApplicantContacted',
    defaultMessage: 'Did you contact the applicant?',
  },
  // Skip Modal
  skipTitle: {
    id: 'App.ManageAffordableWaitlist.Skip.Title',
    defaultMessage: 'Skip',
  },
  // Reinstate Modal
  reinstateTitle: {
    id: 'App.ManageAffordableWaitlist.Reinstate.Title',
    defaultMessage: 'Reinstate',
  },
  reinstatePosition: {
    id: 'App.ManageAffordableWaitlist.Reinstate.Position',
    defaultMessage: 'Reinstate Position',
  },
  // Cancel Modal
  cancelTitle: {
    id: 'App.ManageAffordableWaitlist.Cancel.Title',
    defaultMessage: 'Cancel',
  },
  // Shared
  notes: {
    id: 'App.ManageAffordableWaitlist.Modals.Note',
    defaultMessage: 'Notes',
  },
  reason: {
    id: 'App.ManageAffordableWaitlist.Modals.Reason',
    defaultMessage: 'Reason',
  },
  save: {
    id: 'App.ManageAffordableWaitlist.Modals.Save',
    defaultMessage: 'Save',
  },
  limitReached255: {
    id: 'App.ManageAffordableWaitlist.Modals.LimitReached255',
    defaultMessage:
      'Your note exceeds the 255 character limit. Please shorten your text.',
  },
  confirmSelectFromWaitlistTitle: {
    id: 'App.ManageAffordableWaitlist.ConfirmTitle',
    defaultMessage: 'Select from Waitlist',
  },
  contentConfirmSelectFromWaitlist: {
    id: 'App.ManageAffordableWaitlist.ContentConfirmSelectFromWaitlist',
    defaultMessage:
      'Are you sure you would like to select this household from the waitlist to process their application?',
  },
  denyTitle: {
    id: 'App.ManageAffordableWaitlist.Deny.Title',
    defaultMessage: 'Deny',
  },
  notes: {
    id: 'App.ManageAffordableWaitlist.Notes',
    defaultMessage: 'Notes',
  },
  notesRequired: {
    id: 'App.ManageAffordableWaitlist.Modals.NotesRequired',
    defaultMessage: 'Notes are required when (Other - See Notes) is selected',
  },
};

export default messages;
