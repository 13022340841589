import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedDate, FormattedNumber } from 'react-intl';
import { bindActionCreators } from 'redux';
import { pathOr } from 'ramda';
import confirm from '../../components/ConfirmDialogModal';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import FormattedDateOrDashes from '../../components/FormattedDateOrDashes';
import {
  generateFinalAccountStatement,
  getFinalAccountStatement,
} from './actions';
import { phoneFormatter } from '../../utils/redux-form-helper';
import { getFinanciallyResponsibleResidents } from '../ResidentProfile/selectors';
import Spinner from '../../components/Spinner';
import ConfirmFASModal from './ConfirmationModal/index';
type ReactRouterProps = {
  history: Object,
};

type ConnectedProps = ReactRouterProps & {
  customerId: string,
  isCommercial: boolean,
  frNames: Array<string>,
  property: Object,
  lease: Object,
  finalAccountStatement: Object,
};

type ConnectedMethods = {
  actions: Object,
  flags: Object,
};

type DefaultProps = {
  today: Date,
};

type RouterProps = {
  match: {
    params: {
      propertyId: string,
      residentId: string,
    },
  },
};

type StateProps = {
  disabled: boolean,
  showConfirmation: boolean,
};

type Props = DefaultProps & ConnectedProps & ConnectedMethods & RouterProps;

export class FinalAccountStatement extends Component<Props, StateProps> {
  static defaultProps = {
    today: new Date(),
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      disabled: false,
      showConfirmation: false,
    };
  }

  componentDidMount() {
    const {
      actions: { getFinalAccountStatement },
      customerId,
      history,
    } = this.props;
    if (!customerId) {
      history.goBack();
    } else {
      getFinalAccountStatement({ customerId });
    }
  }

  handleCancel = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleSubmit = (sendEmailSelected: boolean) => {
    const {
      actions: { generateFinalAccountStatement },
      customerId,
      match: {
        params: { residentId },
      },
    } = this.props;
    if (this.props.flags?.sendFasViaEmail) {
      this.setState({ disabled: true });
      generateFinalAccountStatement(customerId, residentId, sendEmailSelected);
    } else {
      confirm(
        'Final Account Statement can only be generated once. Are you sure all charges are correct and that you want to Generate the Final Account Statement and write off all remaining balances?', // eslint-disable-line
      ).then(
        () => {
          this.setState({ disabled: true });
          generateFinalAccountStatement(customerId, residentId);
        },
        () => {},
      );
    }
  };

  isILIProperty(property) {
    if (property.organizationId === '23caad2e-6abe-4d00-87f0-adbe19362643') {
      return (
        <p className="text-center">
          Any amounts due from prior residents must be paid to {property.name}{' '}
          within 30 (thirty) days from the date of this statement. If we do not
          receive payment, your balance will be turned over to IQ Data, a
          collection company, and will be subject to additional fees/or
          penalties. Please contact {property.name} at {property.phoneNumber}{' '}
          with any questions. Additionally, IQ Data can be reached at
          888-248-2509.
        </p>
      );
    } else {
      return (
        <p className="text-center">
          Any amounts due from prior resident must be paid to {property.name}{' '}
          within 30 days of the date of this statement. If we do not receive
          payment, your balance will be turned over to a collection company and
          will be subject to additional fees and/or penalties. Please contact{' '}
          {property.name} with any questions.
        </p>
      );
    }
  }

  toggleShowConfirmation = () => {
    const { showConfirmation } = this.state;
    this.setState({ showConfirmation: !showConfirmation });
  };

  formatCurrency(input: string | number): string {
    if (input === null) {
      return '';
    }
    return +input >= 0
      ? `$${(+input).toFixed(2).toLocaleString()}`
      : `-$${Math.abs(+input).toFixed(2).toLocaleString()}`;
  }

  renderForwardingAddress = (lease: Object) => {
    const noForwardingAddress = pathOr(
      false,
      ['noticeToVacate', 'noForwardingAddress'],
      lease,
    );
    if (noForwardingAddress) {
      return <p>No forwarding address provided.</p>;
    }
    const isForeignAddress = pathOr(
      false,
      ['noticeToVacate', 'isForeignAddress'],
      lease,
    );

    if (isForeignAddress) {
      return (
        <React.Fragment>
          <p>{`${pathOr(
            '---',
            ['noticeToVacate', 'foreignAddressLine1'],
            lease,
          )}`}</p>
          <p>{`${pathOr(
            '---',
            ['noticeToVacate', 'foreignAddressLine2'],
            lease,
          )}`}</p>
          <p>{`${pathOr(
            '---',
            ['noticeToVacate', 'foreignAddressLine3'],
            lease,
          )}`}</p>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <p>{`${pathOr('---', ['noticeToVacate', 'street'], lease)}`}</p>
        <p>
          {`${pathOr('---', ['noticeToVacate', 'city'], lease)}, ${pathOr(
            '---',
            ['noticeToVacate', 'stateName'],
            lease,
          )} ${pathOr('---', ['noticeToVacate', 'zipCode'], lease)}`}
        </p>
      </React.Fragment>
    );
  };

  render() {
    const {
      match: {
        params: { propertyId, residentId },
      },
      today,
      frNames,
      lease,
      property,
      isCommercial,
      finalAccountStatement: {
        balance,
        additionalCharges,
        finalBalance,
        depositsHeld,
        propertyMarketing,
        household,
        organization,
      } = {},
    } = this.props;
    const currencyStyle = { value: 'currency' };
    const orgName = pathOr('---', ['name'], organization);
    const finalAccountBalance = finalBalance - depositsHeld;
    const emailFASFlagEnabled = this.props.flags?.sendFasViaEmail;
    return (
      <div className="bodywrap">
        <div className="container-fluid">
          <ConfirmFASModal
            show={this.state.showConfirmation && emailFASFlagEnabled}
            onHide={() => this.toggleShowConfirmation()}
            onSubmit={(sendEmailSelected) => {
              this.handleSubmit(sendEmailSelected);
              this.toggleShowConfirmation();
            }}
          />
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <Link
                className="btn-text no-print"
                to={`/property/${propertyId}/prior-resident/${residentId}`}
              >
                <i className="et-chevron-left" />
                Go back to Prior Resident profile
              </Link>
            </div>
            <div className="col-xs-12 col-lg-7">
              <p className="no-print padbottom20">
                <strong>NOTE:</strong> This final account statement reflects the
                ledger balance at move-out plus all charges/credits/payments
                posted to the resident's account after move-out. Please review
                and confirm that all additional payments/charges/credits have
                been posted before generating the final account statement.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-lg-7">
              <div className="block-white-shadow block-with-padding">
                <div className="container-fluid">
                  <div className="printable-header">
                    <div className="row">
                      <div className="col-xs-12 col-md-2">
                        <div className="logo-placeholder">
                          <img
                            src={
                              propertyMarketing != null
                                ? propertyMarketing.propertyLogoLink
                                : ''
                            }
                            alt={property.name}
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-md-10">
                        <p>Final Account Statement</p>
                        <h1 className="font-light font25">{property.name}</h1>
                        <p>
                          <strong>
                            {`${property.address}, ${
                              property.physicalCity
                            }, ${pathOr('', ['state', 'code'], property)} ${
                              property.physicalZip
                            } | ${phoneFormatter(property.phoneNumber)} | ${
                              property.propertyEmail
                            }`}
                          </strong>
                        </p>
                      </div>
                    </div>
                    <div className="row padtop20">
                      <div className="col-xs-12 col-md-3">
                        {frNames.map((name) => (
                          <h2 key={name}>{name}</h2>
                        ))}
                        {this.renderForwardingAddress(lease)}
                      </div>
                      <div className="col-xs-12 col-md-5">
                        <div className="row">
                          <div className="col-xs-12">
                            <span>
                              <strong>Unit Number: </strong>
                              <span>
                                {pathOr('', ['units', '0', 'number'], lease)}
                              </span>
                            </span>
                          </div>
                          <div className="col-xs-12 padtop10">
                            <span>
                              <strong>Final Account Statement Date: </strong>
                              <FormattedDateOrDashes
                                format="MM/DD/YYYY"
                                value={today}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="divider-dotted" />
                  <div className="panel__details nopad">
                    <div className="row">
                      <div className="col-xs-12">
                        <h2>Lease Details</h2>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="form-group">
                                <div className="col-xs-6">
                                  <label>Property Move-In Date: </label>
                                </div>
                                <div className="col-xs-6">
                                  <FormattedDateOrDashes
                                    format="MM/DD/YYYY"
                                    value={pathOr(
                                      lease.actualMoveInDate,
                                      ['householdMoveInDate'],
                                      household,
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xs-6">
                                <label>Notice Given: </label>
                              </div>
                              <div className="col-xs-6">
                                <FormattedDateOrDashes
                                  format="MM/DD/YYYY"
                                  value={pathOr(
                                    '',
                                    ['noticeToVacate', 'noticeDate'],
                                    lease,
                                  )}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xs-6">
                                <label>Move-Out Date:</label>
                              </div>
                              <div className="col-xs-6">
                                <FormattedDateOrDashes
                                  format="MM/DD/YYYY"
                                  value={lease.moveOutDate}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-xs-6">
                                <label>Lease End Date:</label>
                              </div>
                              <div className="col-xs-6">
                                <FormattedDateOrDashes
                                  format="MM/DD/YYYY"
                                  value={lease.endDate}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xs-6">
                                <label>Reason for Move-Out:</label>
                              </div>
                              <div className="col-xs-6">
                                {`${pathOr(
                                  '',
                                  ['noticeToVacate', 'moveOutReason', 'type'],
                                  lease,
                                )} - ${pathOr(
                                  '',
                                  ['noticeToVacate', 'moveOutReason', 'reason'],
                                  lease,
                                )}`}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xs-6">
                                <label>Subjournal:</label>
                              </div>
                              <div className="col-xs-6">
                                {isCommercial ? 'Resident + CAM' : 'Resident'}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="highlighted-footer">
                              <div className="row">
                                <div className="col-xs-12">
                                  <span>Ledger Balance at Move Out: </span>
                                  <span>
                                    {balance != null &&
                                    !Number.isNaN(balance) ? (
                                      <FormattedNumber
                                        value={balance}
                                        style={currencyStyle.value}
                                        currency="USD"
                                      />
                                    ) : (
                                      '---'
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row padtop10">
                    <div className="col-xs-12">
                      <h2 className="padtop20">
                        Additional Charges/Credits/Payments
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <table className="table table-prospects table-striped">
                        <thead className="table-header table-header--printable">
                          <tr>
                            <th>Date</th>
                            <th>Code</th>
                            <th>Description</th>
                            <th>Charges</th>
                            <th>Credits</th>
                            <th>Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(additionalCharges) &&
                            additionalCharges.map(
                              (
                                {
                                  transactionDate,
                                  charges,
                                  credits,
                                  transactionNote,
                                  subjournalRunningBalance,
                                  propertyTransactionCode: {
                                    transactionCode: { code, description },
                                  },
                                },
                                index,
                              ) => (
                                <tr key={index}>
                                  <td>
                                    <FormattedDate
                                      format="MM/DD/YYYY"
                                      value={transactionDate}
                                    />
                                  </td>
                                  <td>{code}</td>
                                  <td>
                                    {transactionNote
                                      ? `${description} - ${transactionNote}`
                                      : description}
                                  </td>
                                  <td>
                                    {charges === 0
                                      ? ' '
                                      : this.formatCurrency(charges)}
                                  </td>
                                  <td>
                                    {credits === 0
                                      ? ' '
                                      : this.formatCurrency(credits)}
                                  </td>
                                  <td>
                                    <FormattedNumber
                                      value={subjournalRunningBalance}
                                      style={currencyStyle.value}
                                      currency="USD"
                                    />
                                  </td>
                                </tr>
                              ),
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="panel__details nopad">
                    <div className="row">
                      <div className="col-sm-7 col-md-7">
                        <h2>Apply Deposits</h2>
                        <div className="row">
                          <div className="col-xs-8">
                            <label>
                              Balance After All Charges/Payments/Credits:
                            </label>
                          </div>
                          <div className="col-xs-4">
                            <div className="text-right">
                              {finalBalance != null &&
                              !Number.isNaN(finalBalance) ? (
                                <FormattedNumber
                                  value={finalBalance}
                                  style={currencyStyle.value}
                                  currency="USD"
                                />
                              ) : (
                                '---'
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-8">
                            <label>Total Deposits Held:</label>
                          </div>
                          <div className="col-xs-4">
                            <div className="text-right">
                              {depositsHeld != null &&
                              !Number.isNaN(depositsHeld) ? (
                                <FormattedNumber
                                  value={depositsHeld}
                                  style={currencyStyle.value}
                                  currency="USD"
                                />
                              ) : (
                                '---'
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-12">
                            <div className="highlighted-footer">
                              <span>Final Account Balance:</span>{' '}
                              <span className="pull-right">
                                {depositsHeld != null &&
                                !Number.isNaN(depositsHeld) ? (
                                  <FormattedNumber
                                    value={finalAccountBalance}
                                    style={currencyStyle.value}
                                    currency="USD"
                                  />
                                ) : (
                                  '---'
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-5 col-md-5">
                        <div className="form-groupbox form-groupbox--flexible">
                          <h2>Statement Summary</h2>
                          <div className="row">
                            <div className="col-xs-8">
                              <label>Amount Due from Prior Resident: </label>
                            </div>
                            <div className="col-xs-4">
                              <strong>
                                <FormattedNumber
                                  value={
                                    finalAccountBalance > 0
                                      ? finalAccountBalance
                                      : 0
                                  }
                                  style={currencyStyle.value}
                                  currency="USD"
                                />
                              </strong>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-8">
                              <label>Amount Due to Prior Resident: </label>
                            </div>
                            <div className="col-xs-4">
                              <strong>
                                <FormattedNumber
                                  value={
                                    finalAccountBalance < 0
                                      ? finalAccountBalance
                                      : 0
                                  }
                                  style={currencyStyle.value}
                                  currency="USD"
                                />
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="divider-dotted" />
                  <div className="row padbottom10">
                    <div className="col-xs-12">
                      {this.isILIProperty(property)}
                      <p className="text-center nopad">
                        {propertyMarketing != null
                          ? propertyMarketing.officeHoursDescription
                          : ''}
                      </p>
                      <p className="text-center nopad">Managed by {orgName}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-10 col-lg-7">
              <div className="form-footer padtop10">
                <div className="button-group padbottom10">
                  <button
                    className="btn btn-default"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={
                      emailFASFlagEnabled
                        ? this.toggleShowConfirmation
                        : this.handleSubmit
                    }
                    disabled={this.state.disabled || !this.props.customerId}
                  >
                    {!this.props.customerId ? (
                      <Spinner small />
                    ) : (
                      'Write Off Balance and Generate Final Account Statement'
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStatetoProps(state: Object, { history }: Object): ConnectedProps {
  return {
    customerId: state.residentProfile.residentInfo.residentCustomer.customer.id,
    isCommercial: pathOr(
      false,
      ['residentProfile', 'applicationInfo', 'isCommercial'],
      state,
    ),
    lease: state.residentProfile.residentInfo.lease,
    frNames: getFinanciallyResponsibleResidents(state).map(
      (a) => a.completeName,
    ),
    property: state.app.selectedProperty,
    finalAccountStatement: state.finalAccountStatement.payload,
    history,
  };
}

function mapDispatchToProps(dispatch): ConnectedMethods {
  return {
    actions: bindActionCreators(
      { getFinalAccountStatement, generateFinalAccountStatement },
      dispatch,
    ),
  };
}

const connected = connect(
  mapStatetoProps,
  mapDispatchToProps,
)(FinalAccountStatement);

export default withLDConsumer()(connected);
