import moment from 'moment';

import { post, get, put, patch, getDownload } from '../utils/api';

type AddToWaitlistPayloadType = {
  affordableProgram: string,
  anticipatedAnnualIncome: number,
  incomeLevel: string,
  requestingAccommodations: boolean,
  preferences: Array<string>,
};

type AddWaitlistNotePayloadType = {
  description: string,
  applicantContacted: boolean,
  affordableWaitlistRow: AddToWaitlistPayloadType & {
    applicationStatusId: string,
    id: string,
  },
};

type SkipFromWaitlistReasonType =
  | 'urgentHousingNeed'
  | 'medicalNecessity'
  | 'incomeTargeting'
  | 'preferences'
  | 'otherSeeNotes';

type SkipFromWaitlistPayloadType = {
  description: string,
  reasons: SkipFromWaitlistReasonType[],
  affordableWaitlistRow: AddToWaitlistPayloadType & {
    applicationStatusId: string,
    id: string,
    applicationId: string,
    position: number,
  },
};

type ReinstateToWaitlistReasonType =
  | 'changedMind'
  | 'unintentionallyRemoved'
  | 'resolvedScreeningIssue'
  | 'otherSeeNotes';

type ReinstateToWaitlistPayloadType = {
  description: string,
  reasons: ReinstateToWaitlistReasonType[],
  reinstatePosition: 'originalPlace' | 'bottomOfWaitlist',
  affordableWaitlistRow: AddToWaitlistPayloadType & {
    applicationStatusId: string,
    id: string,
    applicationId: string,
    position: number,
  },
};

export default class AffordableWaitlistService {
  /**
   * Will retrieve all affordable waitlists for specific property
   * -
   * @param {*} organizationId
   * @param {*} propertyId
   * @returns
   */
  getAllAffordableWaitlistsByPropertyId(
    organizationId: string,
    propertyId: string,
    filters: Object,
    options?: Object,
  ): any {
    const filter = filters ? `filter=${JSON.stringify(filters)}` : '';
    const queryString = filter ? filter : '';
    return get(
      `/${organizationId}/${propertyId}/affordable-waitlist?${queryString}`,
      options,
    );
  }

  /**
   * Will get a affordable waitlist by application id
   * -
   * @param {*} organizationId
   * @param {*} propertyId
   * @param {*} applicationId
   * @returns
   */
  getAffordableWaitlistByApplicationId(
    organizationId: string,
    propertyId: string,
    applicationId: string,
    options?: Object,
  ): any {
    return get(
      `/${organizationId}/${propertyId}/${applicationId}/affordable-waitlist`,
      options,
    );
  }

  /**
   * Will add a household to the affordable waitlist
   * ---
   * @param {*} organizationId
   * @param {*} propertyId
   * @param {*} payload
   * @param {*} options
   * @returns
   */
  addToWaitlist(
    organizationId: string,
    propertyId: string,
    applicationId: string,
    payload: AddToWaitlistPayloadType,
    options?: Object,
  ): any {
    return post(
      `/${organizationId}/${propertyId}/${applicationId}/affordable-waitlist`,
      JSON.stringify(payload),
      options,
    );
  }

  /**
   * Will add a note related to the affordable waitlist row
   * ---
   * @param {*} organizationId
   * @param {*} propertyId
   * @param {*} payload
   * @param {*} options
   * @returns
   */
  addWaitlistNote(
    organizationId: string,
    propertyId: string,
    affordableWaitlistId: string,
    payload: AddWaitlistNotePayloadType,
    options?: Object,
  ): any {
    return post(
      `/${organizationId}/${propertyId}/${affordableWaitlistId}/affordable-waitlist/add-waitlist-note`,
      JSON.stringify(payload),
      options,
    );
  }

  /**
   * Will skip an application from the affordable waitlist
   * ---
   * @param {*} organizationId
   * @param {*} propertyId
   * @param {*} payload
   * @param {*} options
   * @returns
   */
  skipFromWaitlist(
    organizationId: string,
    propertyId: string,
    affordableWaitlistId: string,
    payload: SkipFromWaitlistPayloadType,
    options?: Object,
  ): any {
    return put(
      `/${organizationId}/${propertyId}/${affordableWaitlistId}/affordable-waitlist/skip-from-waitlist`,
      JSON.stringify(payload),
      options,
    );
  }

  /**
   * Will reinstate an application to the affordable waitlist
   * ---
   * @param {*} organizationId
   * @param {*} propertyId
   * @param {*} payload
   * @param {*} options
   * @returns
   */
  reinstateToWaitlist(
    organizationId: string,
    propertyId: string,
    affordableWaitlistId: string,
    payload: ReinstateToWaitlistPayloadType,
    options?: Object,
  ): any {
    return put(
      `/${organizationId}/${propertyId}/${affordableWaitlistId}/affordable-waitlist/reinstate-to-waitlist`,
      JSON.stringify(payload),
      options,
    );
  }

  // Affordable Waitlist Property Preferences

  getAffordableWaitlistPropertyPreferences(
    organizationId: string,
    propertyId: string,
    options?: Object,
  ): any {
    return get(
      `/${organizationId}/${propertyId}/affordable-waitlist/configure-preferences`,
      options,
    );
  }

  createAffordableWaitlistPropertyPreference(
    organizationId: string,
    propertyId: string,
    payload,
    options?: Object,
  ): any {
    const body = JSON.stringify(payload);
    return post(
      `/${organizationId}/${propertyId}/affordable-waitlist/configure-preferences`,
      body,
      options,
    );
  }

  updateAffordableWaitlistPropertyPreference(
    organizationId: string,
    propertyId: string,
    payload,
    options?: Object,
  ): any {
    const body = JSON.stringify(payload);
    return put(
      `/${organizationId}/${propertyId}/affordable-waitlist/configure-preferences`,
      body,
      options,
    );
  }

  /**
   * Will skip an application from the affordable waitlist
   * ---
   * @param {*} organizationId
   * @param {*} propertyId
   * @param {*} payload
   * @param {*} options
   * @returns
   */
  confirmFromWaitlist(
    organizationId: string,
    propertyId: string,
    affordableWaitlistId: string,
    payload: SkipFromWaitlistPayloadType,
    options?: Object,
  ): any {
    return put(
      `/${organizationId}/${propertyId}/${affordableWaitlistId}/affordable-waitlist/confirm-from-waitlist`,
      JSON.stringify(payload),
      options,
    );
  }

  removeFromWaitlist(
    organizationId: string,
    propertyId: string,
    payload,
    options?: Object,
  ): any {
    const body = JSON.stringify(payload);
    return patch(
      `/${organizationId}/${propertyId}/affordable-waitlist/applications/${payload.applicationId}`,
      body,
      options,
    );
  }

  denyApplicationFromWaitlist(
    organizationId: string,
    propertyId: string,
    payload,
    options,
  ) {
    const body = JSON.stringify(payload);
    return patch(
      `/${organizationId}/${propertyId}/affordable-waitlist/applications/${payload.applicationId}/deny`,
      body,
      options,
    );
  }

  generateAffordableWaitlistReport(
    organizationId: string,
    propertyId: string,
    options?: Object,
  ): any {
    return getDownload(
      `/${organizationId}/${propertyId}/affordable-waitlist/report`,
      `Affordable Wailist Report - ${moment().local().format('MM/DD/YYY')}.csv`,
      options,
    );
  }
}
