import { defineMessages } from 'react-intl';

/**
 * Will have the messages displayed in the ManageAffordableWaitlist component
 */
const messages = defineMessages({
  title: {
    id: 'App.ManageAffordableWaitlist.Title',
    defaultMessage: 'Manage Affordable Waitlist',
  },
  // Top Buttons
  configurePreferences: {
    id: 'App.ManageAffordableWaitlist.ConfigurePreferences',
    defaultMessage: 'Configure Preferences',
  },
  generateReport: {
    id: 'App.ManageAffordableWaitlist.GenerateReport',
    defaultMessage: 'Generate Waitlist Report',
  },
  // Actions
  selectFromWaitlist: {
    id: 'App.ManageAffordableWaitlist.Actions.SelectFromWaitlist',
    defaultMessage: 'Select From Waitlist',
  },
  cancel: {
    id: 'App.ManageAffordableWaitlist.Actions.Cancel',
    defaultMessage: 'Cancel',
  },
  deny: {
    id: 'App.ManageAffordableWaitlist.Actions.Deny',
    defaultMessage: 'Deny',
  },
  reinstate: {
    id: 'App.ManageAffordableWaitlist.Actions.Reinstate',
    defaultMessage: 'Reinstate',
  },
  addNote: {
    id: 'App.ManageAffordableWaitlist.Actions.AddNote',
    defaultMessage: 'Add Note',
  },
  // Table Headers
  position: {
    id: 'App.ManageAffordableWaitlist.Position',
    defaultMessage: 'Waitlist Position',
  },
  affordablePrograms: {
    id: 'App.ManageAffordableWaitlist.AffordablePrograms',
    defaultMessage: 'Affordable Programs',
  },
  applicationCode: {
    id: 'App.ManageAffordableWaitlist.ApplicationCode',
    defaultMessage: 'Application Code',
  },
  applicants: {
    id: 'App.ManageAffordableWaitlist.Applicants',
    defaultMessage: 'Applicants',
  },
  needsUnitAccessible: {
    id: 'App.ManageAffordableWaitlist.NeedsUnitAccessible',
    defaultMessage: 'Need for Accessible Unit',
  },
  texting: {
    id: 'App.ManageAffordableWaitlist.Texting',
    defaultMessage: 'Texting',
  },
  householdStatus: {
    id: 'App.ManageAffordableWaitlist.HouseholdStatus',
    defaultMessage: 'Household Status',
  },
  status: {
    id: 'App.ManageAffordableWaitlist.Status',
    defaultMessage: 'Status',
  },
  phoneNumber: {
    id: 'App.ManageAffordableWaitlist.PhoneNumber',
    defaultMessage: 'Phone Number',
  },
  emailAddress: {
    id: 'App.ManageAffordableWaitlist.EmailAddress',
    defaultMessage: 'Email',
  },
  desiredMoveInDate: {
    id: 'App.ManageAffordableWaitlist.DesiredMoveInDate',
    defaultMessage: 'Desired Move-In Date',
  },
  applicationReceived: {
    id: 'App.ManageAffordableWaitlist.ApplicationReceived',
    defaultMessage: 'Date And Time Received',
  },
  applicationRemoved: {
    id: 'App.ManageAffordableWaitlist.ApplicationRemoved',
    defaultMessage: 'Date And Time Removed',
  },
  preferredUnitSize: {
    id: 'App.ManageAffordableWaitlist.PreferredUnitSize',
    defaultMessage: 'Preferred Unit Size',
  },
  householdSize: {
    id: 'App.ManageAffordableWaitlist.HouseholdSize',
    defaultMessage: 'Household Size',
  },
  incomeLevel: {
    id: 'App.ManageAffordableWaitlist.IncomeLevel',
    defaultMessage: 'Income Level',
  },
  datesContacted: {
    id: 'App.ManageAffordableWaitlist.DatesContacted',
    defaultMessage: 'Dates Contacted',
  },
  moveInDate: {
    id: 'App.ManageAffordableWaitlist.MoveInDate',
    defaultMessage: 'Move-In Date',
  },
  requestingAccommodation: {
    id: 'App.ManageAffordableWaitlist.RequestingAccommodation',
    defaultMessage: 'Accommodations Requested',
  },
  preferences: {
    id: 'App.ManageAffordableWaitlist.Preferences',
    defaultMessage: 'Preferences',
  },
  assignedTo: {
    id: 'App.ManageAffordableWaitlist.AssignedTo',
    defaultMessage: 'Assigned To',
  },
  notes: {
    id: 'App.ManageAffordableWaitlist.Notes',
    defaultMessage: 'Notes',
  },
  actions: {
    id: 'App.ManageAffordableWaitlist.Actions',
    defaultMessage: 'Actions',
  },
  // Others
  open: {
    id: 'App.ManageAffordableWaitlist.Open',
    defaultMessage: 'Open',
  },
  closed: {
    id: 'App.ManageAffordableWaitlist.Closed',
    defaultMessage: 'Closed',
  },
  closedWarning: {
    id: 'App.ManageAffordableWaitlist.ClosedWarning',
    defaultMessage:
      'The waitlist is closed. Applicants cannot be added to the waitlist. \
    This setting is managed on the Property Info screen.',
  },
  includeAll: {
    id: 'App.ManageAffordableWaitlist.includeAll',
    defaultMessage: 'Include All',
  },
  // Modals
  // ManageAffordableWaitlistModals/messages.js
});

export default messages;
