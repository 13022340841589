import React from 'react';
import {
  Box,
  Stack,
  Typography,
  Divider,
  IconBadgeStatus,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import messages from '../messages';
import type { Props } from './types';
import ScreeningDetailsTooltip from '../ScreeningDetailsTooltip';
import { STATUSES } from '../constants';

function TransUnionScreening({
  intl,
  creditStatus,
  criminalStatus,
  evictionStatus,
  missingRequiredFields,
}: Props) {
  return (
    <Box
      sx={{
        borderRadius: '4px',
        border: '1px solid',
        borderColor: 'divider',
        padding: 3,
      }}
    >
      <Stack direction="row" gap={2} alignItems="center">
        <Stack width={1 / 3}>
          <IconBadgeStatus variant={creditStatus} />
          <Typography
            variant="titleMedium"
            color={
              creditStatus === STATUSES.NOT_READY ? undefined : 'text.disabled'
            }
            marginLeft={0.5}
            marginTop={1}
          >
            {intl.formatMessage(messages.credit)}
          </Typography>
          <ScreeningDetailsTooltip
            intl={intl}
            missingRequiredFields={missingRequiredFields}
          />
        </Stack>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Stack width={1 / 3}>
          <IconBadgeStatus variant={criminalStatus} />
          <Typography
            variant="titleMedium"
            color={
              criminalStatus === STATUSES.NOT_READY
                ? undefined
                : 'text.disabled'
            }
            marginLeft={0.5}
            marginTop={1}
          >
            {intl.formatMessage(messages.criminal)}
          </Typography>
          <ScreeningDetailsTooltip
            intl={intl}
            missingRequiredFields={missingRequiredFields}
          />
        </Stack>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Stack width={1 / 3}>
          <IconBadgeStatus variant={evictionStatus} />
          <Typography
            variant="titleMedium"
            color={
              evictionStatus === STATUSES.NOT_READY
                ? undefined
                : 'text.disabled'
            }
            marginLeft={0.5}
            marginTop={1}
          >
            {intl.formatMessage(messages.evictions)}
          </Typography>
          <ScreeningDetailsTooltip
            intl={intl}
            missingRequiredFields={missingRequiredFields}
          />
        </Stack>
      </Stack>
    </Box>
  );
}

export default TransUnionScreening;
