import {
  compose,
  filter,
  head,
  path,
  pathOr,
  propOr,
  sortBy,
  toLower,
} from 'ramda';
import React from 'react';
import { Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import {
  Box,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Banner } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { formatDateTimeDisplayLocal } from '@fortress-technology-solutions/fortress-component-library/utils';
import DashesIfNullOrUndefined from '../../../components/DashesIfNullOrUndefined';
import FormatPhone from '../../../components/FormatPhone';
import {
  DetailList,
  Divider,
} from '../../../components/ProfileVisualComponents';
import { getSentAndExecutedStatus } from '../../../utils/lease-helpers';
import type { DropdownOption } from '../../App/types';
import type { Application } from '../../CreateApplication/types';
import { useApplicationStatusOptions } from '../hooks';
import ApplicationNotes from '../LeaseApplicationTab/ApplicationNotes';
import messages from './messages';

type Props = {
  currentRecord: Application,
  intl: Object,
  handleNotesChange: Function,
  handleClassChange: Function,
  propertyClasses: Array<Object>,
  propertyClassType: string,
  isResident: boolean,
  prospectUpdateAllowed: boolean,
  assignedUnits: Array<Object>,
  applicationStatuses: Array<DropdownOption>,
  handleStatusChange: Function,
  checkUserPermissions: Function,
  applicationStatusId: string,
};

const WordBreakSpan = styled.span`
  word-break: break-all;
`;

const formatApplicationCode = (code: string, propertyCode: string) =>
  `${propertyCode}-${code.padStart(10, '0')}`;

export const filterFRApplicants = (applicants: Array<Object>) => {
  const isFRA = (x) => x.applicantType.financiallyResponsible;

  const financialResponsibles = filter(isFRA, applicants);
  const sortFRAByName = sortBy(
    compose(toLower, path(['applicantCustomer', 'customer', 'firstName'])),
  );
  // $FlowFixMe
  return sortFRAByName(financialResponsibles);
};

const ProfileDetails = ({
  currentRecord,
  intl,
  handleNotesChange,
  handleClassChange,
  isResident,
  propertyClasses,
  propertyClassType,
  prospectUpdateAllowed,
  assignedUnits,
  applicationStatuses,
  handleStatusChange,
  checkUserPermissions,
  applicationStatusId,
}: Props) => {
  const visibleApplicants = filterFRApplicants(currentRecord.applicants);
  const lastNote = currentRecord.notes;
  const latestLog = {
    notes: lastNote,
    updatedAt: currentRecord.updatedAt,
    updatedBy: currentRecord.updatedById,
    updatedByUser: currentRecord.updatedBy,
  };
  const applicationStatusCreatedAt =
    // $FlowFixMe
    pathOr(
      currentRecord.updatedAt,
      ['statusHistory', '0', 'createdAt'],
      currentRecord,
    );

  const updatedByName =
    latestLog && latestLog.updatedBy
      ? `${latestLog.updatedByUser.firstName} ${latestLog.updatedByUser.lastName}`
      : '---';

  const renderLastestNote = latestLog ? (
    <ApplicationNotes
      intl={intl}
      currentRecord={currentRecord}
      handleNotesChange={handleNotesChange}
      maxLength={250}
      isResident={isResident}
      prospectUpdateAllowed={prospectUpdateAllowed}
    />
  ) : null;

  const renderLastStatus = latestLog ? (
    <Banner
      BoxProps={{ sx: { maxWidth: '100%', width: '100%', mb: 2 } }}
      color={'blue'}
      text={`Last edit by ${updatedByName} on
         ${formatDateTimeDisplayLocal(applicationStatusCreatedAt)}
        `}
    />
  ) : null;

  const applicationPropertyClass = (
    <div className="panel-body">
      <form>
        <label>
          Unit Type:
          <select
            className="form-control input-md"
            // $FlowFixMe
            value={currentRecord.propertyClass.id}
            onChange={(e) =>
              handleClassChange({ propertyClassId: e.target.value })
            }
          >
            {propertyClasses
              .filter((propertyClass) => propertyClass.name !== 'Mixed')
              .map((propertyClass) => (
                <option key={propertyClass.id} value={propertyClass.id}>
                  {propertyClass.name}
                </option>
              ))}
          </select>
        </label>
      </form>
    </div>
  );
  const isCommercial = currentRecord.isCommercial === true;
  const assignedUnit =
    assignedUnits && !!head(assignedUnits) ? head(assignedUnits) : null;
  const unitNumber = pathOr(
    intl.formatMessage(messages.notAssigned),
    ['unit', 'number'],
    // $FlowFixMe
    assignedUnit,
  );
  // $FlowFixMe
  const lease = pathOr({}, ['au', 'lease'], currentRecord);
  const { leaseExecuted, leaseSentToPortal } = getSentAndExecutedStatus(lease);
  const statusUpdateAllowed = checkUserPermissions([
    'application-status-update',
    'application-status-update-excluding-approved',
  ]);
  const disableStatusChange =
    !statusUpdateAllowed || isResident || leaseExecuted || leaseSentToPortal;

  const [applicationStatusOptions] = useApplicationStatusOptions(
    applicationStatuses,
    applicationStatusId,
  );

  const renderCommercialProfilePanel = (
    <Box sx={{ padding: 2 }}>
      <div className="row resident-unit-container">
        <div className="col-xs-12 col-sm-12">
          <h3>
            <FormattedMessage {...messages.applicantTenant} />
          </h3>
          <i className="icon et-home" />
          <strong>
            <FormattedMessage {...messages.unitNumber} />:{' '}
          </strong>
          {unitNumber}
        </div>
      </div>
      <div className="row">
        <hr />
      </div>
      <div className="row">
        {visibleApplicants.map((applicant, i) => {
          // $FlowFixMe
          const customer = pathOr(
            {},
            ['applicantCustomer', 'customer'],
            applicant,
          );
          const emailAddress = propOr('', 'emailAddress', customer);
          const firstName = propOr('', 'firstName', customer);
          const dba = propOr('', 'preferredName', customer);
          const phoneNumber = propOr('', 'phoneNumber', customer);
          return (
            <Col key={i} xs={12} sm={12}>
              <h2>
                <DashesIfNullOrUndefined data={firstName} />
              </h2>
              <ul className="details-list">
                <li>
                  <span>
                    <FormattedMessage {...messages.dba} />:{' '}
                  </span>
                  <DashesIfNullOrUndefined data={dba} />
                </li>
                <li>
                  <span>
                    <FormattedMessage {...messages.phone} />:{' '}
                  </span>
                  <FormatPhone phoneNumber={phoneNumber} />
                </li>
                <li>
                  <span>
                    <FormattedMessage {...messages.email} />:{' '}
                  </span>
                  <a href={`mailto:${emailAddress}`}>
                    <DashesIfNullOrUndefined data={emailAddress} />
                  </a>
                </li>
              </ul>
            </Col>
          );
        })}
      </div>
      <div className="row">
        <Col xs={12} sm={5} className="profile-details">
          <FormattedMessage {...messages.applicationStatus} />:
        </Col>
        <Col xs={12} sm={6}>
          <select
            name="applicationStatusId"
            className="form-control"
            value={currentRecord.applicationStatus.id}
            onChange={(event) =>
              statusUpdateAllowed
                ? handleStatusChange(event, event.target.value)
                : null
            }
            disabled={disableStatusChange}
          >
            {applicationStatusOptions.map((status) => (
              <option
                key={status.value}
                value={status.value}
                disabled={status.disabled}
              >
                {status.text}
              </option>
            ))}
          </select>
        </Col>
      </div>
    </Box>
  );
  const renderProfilePanel = (
    <Box sx={{ padding: 2 }}>
      {visibleApplicants.map((applicant) => {
        const customer = pathOr(
          {},
          ['applicantCustomer', 'customer'],
          applicant,
        );
        const emailAddress = propOr('', 'emailAddress', customer);
        const firstName = propOr('', 'firstName', customer);
        const lastName = propOr('', 'lastName', customer);
        const phoneNumber = propOr('', 'phoneNumber', customer);
        return (
          <React.Fragment key={applicant.id}>
            <Typography variant={'h3'}>
              <DashesIfNullOrUndefined data={firstName} />{' '}
              <DashesIfNullOrUndefined data={lastName} />
            </Typography>
            <ul className="details-list">
              <li>
                <span>
                  <FormattedMessage {...messages.phone} />:{' '}
                </span>
                <FormatPhone phoneNumber={phoneNumber} />
              </li>
              <li>
                <span>
                  <FormattedMessage {...messages.email} />:{' '}
                </span>
                <WordBreakSpan>
                  <a href={`mailto:${emailAddress}`}>
                    <DashesIfNullOrUndefined data={emailAddress} />
                  </a>
                </WordBreakSpan>
              </li>
            </ul>
          </React.Fragment>
        );
      })}
      <Divider />
      <DetailList>
        <li>
          <span>
            <FormattedMessage {...messages.applicationId} />:{' '}
          </span>
          {
            <DashesIfNullOrUndefined
              data={formatApplicationCode(
                String(currentRecord.applicationCode),
                currentRecord.property.shortName,
              )}
            />
          }
        </li>
        <li>
          <span>
            <FormattedMessage {...messages.applicantStatus} />:{' '}
          </span>
          <span
            className={`tag status-tag status-tag--${currentRecord.applicationStatus.color}`}
          >
            {
              <DashesIfNullOrUndefined
                data={currentRecord.applicationStatus.name}
              />
            }
          </span>
        </li>
      </DetailList>
      {renderLastStatus}
      {renderLastestNote}
      {propertyClassType === 'Mixed' && applicationPropertyClass}
    </Box>
  );
  return (
    <React.Fragment>
      {!isCommercial ? renderProfilePanel : renderCommercialProfilePanel}
    </React.Fragment>
  );
};

export default ProfileDetails;
