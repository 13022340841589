import CashFlowTable from './table';
import React, { memo } from 'react';
import {
  Box,
  Input,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { palette } from '@fortress-technology-solutions/fortress-component-library/design';

const headers = [
  { label: '', key: 'label', flex: 3, textAlign: 'left' },
  { label: 'Current Budget', key: 'currentBudget', flex: 1 },
  { label: 'Actual', key: 'actual', flex: 1 },
  {
    label: 'Proposed Budget',
    key: 'proposedBudget',
    flex: 1,
    editable: true,
    placeholder: '0.00',
    type: 'currency',
  },
  {
    label: 'Comments or (YTD)',
    key: 'comments',
    flex: 1,
    editable: true,
    placeholder: 'Comment or (YTD)',
  },
];

const data = [
  {
    header: 'Reserve Account',
    rows: [
      {
        label: '1. Beginning Balance',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '2. Transfer to Reserve',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '\u00A0\u00A0\u00A0\u00A0' + 'Transfer from Reserve',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '3. Operating Deficit',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '4. Annual Capital Budget',
        labelSuffix: '(Part V - Reserve)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '5. Building and Equipment Repair',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '6. Other Non-Operating Expenses',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '7. Total',
        labelSuffix: '(3 thru 6)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '8. Ending Balance',
        labelSuffix: '[(1+2)-7]',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
    ],
  },
  {
    header: 'General Operating Account *',
    rows: [
      {
        label: 'Beginning Balance',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: 'Ending Balance',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
    ],
  },
  {
    header: 'Real Estate Tax and Insurance Escrow Accnt *',
    rows: [
      {
        label: 'Beginning Balance',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: 'Ending Balance',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
    ],
  },
  {
    header: 'Tenant Security Deposit Account *',
    rows: [
      {
        label: 'Beginning Balance',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: 'Ending Balance',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '',
        labelSuffix: '(*Complete upon submission of actual expenses.)',
        isLabelOnly: true,
      },
    ],
  },
];

const bottomSection = [
  {
    label: 'Number of Applicants on the Waiting List',
    value: '',
    placeholder: '0',
  },
  { label: 'Number of Applicants Needing RA', placeholder: '0' },
  {
    label: 'Reserve Account Required Balance',
    placeholder: '$0.00',
  },
  { label: 'Amount Ahead / Behind', placeholder: '$0.00' },
];

const Part3 = ({ tableState, setTableState, control }) => {
  return (
    <>
      <CashFlowTable
        data={data}
        headers={headers}
        tableState={tableState}
        setTableState={setTableState}
        control={control}
      />
      <Box
        sx={{
          borderTop: `1px solid ${palette.light.lightGrey}`,
        }}
      >
        {bottomSection.map((row, rowIndex) => {
          const key = `3-bottom-${rowIndex}-${row.label}`;
          return (
            <Box
              key={rowIndex}
              display="flex"
              alignItems="center"
              sx={{
                borderBottom: `1px solid ${palette.light.lightGreyAlt}`,
              }}
            >
              <Box flex={3} padding="8px 16px">
                <Typography variant="bodySmall">{row.label}</Typography>
              </Box>
              <Box flex={1} padding="8px 16px">
                <Input
                  value={tableState[key] || ''}
                  onChange={(e) =>
                    setTableState({
                      ...tableState,
                      [key]: e.target.value,
                    })
                  }
                  placeholder={row.placeholder || ''}
                  gutterBottom={false}
                  HelperTextProps={{
                    sx: { display: 'none' },
                  }}
                />
              </Box>
              <Box flex={1} padding="8px 16px" />
              <Box flex={1} padding="8px 16px" />
              <Box flex={1} padding="8px 16px" />
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default memo(Part3);
