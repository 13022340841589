export const cancel = 'Cancel';
export const save = 'Save';

export const actionsMapper = {
  ADD_TO_WAITLIST: 'Add to Waitlist',
  SELECT_FROM_WAITLIST: 'Select from waitlist',
  CANCEL: 'Canceled',
  DENY: 'Denied',
  REINSTATE: 'Reinstate',
  ADD_NOTE: 'Add note',
  SKIP: 'Skip',
};

export const householdPreferencesNames = [
  'Disabled',
  'Elderly',
  'Veteran',
  'VAWA',
  'Working Family',
];

export const propertyPreferencesNames = [
  'Veteran',
  'Adult Transition',
  'Involuntarily',
  'Disability',
  'Elderly',
  'Homeless',
  'Paymore50',
  'Residency',
  'Victims',
  'Working Families',
];

const skipReasons = [
  'Urgent Housing Need',
  'Medical Necessity',
  'Income Targeting',
  'Preferences',
  'Other - See Notes',
];
const reinstateReasons = [
  'Changed Mind',
  'Unintentionally Removed',
  'Resolved Screening Issue',
  'Other - See Notes',
];

const cancelReasons = [
  'No Longer Interested',
  'Duplicate',
  'Found Other Housing',
  'No Response',
  'Other - See Notes',
];

const denyReasons = [
  'Income Ineligible',
  'Criminal Background',
  'Eviction History',
  'False Information on Application',
  'Housing History',
  'Outstanding Debt',
  'Other - See Notes',
];

export const reasons = {
  skip: skipReasons,
  reinstate: reinstateReasons,
  cancel: cancelReasons,
  deny: denyReasons,
};
